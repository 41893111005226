import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Close } from '@sharefiledev/icons';
import { Modal } from 'antd';

export const ButtonInfoContainer = styled.span`
	padding: ${sizes.XS} ${sizes.SM};
	background: ${colorPalette.neutral2};
	border-radius: ${sizes.XS};
	font-size: 14px;
	font-weight: 600;
`;

export const ShortcutsModalStyled = styled(Modal)`
	.ant-modal-content {
		padding: ${sizes.MD} ${sizes.LG};
	}
	.ant-modal-header {
		margin-bottom: ${sizes.MS};
	}
`;

export const StyledCloseIcon = styled(Close)`
	opacity: 45%;
`;
