import React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Alert, Button, Flex, Grid, Row, Space, theme, Typography } from 'antd';
import { AsteriskIcon } from '../../../../Common/icons';
import { SignerRequest } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import {
	StyledDeclineDocumentModal,
	StyledTextArea,
	SyledCloseIcon,
} from './DeclineDocumentModal.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const asteriskIconStyles: React.CSSProperties = {
	margin: `${sizes.XXS} 0px 0px ${sizes.XXS}`,
};

const textAreaStyles: React.CSSProperties = {
	margin: `${sizes.XS} 0px 0px 0px`,
	boxShadow: 'none',
};

const modalBodyStyles: React.CSSProperties = {
	overflow: 'auto',
	scrollbarWidth: 'none',
};

export interface Props {
	isModalOpen: boolean;
	reason: string;
	onDecline: () => Promise<SignerRequest>;
	onClose: () => void;
	onReasonChange: (value: string) => void;
}

export function DeclineDocumentModal(props: Props) {
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const { token } = useToken();
	const screens = useBreakpoint();

	modalBodyStyles.maxHeight = screens.xs ? '90vh' : '40vh';

	const onDeclineClick = async () => {
		await props.onDecline();
		setIsModalOpen(false);
	};

	const onReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		props.onReasonChange(e.target.value);
	};

	React.useEffect(() => {
		setIsModalOpen(props.isModalOpen);
	}, [props.isModalOpen]);

	return (
		<StyledDeclineDocumentModal
			open={isModalOpen}
			token={token}
			centered={screens.sm}
			styles={{ body: modalBodyStyles }}
			width={screens.xs ? '100%' : 520}
			title={t('esign-pilet-ui:signerPage.modals.declineDocument.title')}
			closeIcon={<SyledCloseIcon />}
			onCancel={props.onClose}
			data-testid="DeclineDocumentModal"
			footer={[
				<Flex key="flex">
					<Button
						size="small"
						shape="round"
						key="decline"
						type="primary"
						disabled={!props.reason}
						danger
						onClick={onDeclineClick}
						data-testid="modal-button-decline"
					>
						{t('esign-pilet-ui:signerPage.modals.declineDocument.decline')}
					</Button>

					<Button
						size="small"
						shape="round"
						key="cancel"
						type="default"
						onClick={props.onClose}
						data-testid="modal-button-close"
					>
						{t('esign-pilet-ui:signerPage.modals.declineDocument.cancel')}
					</Button>
				</Flex>,
			]}
		>
			<Space wrap direction="vertical" size={16} style={{ width: '100%' }}>
				<Row style={{ marginTop: sizes.base, marginBottom: sizes.XS }}>
					<Alert
						message={t('esign-pilet-ui:signerPage.modals.declineDocument.warningMessage')}
						style={{ borderRadius: sizes.SM }}
						type="warning"
						showIcon
					/>
				</Row>

				<Row style={{ marginTop: sizes.XS, marginBottom: sizes.MD }}>
					<Typography.Text>
						{t('esign-pilet-ui:signerPage.modals.declineDocument.reason')}
					</Typography.Text>
					<AsteriskIcon style={asteriskIconStyles} />
					<StyledTextArea
						style={textAreaStyles}
						minLength={2}
						onChange={onReasonChange}
						data-testid="decline-reason-input"
						placeholder={t(
							'esign-pilet-ui:signerPage.modals.declineDocument.reasonPlaceholder'
						)}
					></StyledTextArea>
					<Typography.Text style={{ color: colorPalette.neutral6 }}>
						{t('esign-pilet-ui:signerPage.modals.declineDocument.reasonHelp')}
					</Typography.Text>
				</Row>
			</Space>
		</StyledDeclineDocumentModal>
	);
}
