import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { SignatureDrawerIndexes } from '../../../constants';
import { t } from '../../../util';
import { canvasDimesions, SignatureType } from '../constants';
import { SaveSignatureEvent } from '../SaveSignatureEvent';
import {
	StyledClearSignature,
	StyledMessage,
	StyledSignatureTypeWrapper,
} from './ESignatureTextPad.styled';

const MAX_FONT_SIZE = 76;
const MIN_FONT_SIZE = 20;
const EMPTY_TEXT = '';

export const ESignatureTextPad = ({
	saveSignature,
	penColor,
	isSignatureDrawn,
	setIsSignatureDrawn,
	defaultSignature = '',
	setDefaultSignature = undefined,
	tabOpened = SignatureType.TYPED,
	clearRef = undefined,
	isSignerFlow = false,
	previousValue = undefined,
	setPreviousValue = undefined,
	pageBoundingBox = undefined,
}) => {
	const [fontSize, setFontSize] = useState(MAX_FONT_SIZE);
	const [signatureText, setSignatureText] = useState(EMPTY_TEXT);
	const canvasRef = useRef(null);
	const elementRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [canvasWidth] = useState(
		pageBoundingBox?.width < canvasDimesions.width
			? pageBoundingBox?.width
			: canvasDimesions.width
	);

	const resizeText = useCallback(() => {
		const maxWidth = inputRef.current.clientWidth,
			element = elementRef.current;

		setTimeout(() => {
			element.style.fontSize = MAX_FONT_SIZE + 'px';
			while (
				element.getBoundingClientRect().width > maxWidth &&
				parseInt(element.style.fontSize, 10) > MIN_FONT_SIZE
			) {
				element.style.fontSize = parseInt(element.style.fontSize, 10) - 1 + 'px';
			}
			setFontSize(parseInt(element.style.fontSize, 10));
		}, 0);
	}, []);

	const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		if (defaultSignature) {
			setDefaultSignature(null);
		}
		if (value) {
			setSignatureText(value);
			if (!isSignatureDrawn) {
				setIsSignatureDrawn(true);
			}
			resizeText();
		} else {
			setSignatureText('');
			setIsSignatureDrawn(false);
		}
	};

	const clearSignature = () => {
		setSignatureText('');
		setIsSignatureDrawn(false);
		if (defaultSignature) {
			setDefaultSignature(null);
		}
	};

	useEffect(() => {
		const canvasElement = canvasRef.current;
		const inputElement = inputRef.current;

		const onSaveEventHandler = () => {
			if (
				canvasElement &&
				inputElement &&
				(!isSignerFlow || (isSignerFlow && tabOpened === SignatureType.TYPED))
			) {
				const width = canvasElement.width,
					height = canvasElement.height,
					font = `${fontSize}px 'Cedarville Cursive', 'cursive'`,
					ctx = canvasElement.getContext('2d');

				ctx.clearRect(0, 0, width, height);
				ctx.restore();
				ctx.font = font;
				ctx.textBaseline = 'middle';
				ctx.fillStyle = penColor;
				ctx.fillText(inputElement.value, 0, height / 2);
				ctx.save();
				const signatureDataUrl = canvasElement.toDataURL('image/png');
				saveSignature(signatureDataUrl, null, inputElement.value);
			}
		};

		const key = 'ESignatureTextPad';
		SaveSignatureEvent.registerHandler(key, onSaveEventHandler);

		if (elementRef.current) {
			elementRef.current.textContent = signatureText;
			resizeText();
		}

		if (previousValue?.signature_type === SignatureType.TYPED) {
			setSignatureText(previousValue.signature_text);
			setDefaultSignature(null);
			setPreviousValue(null);
		} else if (defaultSignature && isSignerFlow && tabOpened === SignatureType.TYPED) {
			setSignatureText(defaultSignature);
		}

		if (isSignerFlow && tabOpened === SignatureType.TYPED) {
			signatureText ? setIsSignatureDrawn(true) : setIsSignatureDrawn(false);
		}

		return () => {
			SaveSignatureEvent.unRegisterHandler(key);
		};
	}, [
		signatureText,
		saveSignature,
		fontSize,
		resizeText,
		penColor,
		setIsSignatureDrawn,
		tabOpened,
		defaultSignature,
		setDefaultSignature,
		previousValue,
		setPreviousValue,
		isSignerFlow,
	]);

	return (
		<div className="signature-textpad-wrapper" data-testid="signature-text-pad">
			<StyledMessage>{t('esign-pilet-ui:signatureAnnotationTextMessage')}</StyledMessage>
			<div className="signature-type-canvas-wrapper">
				<StyledSignatureTypeWrapper className="signature-type-wrapper">
					<input
						autoFocus
						id="typed-signature-input"
						data-testid="typed-signature-input"
						className="signature-input signature-type-inner-canvas-wrapper"
						tabIndex={SignatureDrawerIndexes.TypeInput}
						value={signatureText}
						onChange={onTextChange}
						ref={inputRef}
						maxLength={150}
						style={{ fontSize, color: penColor }}
					/>
				</StyledSignatureTypeWrapper>
				<canvas
					ref={canvasRef}
					{...{ width: canvasWidth, height: canvasDimesions.height }}
					id="input-text-canvas"
					data-testid="typed-signature-canvas"
					style={{ display: 'none' }}
				></canvas>
				{isSignatureDrawn &&
					(isSignerFlow ? (
						<Button
							type="link"
							onClick={clearSignature}
							ref={clearRef}
							id="signature-text-clear-link"
							data-testid="signature-text-clear-link"
							className="signature-pad-clear-link"
							tabIndex={SignatureDrawerIndexes.TypeClearButton}
							style={{
								position: 'absolute',
								top: '60px',
								right: '10px',
								marginTop: '-5px',
							}}
						>
							{t('esign-pilet-ui:clear')}
						</Button>
					) : (
						<StyledClearSignature
							onClick={clearSignature}
							ref={clearRef}
							data-testid="signature-text-clear-link"
						>
							{t('esign-pilet-ui:clear')}
						</StyledClearSignature>
					))}
			</div>
			<div
				className="hidden-resizer"
				ref={elementRef}
				style={{
					fontSize: fontSize + 'px',
				}}
			>
				{signatureText}
			</div>
		</div>
	);
};
