import React, { useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { Close, HelpQuestion, KeyboardShortcut } from '@sharefiledev/icons';
import { Button, Flex, Grid, Layout, Typography } from 'antd';
import { useAppStore } from '../../Common/store';
import { SignerPageTabIndexes } from '../../constants';
import { Account, BaseFiles, SignerSubmissionResponse } from '../../data/rsTypes';
import { ShortcutsModal } from '../Modals/ShortcutsModal';
import { HelpAndFaqDrawer } from '../PageContent/HelpAndFaqDrawer';
import { SubmitResponseButton } from '../SubmitResponseButton/SubmitResponseButton';
import { DocumentActionsDrawer } from './DocumentActions/DocumentActions';
const { Title } = Typography;
const { useBreakpoint } = Grid;

const { Header } = Layout;

const headerFlexStyles: React.CSSProperties = {
	height: '100%',
};
const titleStyles: React.CSSProperties = {
	marginTop: sizes.XXS,
	marginBottom: sizes.XXS,
	fontSize: sizes.base,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textWrap: 'nowrap',
};
export interface PageHeaderProps {
	baseFiles: BaseFiles;
	account: Account;
	setSubmitResponse: (submitResponse: SignerSubmissionResponse) => void;
	setIsSignerPageDrawerOpen?: (isOpen: boolean) => void;
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
	const screens = useBreakpoint();
	const [esignDocument, isSignedInUserFlow] = useAppStore(store => [
		store.esignDocument,
		store.isSignedInUserFlow,
	]);
	const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState<boolean>(false);
	const [isHelpAndFaqDrawerOpen, setIsHelpAndFaqDrawerOpen] = useState<boolean>(false);

	const headerStyles: React.CSSProperties = {
		padding: `0 ${screens.xs ? sizes.base : sizes.MD}`,
		borderBottom: `1px solid ${colorPalette.neutral3}`,
	};

	React.useEffect(() => {
		const handleKeyDown = event => {
			if (event.shiftKey && event.key === '?') {
				setIsShortcutsModalOpen(prev => !prev);
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, []);

	return (
		<>
			<Header style={headerStyles}>
				<Flex
					gap={screens.xs ? 'small' : 'middle'}
					justify="space-between"
					align="center"
					style={headerFlexStyles}
				>
					<Flex gap={24} style={{ overflow: 'hidden' }}>
						{screens.lg && <SharefileTextLogoIcon />}
						<Title level={5} style={titleStyles}>
							{esignDocument.name}
						</Title>
					</Flex>
					<Flex justify="space-between" align="center" gap={screens.xs ? 0 : 8}>
						{screens.lg && (
							<Button
								id="keyboard-shortcut-button"
								tabIndex={SignerPageTabIndexes.KeyboardShortcut}
								size="middle"
								icon={<KeyboardShortcut />}
								type="text"
								onClick={() => setIsShortcutsModalOpen(true)}
							></Button>
						)}
						<Button
							id="help-section-button"
							tabIndex={SignerPageTabIndexes.HelpSection}
							style={{ marginLeft: 0 }}
							size="middle"
							icon={<HelpQuestion />}
							onClick={() => setIsHelpAndFaqDrawerOpen(true)}
							data-testid="help-button"
							type="text"
						></Button>
						<DocumentActionsDrawer baseFiles={props.baseFiles} account={props.account} />
						{screens.sm && (
							<SubmitResponseButton setSubmitResponse={props.setSubmitResponse} />
						)}
						{isSignedInUserFlow && (
							<Button
								icon={<Close />}
								onClick={() => props.setIsSignerPageDrawerOpen(false)}
								style={{ border: 'none', color: 'black' }}
								data-testid="signed-in-user-close-button"
							></Button>
						)}
					</Flex>
				</Flex>
			</Header>
			<ShortcutsModal
				isModalOpen={isShortcutsModalOpen}
				setIsModalOpen={setIsShortcutsModalOpen}
			/>
			<HelpAndFaqDrawer
				open={isHelpAndFaqDrawerOpen}
				setIsOpen={setIsHelpAndFaqDrawerOpen}
			/>
		</>
	);
};
