import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import SignaturePad, { Options } from 'signature_pad';
import trimCanvas from 'trim-canvas';
import { SignatureDrawerIndexes } from '../../constants';

export interface SignatureCanvasProps extends Options {
	canvasProps?: React.CanvasHTMLAttributes<HTMLCanvasElement>;
	clearOnResize?: boolean;
	onEnd?: (event: any) => void;
	onBegin?: (event: any) => void;
}

export const ESignatureCanvas = forwardRef<any, SignatureCanvasProps>((props, ref) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	let _sigPad = useRef<SignaturePad | null>(null);

	useEffect(() => {
		if (!_sigPad.current) {
			const canvas = getCanvas();
			const signaturePad = new SignaturePad(canvas, _excludeOurProps());
			_sigPad.current = signaturePad;
		}
		_resizeCanvas();

		on();
		bindStrokeEnd();
		return () => {
			unbindStrokeEnd();
			off();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const on = () => {
		window.addEventListener('resize', _checkClearOnResize);
		return getSignaturePad().on();
	};

	const off = () => {
		window.removeEventListener('resize', _checkClearOnResize);
		return getSignaturePad().off();
	};

	const _excludeOurProps = () => {
		const { canvasProps, clearOnResize, ...sigPadProps } = props;
		return sigPadProps;
	};

	// return the canvas ref for operations like toDataURL
	const getCanvas = (): HTMLCanvasElement => {
		return canvasRef.current;
	};

	// return a trimmed copy of the canvas
	const getTrimmedCanvas = (): HTMLCanvasElement => {
		// copy the canvas
		const canvas = getCanvas();
		const copy = document.createElement('canvas');
		copy.width = canvas.width;
		copy.height = canvas.height;

		copy.getContext('2d')!.drawImage(canvas, 0, 0);
		// then trim it
		return trimCanvas(copy);
	};

	// return the internal SignaturePad reference
	const getSignaturePad = (): SignaturePad => {
		return _sigPad.current;
	};

	const _checkClearOnResize = (): void => {
		_resizeCanvas();
	};

	const _resizeCanvas = (): void => {
		const canvasProps = props.canvasProps ?? {};
		const { width: widthOnResizeCanvas, height: heightOnResizeCanvas } = canvasProps;
		// don't resize if the canvas has fixed width and height
		if (
			typeof widthOnResizeCanvas !== 'undefined' &&
			typeof heightOnResizeCanvas !== 'undefined'
		) {
			return;
		}

		const canvas = getCanvas();
		/* When zoomed out to less than 100%, for some very strange reason,
		some browsers report devicePixelRatio as less than 1
		and only part of the canvas is cleared then. */
		const ratio = Math.max(window.devicePixelRatio ?? 1, 1);

		if (typeof widthOnResizeCanvas === 'undefined') {
			canvas.width = canvas.offsetWidth * ratio;
		}
		if (typeof heightOnResizeCanvas === 'undefined') {
			canvas.height = canvas.offsetHeight * ratio;
		}

		canvas.getContext('2d')!.scale(ratio, ratio);
	};

	const clear: SignaturePad['clear'] = () => {
		return getSignaturePad().clear();
	};

	const isEmpty: SignaturePad['isEmpty'] = () => {
		return getSignaturePad().isEmpty();
	};

	const fromDataURL: SignaturePad['fromDataURL'] = (dataURL, options) => {
		return getSignaturePad().fromDataURL(dataURL, options);
	};

	const toDataURL: SignaturePad['toDataURL'] = (type, encoderOptions) => {
		return getSignaturePad().toDataURL(type, encoderOptions);
	};

	const fromData: SignaturePad['fromData'] = pointGroups => {
		return getSignaturePad().fromData(pointGroups);
	};

	const toData = () => {
		return getSignaturePad().toData();
	};

	const bindStrokeEnd = () => {
		getSignaturePad().addEventListener('endStroke', props.onEnd);
	};
	const unbindStrokeEnd = () => {
		getSignaturePad().removeEventListener('endStroke', () => props.onEnd);
	};

	useImperativeHandle(ref, () => ({
		isEmpty,
		clear,
		toDataURL,
		fromDataURL,
		toData,
		fromData,
		getTrimmedCanvas,
	}));

	const { canvasProps: { height, width } = {} } = props;
	return (
		<canvas
			id="signature-drawer-typed-canvas"
			data-testid="canvas"
			tabIndex={SignatureDrawerIndexes.DrawCanvas}
			height={height}
			width={width}
			ref={canvasRef}
			style={{ aspectRatio: 4 }}
		></canvas>
	);
});

export default ESignatureCanvas;
