// This storage is used to save document for signing
import { StateCreator } from 'zustand';
import { BaseFiles, ExtendedRSDocument } from '../../data/rsTypes';
import { storeResetFns } from '.';

type State = {
	esignDocument: ExtendedRSDocument;
	baseFiles: BaseFiles;
	originAnalyticsTrackingId: string;
};

type Actions = {
	setEsignDocument: (esignDocument: ExtendedRSDocument) => void;
	setBaseFiles: (baseFiles: BaseFiles) => void;
	setOriginAnalyticsTrackingId: (originAnalyticsTrackingId: string) => void;
};

export type EsignDocumentStore = State & Actions;

const initialState: State = {
	esignDocument: undefined,
	baseFiles: undefined,
	originAnalyticsTrackingId: '', // to track the Pendo metrics to evaluate the impact of the project template on user adoption.
};

export const createEsignDocumentSlice: StateCreator<EsignDocumentStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setEsignDocument(esignDocument) {
			set(prevValue => ({
				esignDocument: esignDocument,
				baseFiles: prevValue.baseFiles,
			}));
		},
		setBaseFiles(baseFiles) {
			set(prevValue => ({
				esignDocument: prevValue.esignDocument,
				baseFiles: baseFiles,
			}));
		},
		setOriginAnalyticsTrackingId(originAnalyticsTrackingId) {
			set(() => ({
				originAnalyticsTrackingId: originAnalyticsTrackingId,
			}));
		},
	};
};
