interface PollParams<T> {
	pollFn: () => Promise<T>;
	validateFn: (a: T) => boolean;
	maxAttempts: number;
	delayInMilliSeconds: number;
}

export async function pollWithConstantBackOff<T>({
	pollFn,
	validateFn,
	maxAttempts,
	delayInMilliSeconds,
}: PollParams<T>): Promise<T> {
	let attempts = 0;

	const executePoll = async (
		resolve: (value: T | PromiseLike<T>) => void,
		reject: (reason?: any) => void
	) => {
		try {
			attempts++;
			const result = await pollFn();
			if (validateFn(result)) {
				return resolve(result);
			}
			retry(resolve, reject);
		} catch (e) {
			return reject(e);
		}
	};

	const retry = (
		resolve: (value: T | PromiseLike<T>) => void,
		reject: (reason?: any) => void
	) => {
		if (maxAttempts && attempts === maxAttempts) {
			return reject('Max attempts exceeded');
		}
		const timeOutInMilliSeconds = delayInMilliSeconds + getRandomInt(100);
		setTimeout(executePoll, timeOutInMilliSeconds, resolve, reject);
	};

	const getRandomInt = (max: number) => Math.floor(Math.random() * max);

	return new Promise<T>(executePoll);
}
