import React, { useEffect, useState } from 'react';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Close } from '@sharefiledev/icons';
import {
	Alert,
	Avatar,
	Button,
	Grid,
	List,
	Space,
	Tabs,
	TabsProps,
	Typography,
} from 'antd';
import { SignatureDrawerIndexes } from '../../../../../constants';
import { ESignatureClient } from '../../../../../data/eSignatureClient';
import { Component, DocumentSigner } from '../../../../../data/rsTypes';
import {
	SignatureSave,
	SignatureType,
} from '../../../../../PreparePage/SignaturePad/constants';
import { ESignaturePad } from '../../../../../PreparePage/SignaturePad/ESignaturePad/ESignaturePad';
import { ESignatureTextPad } from '../../../../../PreparePage/SignaturePad/ESignatureTextPad/ESignatureTextPad';
import { ESignatureUploadPad } from '../../../../../PreparePage/SignaturePad/ESignatureUploadPad/ESignatureUploadPad';
import { SaveSignatureEvent } from '../../../../../PreparePage/SignaturePad/SaveSignatureEvent';
import { t } from '../../../../../util';
import { useAppStore } from '../../../../store';
import { SignatureStyledDrawer } from './SignatureDrawer.styled';

interface Props {
	signer: DocumentSigner;
	component: Component;
	signatureColor: string;
	pageBoundingBox: DOMRect;
	personalSettings: any;
	clearRef: any;
	previousValue: any;
	isSignatureDrawerOpened: boolean;
	setSignatureDrawerOpen: (value: boolean) => void;
	saveSignature: (signature) => void;
	setPreviousValue: (value) => void;
}

const { useBreakpoint } = Grid;
const { Text } = Typography;

const uploadSignatureListItemStyles: React.CSSProperties = {
	color: colorPalette.neutral7,
};

export const SignatureDrawer = (props: Props) => {
	const screens = useBreakpoint();
	const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);
	const [selectedTab, setSelectedTab] = useState(
		props.personalSettings?.allowTypedSignature
			? SignatureType.TYPED
			: SignatureType.DRAWN
	);
	const [defaultSignature, setDefaultSignature] = React.useState(props.signer.name);
	const { setLastSavedSignature } = useAppStore(store => ({
		setLastSavedSignature: store.setLastSavedSignature,
	}));

	const saveSignature = async (signatureDataUrl, jsonData, text) => {
		const payload = {
			signature: {
				color: props.signatureColor,
				image_data: null,
				image_data_raw: signatureDataUrl,
				image_url: null,
				is_default: false,
				json_data: jsonData,
				s3_guid: null,
				signature_text: text,
				signature_type: selectedTab,
				signer_id: props.signer.id,
				user_id: null,
			},
		};

		await ESignatureClient.saveSignature(payload).then((res: any) => {
			props.saveSignature(res.signature);
			setLastSavedSignature(payload);
		});
	};

	useEffect(() => {
		switch (props.previousValue?.signature_type) {
			case SignatureType.DRAWN:
				setSelectedTab(SignatureType.DRAWN);
				break;
			case SignatureType.TYPED:
				setSelectedTab(SignatureType.TYPED);
				break;
			case SignatureType.UPLOADED:
				setSelectedTab(SignatureType.UPLOADED);
				break;
		}
	}, [props.previousValue]);

	const onApply = () => {
		SaveSignatureEvent.dispatch({
			id: props.component.id,
			message: SignatureSave.COMPLETED,
		});
		props.setSignatureDrawerOpen(false);
	};

	const closeSignatureDrawer = () => {
		props.setSignatureDrawerOpen(false);
	};

	const tabs: TabsProps['items'] = [
		{
			key: SignatureType.TYPED,
			label: t('esign-pilet-ui:type'),
			disabled: !props.personalSettings?.allowTypedSignature,
			children: (
				<Space direction="vertical" style={{ minWidth: '100%' }}>
					<ESignatureTextPad
						saveSignature={saveSignature}
						penColor={props.signatureColor}
						isSignatureDrawn={isSignatureDrawn}
						setIsSignatureDrawn={setIsSignatureDrawn}
						defaultSignature={defaultSignature}
						setDefaultSignature={setDefaultSignature}
						tabOpened={selectedTab}
						isSignerFlow={true}
						clearRef={props.clearRef}
						previousValue={props.previousValue}
						setPreviousValue={props.setPreviousValue}
						pageBoundingBox={props.pageBoundingBox}
					/>
					<Space>
						{defaultSignature && (
							<Space style={{ color: colorPalette.neutral7 }}>
								{t(
									'esign-pilet-ui:signerPage.content.fields.signature.prefilledSignatureText'
								)}
							</Space>
						)}
					</Space>
				</Space>
			),
		},
		{
			key: SignatureType.DRAWN,
			label: t('esign-pilet-ui:draw'),
			disabled: !props.personalSettings.allowDrawnSignature,
			children: (
				<Space style={{ minWidth: '100%' }}>
					<ESignaturePad
						saveSignature={saveSignature}
						penColor={props.signatureColor}
						isSignatureDrawn={isSignatureDrawn}
						setIsSignatureDrawn={setIsSignatureDrawn}
						tabOpened={selectedTab}
						clearRef={props.clearRef}
						isSignerFlow={true}
						previousValue={props.previousValue}
						setPreviousValue={props.setPreviousValue}
						pageBoundingBox={props.pageBoundingBox}
					/>
				</Space>
			),
		},
		{
			key: SignatureType.UPLOADED,
			label: t('esign-pilet-ui:uploadImage'),
			disabled: !props.personalSettings?.allowUploadedSignature,
			children: (
				<Space direction="vertical" style={{ minWidth: '100%' }}>
					<ESignatureUploadPad
						saveSignature={saveSignature}
						setIsSignatureDrawn={setIsSignatureDrawn}
						tabOpened={selectedTab}
						clearRef={props.clearRef}
						isSignerFlow={true}
						previousValue={props.previousValue}
						setPreviousValue={props.setPreviousValue}
					/>
					<Space direction="vertical" style={{ minWidth: '100%' }}>
						<Text style={{ color: colorPalette.neutral7 }}>
							{t(
								'esign-pilet-ui:signerPage.content.fields.signature.uploadSignatureNote'
							)}
						</Text>
						<List
							dataSource={[
								t(
									'esign-pilet-ui:signerPage.content.fields.signature.uploadSignatureNote1'
								),
								t(
									'esign-pilet-ui:signerPage.content.fields.signature.uploadSignatureNote2'
								),
							]}
							renderItem={item => (
								<List.Item
									style={{
										display: 'flex',
										padding: `0 0 ${sizes.XS} ${sizes.base}`,
									}}
								>
									<List.Item.Meta
										avatar={
											<Avatar
												style={{
													backgroundColor: 'transparent',
													...uploadSignatureListItemStyles,
												}}
											>
												•
											</Avatar>
										}
										description={item}
										style={uploadSignatureListItemStyles}
									/>
								</List.Item>
							)}
							split={false}
						/>
					</Space>
				</Space>
			),
		},
	];

	const onTabChange = key => {
		setSelectedTab(key);
	};

	const [elementIndex, setElementIndex] = useState(-1);
	const [activeTab, setActiveTab] = useState(0);

	React.useEffect(() => {
		const handleClickEvent = event => {
			if (event.target.innerText === t('esign-pilet-ui:type')) {
				setActiveTab(0);
			} else if (event.target.innerText === t('esign-pilet-ui:draw')) {
				setActiveTab(1);
			} else if (event.target.innerText === t('esign-pilet-ui:uploadImage')) {
				setActiveTab(2);
			}
		};

		const handleKeyDown = event => {
			const elements = document.querySelectorAll('[tabindex]');

			let tabIndexes = Array.from(elements)
				.filter(el => +el.getAttribute('tabindex') >= SignatureDrawerIndexes.TypeTab)
				.map(el => el.getAttribute('tabindex'))
				.sort();

			if (event.key === 'Enter' || event.code === 'Space') {
				let activeTab = null;

				if (event.target.innerText === t('esign-pilet-ui:type')) {
					activeTab = 0;
				} else if (event.target.innerText === t('esign-pilet-ui:draw')) {
					activeTab = 1;
				} else if (event.target.innerText === t('esign-pilet-ui:uploadImage')) {
					activeTab = 2;
				}

				if (activeTab !== null) {
					event.preventDefault();
					setActiveTab(activeTab);
					document
						.getElementById(
							document
								.querySelectorAll("[tabindex='" + tabIndexes[elementIndex] + "']")[0]
								.getAttribute('id')
						)
						.click();
				}
			}
			if (event.key === 'Tab') {
				event.preventDefault();

				if (activeTab === 0) {
					tabIndexes = tabIndexes.filter(index => {
						return (
							index !== SignatureDrawerIndexes.DrawCanvas.toString() &&
							index !== SignatureDrawerIndexes.UploadFile.toString() &&
							index !== SignatureDrawerIndexes.UploadClearButton.toString()
						);
					});
				} else if (activeTab === 1) {
					tabIndexes = tabIndexes.filter(index => {
						return (
							index !== SignatureDrawerIndexes.TypeInput.toString() &&
							index !== SignatureDrawerIndexes.TypeClearButton.toString() &&
							index !== SignatureDrawerIndexes.UploadFile.toString() &&
							index !== SignatureDrawerIndexes.UploadClearButton.toString()
						);
					});
				} else if (activeTab === 2) {
					tabIndexes = tabIndexes.filter(index => {
						return (
							index !== SignatureDrawerIndexes.DrawCanvas.toString() &&
							index !== SignatureDrawerIndexes.TypeInput.toString() &&
							index !== SignatureDrawerIndexes.TypeClearButton.toString()
						);
					});
				}

				let nextElementIndex = event.shiftKey
					? elementIndex - 1 >= 0
						? elementIndex - 1
						: tabIndexes.length - 1
					: elementIndex + 1 < tabIndexes.length
					? elementIndex + 1
					: 0;
				setElementIndex(nextElementIndex);

				const element = document.getElementById(
					document
						.querySelectorAll("[tabindex='" + tabIndexes[nextElementIndex] + "']")[0]
						.getAttribute('id')
				);

				if (element) {
					if ((element as HTMLButtonElement).disabled) {
						setElementIndex(++nextElementIndex);
						document
							.getElementById(
								document
									.querySelectorAll(
										"[tabindex='" + tabIndexes[nextElementIndex] + "']"
									)[0]
									.getAttribute('id')
							)
							.focus();
					} else {
						element.focus();
					}
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('click', handleClickEvent);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('click', handleClickEvent);
		};
	}, [activeTab, elementIndex]);

	return (
		<>
			<Global
				styles={css`
					.signature-type-wrapper,
					.signature-draw-wrapper {
						border: none !important;
					}

					.signature-draw-canvas-wrapper,
					.signature-type-inner-canvas-wrapper {
						border: 1px solid ${colorPalette.neutral3} !important;
						border-radius: 4px;
					}

					.signature-draw-canvas-wrapper:hover,
					.signature-upload-field:hover,
					.signature-type-canvas-wrapper:hover .signature-type-inner-canvas-wrapper {
						border: 2px solid ${colorPalette.lavender6} !important;
						border-radius: 4px;
					}

					.ant-tabs .ant-tabs-tab:focus {
						border: 2px solid #6356f6;
						padding: 0;
					}

					.signature-type-inner-canvas-wrapper:focus,
					.signature-upload-field:focus {
						border: 2px solid #6356f6 !important;
						border-radius: 4px;
					}

					#signature-text-clear-link.ant-btn-link:not(:disabled):not(
							.ant-btn-disabled
						):focus {
						border: 2px solid #6356f6;
						border-radius: 0;
						padding: 6px;
						height: 40px;
						margin: -1px 9px 0 0 !important;
					}

					#signature-draw-clear-link.ant-btn-link:not(:disabled):not(
							.ant-btn-disabled
						):focus {
						border: 2px solid #6356f6;
						border-radius: 0;
						padding: 6px;
						height: 40px;
						margin: 4px 19px 0 0 !important;
					}

					#signature-drawer-typed-canvas:focus {
						outline: none !important;
						border: 2px solid #6356f6 !important;
						border-radius: 4px !important;
					}

					#signature-drawer-typed-canvas:focus:hover {
						border: 1px solid transparent !important;
					}

					#clear-upload-signature-btn:focus {
						border: 1px solid ${colorPalette.lavender6};
						border-radius: 0;
					}

					.ant-list-item-meta-avatar {
						margin-right: 0 !important;
					}
				`}
			/>

			<SignatureStyledDrawer
				id="signature-drawer"
				title={t('esign-pilet-ui:addSignature')}
				onClose={closeSignatureDrawer}
				closeIcon={
					<Close
						id="signature-drawer-close-icon"
						tabIndex={SignatureDrawerIndexes.CloseIcon}
					/>
				}
				open={props.isSignatureDrawerOpened}
				style={{ maxWidth: '850px' }}
				width={screens.xs ? '100vw' : '850px'}
				data-testid="signature-drawer"
			>
				{props.personalSettings?.allowTypedSignature ||
				props.personalSettings?.allowDrawnSignature ||
				props.personalSettings?.allowUploadedSignature ? (
					<Tabs
						activeKey={selectedTab}
						defaultActiveKey={selectedTab}
						items={tabs.filter(tab => !tab.disabled)}
						onChange={onTabChange}
						renderTabBar={(tabBarProps, DefaultTabBar) =>
							tabs.filter(tab => !tab.disabled).length > 1 && (
								<DefaultTabBar className="signature-drawer-tabs" {...tabBarProps}>
									{node => {
										let index: number;
										switch (node.key) {
											case 'typed':
												index = 901;
												break;
											case 'drawn':
												index = 902;
												break;
											case 'uploaded':
												index = 903;
												break;
										}
										return React.cloneElement(node, {
											tabIndex: index,
											id: `side-bar-tab-${index}`,
										});
									}}
								</DefaultTabBar>
							)
						}
					/>
				) : (
					<Alert
						data-testid="drawer-error"
						message={t('esign-pilet-ui:userFetchErrorMessage')}
						type="error"
					/>
				)}

				<Space
					direction="horizontal"
					style={{
						width: '100%',
						display: 'flex',
						marginTop:
							isSignatureDrawn && selectedTab === SignatureType.DRAWN
								? '-24px'
								: sizes.MS,
					}}
				>
					<Button
						id="signature-drawer-ok-button"
						tabIndex={SignatureDrawerIndexes.OkButton}
						onClick={onApply}
						size="small"
						shape="round"
						type="primary"
						disabled={!isSignatureDrawn}
						data-testid="signature-drawer-ok-button"
					>
						{t('esign-pilet-ui:add')}
					</Button>
					<Button
						id="signature-drawer-cancel-button"
						tabIndex={SignatureDrawerIndexes.CancelButton}
						onClick={closeSignatureDrawer}
						size="small"
						shape="round"
						data-testid="signature-drawer-cancel-button"
					>
						{t('esign-pilet-ui:cancel')}
					</Button>
				</Space>
			</SignatureStyledDrawer>
		</>
	);
};
