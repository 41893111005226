import { PaperclipIcon } from '@sharefiledev/flow-web';
import { v4 as uuidv4 } from 'uuid';
import { DateFormatTypes, PAGE_WIDTH_THRESHOLD } from '../../constants';
import { IRecipient } from '../../Contexts/DocumentContext';
import { DocumentSigner, IComponentPayload } from '../../data/rsTypes';
import { t } from '../../util';
import { transformCamelCaseKeysToSnakeCase } from '../../util/objectUtil';
import {
	CalendarIcon,
	CheckboxGroupIcon,
	CheckBoxIcon,
	CreditCardDollarIcon,
	DropDownIcon,
	InitialsIcon,
	SignIcon,
	TextInputIcon,
} from '../icons';
import { Component, ComponentTypes, Field } from './types';

const DEFAULT_WORKFLOWS = [
	'reusableTemplate',
	'signatureRequest',
	'inPerson',
	'packager',
];

const AVAILABLE_ATTRIBUTES = [
	{ name: 'name', workflows: DEFAULT_WORKFLOWS },
	{ name: 'assignedTo', workflows: DEFAULT_WORKFLOWS },
	{ name: 'isRequired', workflows: DEFAULT_WORKFLOWS },
	{ name: 'fontSize', workflows: DEFAULT_WORKFLOWS.concat('selfSign') },
	{ name: 'multiline', workflows: DEFAULT_WORKFLOWS.concat('selfSign') },
	{ name: 'transparent', workflows: DEFAULT_WORKFLOWS.concat('selfSign') },
	{ name: 'groupRequirement', workflows: DEFAULT_WORKFLOWS },
	{ name: 'value', workflows: DEFAULT_WORKFLOWS },
	{ name: 'textAlign', workflows: DEFAULT_WORKFLOWS.concat('selfSign') },
	{ name: 'dateFormat', workflows: DEFAULT_WORKFLOWS },
	{ name: 'selectOptions', workflows: DEFAULT_WORKFLOWS },
	{ name: 'chargeAfterExecuted', workflows: DEFAULT_WORKFLOWS },
	{ name: 'paymentAmount', workflows: DEFAULT_WORKFLOWS },
	{ name: 'helpText', workflows: DEFAULT_WORKFLOWS },
	{ name: 'isMergeField', workflows: ['reusableTemplate'] },
	{ name: 'isDateSigned', workflows: DEFAULT_WORKFLOWS },
	{ name: 'checkboxes', workflows: DEFAULT_WORKFLOWS },
	{ name: 'paymentType', workflows: DEFAULT_WORKFLOWS },
];

export const SortedAttributesList: string[] = [
	'assignedTo',
	'groupRequirement',
	'paymentType',
	'isRequired',
	'transparent',
	'fontSize',
	'name',
	'isDateSigned',
	'dateFormat',
	'multiline',
	'value',
	'textAlign',
	'selectOptions',
	'checkboxes',
	'helpText',
];

const COMPONENT_ATTRIBUTES: Record<ComponentTypes, string[]> = {
	SignatureComponent: ['name', 'assignedTo', 'isRequired', 'transparent'],
	TextComponent: [
		'name',
		'assignedTo',
		'isRequired',
		'multiline',
		'transparent',
		'textAlign',
		'value',
		'isMergeField',
	],
	DateComponent: [
		'name',
		'assignedTo',
		'isRequired',
		'transparent',
		'textAlign',
		'dateFormat',
		'isMergeField',
		'isDateSigned',
	],
	CheckComponent: ['name', 'assignedTo', 'isRequired', 'transparent', 'isMergeField'],
	CheckGroupComponent: [
		'name',
		'assignedTo',
		'isRequired',
		'transparent',
		'groupRequirement',
		'isMergeField',
		'checkboxes',
	],
	InitialsComponent: ['name', 'assignedTo', 'isRequired', 'transparent', 'textAlign'],
	SelectComponent: [
		'name',
		'assignedTo',
		'isRequired',
		'transparent',
		'selectOptions',
		'isMergeField',
	],
	PaymentComponent: [
		'name',
		'assignedTo',
		'isRequired',
		'helpText',
		'paymentType',
		'transparent',
	],
	AttachmentComponent: ['name', 'assignedTo', 'isRequired', 'transparent', 'helpText'],
	CheckmarkAnnotationComponent: [
		'name',
		'assignedTo',
		'isRequired',
		'transparent',
		'isMergeField',
	],
	TextAnnotationComponent: ['assignedTo', 'fontSize', 'transparent'],
	SignatureAnnotationComponent: ['name', 'assignedTo', 'isRequired', 'transparent'],
};

export const OFFSET_X = 10; // Minimum margin on right end of the document. Required to avail some space for mandatory field indicator *
export const DEFAULT_GAP_CHECKBOXES = 4;
export function getAllFields(): Field[] {
	return [
		{
			type: ComponentTypes.SignatureComponent,
			label: t('esign-pilet-ui:signature'),
			icon: SignIcon,
		},
		{
			type: ComponentTypes.InitialsComponent,
			label: t('esign-pilet-ui:initials'),
			icon: InitialsIcon,
		},
		{
			type: ComponentTypes.DateComponent,
			label: t('esign-pilet-ui:date'),
			icon: CalendarIcon,
		},
		{
			type: ComponentTypes.TextComponent,
			label: t('esign-pilet-ui:text'),
			icon: TextInputIcon,
		},
		{
			type: ComponentTypes.SelectComponent,
			label: t('esign-pilet-ui:SelectFieldLabel'),
			icon: DropDownIcon,
		},
		{
			type: ComponentTypes.CheckComponent,
			label: t('esign-pilet-ui:checkbox'),
			icon: CheckBoxIcon,
		},
		{
			type: ComponentTypes.CheckGroupComponent,
			label: t('esign-pilet-ui:checkboxGroup'),
			icon: CheckboxGroupIcon,
		},
		{
			type: ComponentTypes.AttachmentComponent,
			label: t('esign-pilet-ui:prepareDocument.components.attachment'),
			icon: PaperclipIcon,
		},
		{
			type: ComponentTypes.PaymentComponent,
			label: t('esign-pilet-ui:PaymentField.defaultLabel'),
			icon: CreditCardDollarIcon,
		},
		{
			type: ComponentTypes.SignatureAnnotationComponent,
			label: t('esign-pilet-ui:selfSignature'),
			icon: SignIcon,
		},
		{
			type: ComponentTypes.TextAnnotationComponent,
			label: t('esign-pilet-ui:prepareDocument.components.textAnnotation'),
			icon: TextInputIcon,
		},
		{
			type: ComponentTypes.CheckmarkAnnotationComponent,
			label: t('esign-pilet-ui:prepareDocument.components.checkmarkAnnotation'),
			icon: CheckBoxIcon,
		},
	];
}

export function getEditableAttributes(
	componentType: ComponentTypes,
	workflow: string
): string[] {
	let editableAttributes: string[] = COMPONENT_ATTRIBUTES[componentType];
	return editableAttributes.filter(attr => {
		let attrWorkflows = AVAILABLE_ATTRIBUTES.find(a => a.name === attr).workflows;
		return attrWorkflows.includes(workflow);
	});
}

export function getDefaultComponent(componentType: ComponentTypes): Component {
	let component = {} as Component;
	component.isRequired = true;
	component.groupRequirement = 'only_one';
	component.fontSize = 14;
	component.multiline = false;
	component.transparent = true;
	component.textAlign = 'left';
	component.selectOptions = ['Option 1', 'Option 2'];
	component.chargeAfterExecuted = false;
	component.isMergeField = false;
	component.dateFormat = DateFormatTypes.MMDDYYYY;
	component.isAnnotation = false;

	if (componentType === ComponentTypes.SelectComponent) {
		component.showErrorOnResizable = false;
	}

	if (componentType === ComponentTypes.CheckComponent) {
		component.isRequired = false;
	}

	return component;
}

export function getDefaultComponentNames(componentType: ComponentTypes): string {
	let translationLabel = componentType.replace('Component', 'FieldLabel');
	return t(translationLabel);
}

export function isAnnotationComponent(componentType: ComponentTypes): boolean {
	return (
		componentType === ComponentTypes.TextAnnotationComponent ||
		componentType === ComponentTypes.SignatureAnnotationComponent ||
		componentType === ComponentTypes.CheckmarkAnnotationComponent
	);
}

export function getDefaultHelpText(componentType: ComponentTypes) {
	switch (componentType) {
		case ComponentTypes.AttachmentComponent:
			return t(
				'esign-pilet-ui:prepareDocument.components.editor.defaultHelpText.attachmentComponent'
			);
		case ComponentTypes.PaymentComponent:
			return t(
				'esign-pilet-ui:prepareDocument.components.editor.defaultHelpText.paymentComponent'
			);
		default:
			return '';
	}
}

const SEQUENCE_COLOUR_NAMES = [
	'blue',
	'orange',
	'teal',
	'purple',
	'yellow',
	'green',
	'skyblue',
	'maroon',
	'pine',
	'lavender',
	'seagreen',
	'peach',
	'greyscale',
];

const SEQUENCE_COLOUR_HEXCODES = [
	'#0045DB',
	'#F96800',
	'#1C9CAD',
	'#691CC2',
	'#D69600',
	'#4A9912',
	'#3894FF',
	'#A34400',
	'#005463',
	'#8F3BF2',
	'#36C2CC',
	'#FF7373',
	'#171717',
];

export const SIGNER_COLORS = SEQUENCE_COLOUR_NAMES.map((name, index) => {
	return { name: name, color: SEQUENCE_COLOUR_HEXCODES[index] };
});

export const colourCount = SIGNER_COLORS.length;

export const FieldColors = Object.assign(
	{},
	...SEQUENCE_COLOUR_NAMES.map((name, index) => {
		return { [name]: SEQUENCE_COLOUR_HEXCODES[index] };
	})
);

export const Direction = {
	Top: 'n',
	Left: 'w',
	Right: 'e',
	Bottom: 's',
	TopLeft: 'nw',
	TopRight: 'ne',
	BottomLeft: 'sw',
	BottomRight: 'se',
} as const;

export const defaultComponentDimensions: Record<
	ComponentTypes,
	{
		width: number;
		height: number;
		minWidth: number;
		minHeight: number;
		labelThresholdWidth: number;
		aspectRatio: number;
		lockAspectRatio: boolean;
	}
> = {
	CheckComponent: {
		width: 24,
		height: 24,
		minWidth: 14,
		minHeight: 14,
		labelThresholdWidth: 24,
		aspectRatio: 1,
		lockAspectRatio: true,
	},
	CheckGroupComponent: {
		width: 24,
		height: 24,
		minWidth: 14,
		minHeight: 14,
		labelThresholdWidth: 24,
		aspectRatio: 1,
		lockAspectRatio: true,
	},
	CheckmarkAnnotationComponent: {
		width: 24,
		height: 24,
		minWidth: 14,
		minHeight: 14,
		labelThresholdWidth: 24,
		aspectRatio: 1,
		lockAspectRatio: true,
	},
	SignatureComponent: {
		width: 256,
		height: 64,
		minWidth: 96,
		minHeight: 24,
		labelThresholdWidth: 132,
		aspectRatio: 4,
		lockAspectRatio: true,
	},
	SignatureAnnotationComponent: {
		width: 256,
		height: 64,
		minWidth: 96,
		minHeight: 24,
		labelThresholdWidth: 167,
		aspectRatio: 4,
		lockAspectRatio: true,
	},
	TextComponent: {
		width: 296,
		height: 37,
		minWidth: 24,
		minHeight: 24,
		labelThresholdWidth: 77,
		aspectRatio: 4,
		lockAspectRatio: false,
	},
	TextAnnotationComponent: {
		width: 148,
		height: 18.5,
		minWidth: 1,
		minHeight: 1,
		labelThresholdWidth: 24,
		aspectRatio: 4,
		lockAspectRatio: false,
	},
	SelectComponent: {
		width: 160,
		height: 40,
		minWidth: 96,
		minHeight: 24,
		labelThresholdWidth: 130,
		aspectRatio: 4,
		lockAspectRatio: false,
	},
	DateComponent: {
		width: 160,
		height: 37,
		minWidth: 24,
		minHeight: 24,
		labelThresholdWidth: 112,
		aspectRatio: 4,
		lockAspectRatio: false,
	},
	InitialsComponent: {
		width: 99,
		height: 36,
		minWidth: 24,
		minHeight: 24,
		labelThresholdWidth: 98,
		aspectRatio: 4,
		lockAspectRatio: false,
	},
	AttachmentComponent: {
		width: 140,
		height: 40,
		minWidth: 24,
		minHeight: 24,
		labelThresholdWidth: 130,
		aspectRatio: 3.5,
		lockAspectRatio: false,
	},
	PaymentComponent: {
		width: 229,
		height: 40,
		minWidth: 24,
		minHeight: 24,
		labelThresholdWidth: 200,
		aspectRatio: 4,
		lockAspectRatio: false,
	},
};

export const defaultCheckBoxAnnotationIconSize = 13;

export type DirectionType = keyof typeof Direction;
export type DirectionValueType = (typeof Direction)[DirectionType];

export const defaultComponentHandles: Record<
	ComponentTypes,
	Array<DirectionValueType>
> = {
	CheckComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	CheckGroupComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	SignatureComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	TextComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	SelectComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	DateComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	InitialsComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	AttachmentComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	PaymentComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	CheckmarkAnnotationComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	SignatureAnnotationComponent: [
		Direction.TopLeft,
		Direction.BottomLeft,
		Direction.BottomRight,
		Direction.TopRight,
	],
	TextAnnotationComponent: [],
};
export const defaultWidth = 1100;
export const defaultHeight = 1424;
export const minTextAnnotationWidth = 8;
export const HEADER_HEIGHT = 80,
	FOOTER_HEIGHT = 64,
	PADDING = 20,
	MARGIN_BOTTOM = 10;

const fontSizes = [
	'8',
	'9',
	'10',
	'11',
	'12',
	'14',
	'16',
	'18',
	'20',
	'24',
	'28',
	'32',
	'36',
	'40',
	'48',
	'56',
	'64',
	'72',
	'80',
];

export const fontSizeOptions = () => {
	return fontSizes.map(val => {
		return { label: val + 'px', value: val };
	});
};

const getHeightAndWidth = (string, fontSize) => {
	const element = document.createElement('span');
	element.style.fontFamily = 'Courier, sans-serif';
	element.style.fontSize = fontSize + 'px';
	element.style.position = 'absolute';

	document.body.appendChild(element);
	element.innerHTML = string;
	const textWidth = Math.ceil(element.offsetWidth);
	const textHeight = Math.ceil(element.offsetHeight);
	document.body.removeChild(element);
	return {
		width: textWidth,
		height: textHeight,
	};
};

export const sanitizeValueText = (val, availableWidth, fontSize) => {
	const allLines = val.split('\n');
	const sanitizedValue = [];
	for (let i = 0; i < allLines.length; i++) {
		const line = allLines[i];
		let potentialLine = '';
		const sanitizeString = [];
		for (let j = 0; j < line.length; j++) {
			const char = line[j];
			potentialLine = potentialLine + char;
			const potentialHeightAndWidth = getHeightAndWidth(potentialLine, fontSize);
			if (potentialHeightAndWidth['width'] >= availableWidth - PAGE_WIDTH_THRESHOLD) {
				sanitizeString.push(potentialLine.slice(0, -1));
				sanitizeString.push('\n');
				potentialLine = char;
			}
		}
		if (!!potentialLine) {
			sanitizeString.push(potentialLine);
		}
		sanitizedValue.push(sanitizeString.join(''));
	}
	return sanitizedValue.join('\n');
};

export const getParentHeightAndWidth = (elementID: string) => {
	const parentPos = window.document.getElementById(elementID)?.getBoundingClientRect();
	const parentWidth = parentPos?.width ? parentPos.width : defaultWidth;
	const parentHeight = parentPos?.height ? parentPos.height : defaultHeight;

	return { parentWidth, parentHeight };
};

export const pageID = (component: Component) =>
	`page-${component.page ? component.page : 1}`;

export const getAssignedTo = (field: Component) => {
	if (field.assignedTo === null) {
		return null;
	}
	return field.assignedTo.roleName;
};

export function sanitize(components: Component[]) {
	return components.map(field => {
		if (field.type === ComponentTypes.TextAnnotationComponent) {
			const { parentWidth } = getParentHeightAndWidth(pageID(field));
			const availableWidth = parentWidth - field.x * parentWidth;
			const sanitizedValue = sanitizeValueText(
				field.value,
				availableWidth,
				field.fontSize
			);
			field.value = sanitizedValue;
		}

		return {
			...transformCamelCaseKeysToSnakeCase(field),
			x: field.x,
			y: field.y,
			width: field.width,
			height: field.height,
			assigned_to: getAssignedTo(field),
		} as IComponentPayload;
	});
}

export const prepareSigners = (
	recipients,
	documentId,
	signerMessage = null
): DocumentSigner[] =>
	(recipients ?? [])
		?.filter(recipient => recipient.role === '0')
		?.map((signer, idx) => ({
			document_template_id: documentId,
			document_template_type: 'Document',
			is_sender: signer.isSender,
			name: signer.roleName,
			sequence: idx,
			signer_id: signer.signerId,
			signer_email: signer.email,
			signer_name: signer.name,
			message: signerMessage,
		}));

export const emptyRecipient = (): IRecipient => {
	return {
		id: uuidv4(),
		name: '',
		email: '',
		role: '0',
		duplicate: false,
		roleName: 'signer1',
		isSender: false,
	} as IRecipient;
};

export const getComponentBackgroundClass = (
	component: Component,
	signerColor: string
) => {
	if (!component.transparent) {
		if (component.isAnnotation || isAnnotationComponent(component.type)) {
			return 'page-component-opaque-greyscale';
		} else {
			return 'page-component-opaque-' + signerColor;
		}
	}
	return '';
};
