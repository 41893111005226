// This storage is used to save signers
import { StateCreator } from 'zustand';
import { DocumentSigner } from '../../data/rsTypes';
import { storeResetFns } from '.';

type State = {
	signers: DocumentSigner[];
};

type Actions = {
	setSigners: (signers: DocumentSigner[]) => void;
};

export type SignersStore = State & Actions;

const initialState: State = {
	signers: [],
};

export const createSignersSlice: StateCreator<SignersStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setSigners(signers) {
			set(() => ({ signers: signers }));
		},
	};
};
