// This storage is used to save document's attachments
import { StateCreator } from 'zustand';
import { storeResetFns } from '.';

type State = {
	documentAttachments: Array<any>;
};

type Actions = {
	setDocumentAttachments: (documentAttachments: Array<any>) => void;
};

export type DocumentAttachmentsStore = State & Actions;

const initialState: State = {
	documentAttachments: undefined,
};

export const createDocumentAttachmentsSlice: StateCreator<
	DocumentAttachmentsStore
> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setDocumentAttachments(documentAttachments) {
			set(() => ({ documentAttachments: documentAttachments }));
		},
	};
};
