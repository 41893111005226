// This storage is used to save signer's signatures
import { StateCreator } from 'zustand';
import { SignatureResponse } from '../../data/rsTypes';
import { storeResetFns } from '.';

type State = {
	signatures: Array<any>;
	lastSavedSignature: SignatureResponse | null;
};

type Actions = {
	setSignatures: (signatures: Array<any>) => void;
	setLastSavedSignature: (signature: SignatureResponse | null) => void;
};

export type SignaturesStore = State & Actions;

const initialState: State = {
	signatures: undefined,
	lastSavedSignature: null,
};

export const createSignaturesSlice: StateCreator<SignaturesStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setSignatures(signatures) {
			set(() => ({ signatures: signatures }));
		},
		setLastSavedSignature(signature) {
			set(() => ({ lastSavedSignature: signature }));
		},
	};
};
