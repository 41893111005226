import React from 'react';
import { ArrowSync, DeclineDocument, Download, FloppyDisk } from '@sharefiledev/icons';
import { MenuProps } from 'antd';
import { t } from '../../../util';

export interface Props {
	onSave: () => Promise<any>;
	onDownload: () => void;
	onDecline: () => void;
	enableDownload: boolean;
	onSwitchExperience: () => void;
}

export const generateDocumentActionsDropdownContent = (
	props: Props
): MenuProps['items'] => {
	const content: MenuProps['items'] = [
		{
			icon: <FloppyDisk size={16} />,
			label: t('esign-pilet-ui:signerPage.header.documentActions.saveProgress'),
			key: 'save',
			style: { gap: 4 },
			onClick: props.onSave,
		},
		{
			icon: <ArrowSync size={16} />,
			label: t('esign-pilet-ui:signerPage.header.documentActions.switchExperience'),
			key: 'switch',
			style: { gap: 4 },
			onClick: props.onSwitchExperience,
		},
		{
			icon: <DeclineDocument size={16} />,
			label: t('esign-pilet-ui:signerPage.header.documentActions.declineDocument'),
			key: 'decline',
			style: { gap: 4 },
			danger: true,
			onClick: props.onDecline,
		},
	];

	if (props.enableDownload) {
		content.splice(1, 0, {
			icon: <Download size={16} />,
			label: t('esign-pilet-ui:signerPage.header.documentActions.downloadOriginal'),
			key: 'download',
			style: { gap: 4 },
			onClick: props.onDownload,
		});
	}

	return content;
};
