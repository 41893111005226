import React, { memo } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import {
	CalendarMark,
	CheckCircle,
	CheckSquare,
	DropdownMenu,
	FillAndSign,
	Initials,
	MultiSelect,
	Paperclip,
	Payment,
	TextEditingTyping,
} from '@sharefiledev/icons';
import { Flex, Typography } from 'antd';
import { ComponentTypes } from '../../Common/Components/types';
import { MandatoryFieldIndicator } from '../../Common/ComponentsRenderer/MandatoryFieldIndicator';
import { useAppStore } from '../../Common/store';
import { Component } from '../../data/rsTypes';
import { t } from '../../util';
import { StyledCard } from './ProgressFieldComponent.styled';

const { Text } = Typography;

const textStyles: React.CSSProperties = {
	fontWeight: '400',
	lineHeight: '22px',
	textAlign: 'left',
};

interface ProgressFieldProps {
	component: Component;
	isValid: boolean;
	onSelectComponentId: (componentId: string) => void;
}

const renderFieldIcon = (type: string, iconColor: string): JSX.Element => {
	switch (type) {
		case ComponentTypes.TextComponent:
			return <TextEditingTyping color={iconColor} data-testid="text-field" />;
		case ComponentTypes.SignatureComponent:
			return <FillAndSign color={iconColor} data-testid="signature-field" />;
		case ComponentTypes.InitialsComponent:
			return <Initials color={iconColor} data-testid="initials-field" />;
		case ComponentTypes.PaymentComponent:
			return <Payment color={iconColor} data-testid="payment-field" />;
		case ComponentTypes.SelectComponent:
			return <DropdownMenu color={iconColor} data-testid="select-field" />;
		case ComponentTypes.DateComponent:
			return <CalendarMark color={iconColor} data-testid="data-field" />;
		case ComponentTypes.AttachmentComponent:
			return <Paperclip color={iconColor} data-testid="attachment-field" />;
		case ComponentTypes.CheckComponent:
			return <CheckSquare color={iconColor} data-testid="check-field" />;
		case ComponentTypes.CheckGroupComponent:
			return <MultiSelect color={iconColor} data-testid="checkgroup-field" />;
		default:
			return <TextEditingTyping color={iconColor} data-testid="default-field" />;
	}
};

export const ProgressFieldComponent = memo(function ProgressFieldComponent(
	props: ProgressFieldProps
) {
	const selectedComponentId = useAppStore(state => state.selectedComponentId);

	const pendingColor = colorPalette.neutral7;
	const checkmarkColor = colorPalette.green8;
	const iconColor = colorPalette.lavender7;

	const onCardClick = () => {
		props.onSelectComponentId(props.component.id);
	};

	return (
		<StyledCard
			size="small"
			data-testid="field-card"
			id={props.component.id}
			selected={
				selectedComponentId === props.component.id ||
				!!props.component.group_items?.find(item => item.id === selectedComponentId)
			}
			onClick={onCardClick}
		>
			<Flex align="center" gap="middle">
				{renderFieldIcon(props.component.type, iconColor)}
				<Text style={textStyles}>
					{props.component.name}{' '}
					{props.component.is_required && (
						<MandatoryFieldIndicator
							displayOutside={false}
							scale={1}
							textAligned={true}
						/>
					)}
				</Text>

				<Flex align="center" style={{ marginLeft: 'auto', height: sizes.MS }}>
					{props.isValid ? (
						<CheckCircle size={24} color={checkmarkColor} data-testid="checkmark-icon" />
					) : (
						<Text
							style={{
								fontSize: sizes.SM,
								color: pendingColor,
								textWrap: 'nowrap',
							}}
						>
							{t('esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.pending')}
						</Text>
					)}
				</Flex>
			</Flex>
		</StyledCard>
	);
});
