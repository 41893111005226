import React from 'react';
import { css, Global } from '@emotion/react';
import { type TabsProps, Tabs } from 'antd';
import { useAppStore } from '../../Common/store';
import { t } from '../../util';
import { DocumentDetails } from './DocumentDetails/DocumentDetails';
import { ProgressTracker } from './ProgressTracker';

export const SidePanelTabs: React.FC = () => {
	const setSelectedComponentId = useAppStore(state => state.setSelectedComponentId);
	const items: TabsProps['items'] = [
		{
			key: '1',
			label: <>{t('esign-pilet-ui:signerPage.content.sidePanelTabs.label.fields')}</>,
			children: <ProgressTracker onSelectComponentId={setSelectedComponentId} />,
		},
		{
			key: '2',
			label: <>{t('esign-pilet-ui:signerPage.content.sidePanelTabs.label.details')}</>,
			children: <DocumentDetails />,
		},
	];
	return (
		<>
			<Global
				styles={css`
					.side-panel-tabs .ant-tabs-nav-list {
						margin-left: 6px;
					}
				`}
			/>
			<Tabs
				items={items}
				renderTabBar={(tabBarProps, DefaultTabBar) => (
					<DefaultTabBar className="side-panel-tabs" {...tabBarProps}>
						{node => {
							return React.cloneElement(node, {
								tabIndex: 40 + node.key,
								id: `side-bar-tab-${40 + node.key}`,
							});
						}}
					</DefaultTabBar>
				)}
			/>
		</>
	);
};
