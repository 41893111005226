export function transformSnakeCaseKeysToCamelCase(obj) {
	return Object.keys(obj).reduce((accumulator, key) => {
		accumulator[
			key.replace(/_[a-z]/g, match => `${match.toUpperCase().replace('_', '')}`)
		] = obj[key];
		return accumulator;
	}, {});
}

export function transformCamelCaseKeysToSnakeCase(obj) {
	return Object.keys(obj).reduce((accumulator, key) => {
		accumulator[key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)] = obj[key];
		return accumulator;
	}, {});
}
