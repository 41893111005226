import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { sizes } from '@sharefiledev/antd-config';
import { LoadingPrimary } from '@sharefiledev/flow-web';
import { Grid, Space, theme } from 'antd';
import { VariableSizeList } from 'react-window';
import { Component, DocumentSigner, ScrollToPageNumber } from '../../data/rsTypes';
import { Navigation } from '../Navigation/Navigation';
import { useAppStore } from '../store';
import { PageSelector } from './PageSelector';
import {
	DocumentContainer,
	DocumentNavbar,
	PDFViewerContainer,
	StyledPage,
} from './PDFViewer.styled';
import { ZoomControls } from './ZoomControls';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const HEADER_HEIGHT = 56,
	PAGINATION_HEIGHT = 56,
	PADDING = 20,
	MARGIN_BOTTOM = 10,
	NAVBAR_HEIGHT = 64;

interface PDFViewerProps {
	pdf: any;
	onPageClick?: (pageNumber: number) => void;
	pageHeight?: number;
	overlay?: (pageMetadata: Record<string, any>) => React.ReactNode;
	loader?: React.ReactNode;
	highlightClickedPage?: boolean;
	enablePagination?: boolean;
	scrollToPageNumber?: ScrollToPageNumber;
	maxPageWidth?: number;
	pageContainerStyle?: React.CSSProperties;
	pageStyle?: React.CSSProperties;
	components?: Component[];
	signer?: DocumentSigner;
	canShowZoomInOutOptions?: boolean;
}

const defaultLoader = (
	<LoadingPrimary size={48} style={{ position: 'absolute', top: '50%' }} />
);

export const PDFViewer = memo(
	({
		pdf: pdfFile,
		onPageClick,
		pageHeight,
		overlay,
		loader,
		highlightClickedPage,
		enablePagination,
		scrollToPageNumber = { page: 1 },
		maxPageWidth,
		pageContainerStyle = {},
		pageStyle = {},
		components,
		signer,
		canShowZoomInOutOptions = true,
	}: PDFViewerProps) => {
		const { token } = useToken();
		const screens = useBreakpoint();
		const listRef = useRef();
		const documentRef = useRef<HTMLDivElement>(null);
		const documentContainerRef = useRef<HTMLDivElement>(null);
		const hasDifferentPageSizes = useRef(false);
		const [numPages, setNumPages] = useState<number>(0);
		const maxScale = useRef<number | null>(0);

		const [pageIndexRatio, setPageIndexRatio] = useState<Map<any, any> | null>(null);
		const [updatePageOnResize, setUpdatePageOnResize] = useState(true);
		const [selectedPage, setSelectedPage] = useState(1);
		const [currentPage, setCurrentPage] = useState(1);
		const [pageWidth, setPageWidth] = useState(0);

		const zoomLevel = useRef<number>(1);
		const zoomIncrement = 0.05;
		const maxZoomLimit = 3;
		const minZoomLimit = 1;

		// getting this value from : https://code.sharefile-coretools.com/projects/WAPP/repos/esign-pilet/browse/src/SignerPage/PageContent/DocumentViewContainer.tsx#56
		const topMargin = screens.xs ? 0 : parseInt(sizes.XXL, 10);

		const initialHeight = window.innerHeight - HEADER_HEIGHT - NAVBAR_HEIGHT - topMargin;
		const containerHeight = enablePagination
			? initialHeight - PAGINATION_HEIGHT
			: initialHeight;

		const selectedComponentId = useAppStore(state => state.selectedComponentId);

		const computePageWidth = useCallback(() => {
			let newPageWidth = documentRef?.current?.offsetWidth;

			if (pageHeight) {
				newPageWidth = pageHeight / maxScale.current;
			}

			if (maxPageWidth) {
				newPageWidth = newPageWidth < maxPageWidth ? newPageWidth : maxPageWidth;
			}

			setPageWidth(newPageWidth);
		}, [maxPageWidth, pageHeight]);

		const onDocumentLoadSuccess = useCallback(
			pdfDocument => {
				const promises = Array.from(
					{ length: pdfDocument.numPages },
					(_, i) => i + 1
				).map(pageNumber => {
					return pdfDocument.getPage(pageNumber);
				});

				let scale: number | null = null,
					previousAspectRatio;

				Promise.all(promises).then(pages => {
					const map = new Map<number, number>();

					for (const page of pages) {
						const w = page.view[2] || 0;
						const h = page.view[3] || 0;
						const index = page.pageNumber ?? 0;
						const heightByWidthRatio = h / w,
							widthByHeightRatio = w / h;

						// if rotate is odd multiple of 90 degree use widthByHeightRatio else use heightByWidthRatio
						const ratio =
							page.rotate / 90 / 2 === 0 ? heightByWidthRatio : widthByHeightRatio;
						map.set(index, ratio);

						if (!previousAspectRatio) {
							previousAspectRatio = ratio;
						}
						if (ratio !== previousAspectRatio) {
							hasDifferentPageSizes.current = true;
						}

						scale = Math.max(ratio, scale!);
					}

					maxScale.current = scale;
					if (documentRef.current) {
						setPageIndexRatio(map);
						setNumPages(pdfDocument?.numPages);
						computePageWidth();
					}
				});
			},
			[computePageWidth]
		);

		const rowHeight = useRef(0);
		const computeRowHeight = useCallback(() => {
			if (pageHeight) {
				return pageHeight + PADDING;
			}
			const documentWidth = documentRef.current!.offsetWidth;
			rowHeight.current = Math.ceil(
				maxScale.current! * documentWidth +
					(hasDifferentPageSizes.current ? MARGIN_BOTTOM : PADDING)
			);
			return rowHeight.current;
		}, [pageHeight]);

		const onScroll = ({ scrollDirection, scrollOffset }) => {
			const pagefactor = 2.25; // Update page number when atleast half of the page is visible
			const isForwardScroll = scrollDirection === 'forward';
			let thresholdPageValue = 0;

			if (isForwardScroll) {
				thresholdPageValue = Math.abs(
					currentPage * rowHeight.current - containerHeight / pagefactor
				);

				if (scrollOffset > thresholdPageValue) {
					setCurrentPage(num => num + 1);
				}
			} else {
				const page = currentPage > 1 ? currentPage - 1 : currentPage;
				thresholdPageValue = Math.abs(
					page * rowHeight.current - containerHeight / pagefactor
				);

				if (thresholdPageValue > scrollOffset) {
					setCurrentPage(num => Math.max(num - 1, 1));
				}
			}
		};

		useEffect(() => {
			const resizeObserver = new ResizeObserver(_ => {
				if (listRef.current) {
					(listRef.current as any).resetAfterIndex(0);
				}
				computePageWidth();
				setUpdatePageOnResize(false);
				setTimeout(() => {
					setUpdatePageOnResize(true);
				}, 0);
			});

			if (documentRef.current) {
				resizeObserver.observe(documentRef.current);
			}

			// Since the ref value can change we need to remember it, before we cleanup.
			const documentRefCopy = documentRef.current;
			return () => {
				if (documentRefCopy) {
					resizeObserver.unobserve(documentRefCopy);
				}
			};
		}, [computePageWidth]);

		const zoomEvent = React.useMemo(
			() => (zoomFactor: number) => {
				return new CustomEvent('zoom', {
					detail: { zoomFactor: zoomFactor },
				});
			},
			[]
		);

		const roundOffValue = (value: number) => {
			return Math.round(value * 100) / 100;
		};

		const setZoomLevel = (zoom: number) => {
			zoomLevel.current = zoom;
			documentRef.current.style.transform = `scale(${zoom})`;
		};

		const handleZoomIn = useCallback(
			(zoomAmount = null) => {
				zoomAmount = zoomAmount ? zoomAmount : zoomIncrement;

				if (zoomLevel.current + zoomAmount <= maxZoomLimit) {
					setZoomLevel(roundOffValue(zoomLevel.current + zoomAmount));
					window.dispatchEvent(zoomEvent(zoomLevel.current));
				}
			},
			[zoomEvent]
		);

		const handleZoomOut = useCallback(
			(zoomAmount = null) => {
				zoomAmount = zoomAmount ? zoomAmount : zoomIncrement;

				if (zoomLevel.current - zoomAmount >= minZoomLimit) {
					setZoomLevel(roundOffValue(zoomLevel.current - zoomAmount));
					window.dispatchEvent(zoomEvent(zoomLevel.current));
				}
			},
			[zoomEvent]
		);

		React.useEffect(() => {
			if (screens.xs) {
				const component = document.querySelector(
					`#SignerComponent-${selectedComponentId}`
				);
				if (component) {
					component.scrollIntoView({
						block: 'center',
						inline: 'center',
					});
				}
			}
		}, [screens.xs, selectedComponentId]);

		const onPageChange = (num: number) => {
			setCurrentPage(num);
			if (listRef.current) {
				(listRef.current as any).scrollToItem(num - 1, 'start');
			}
		};

		React.useEffect(() => {
			if (listRef.current && scrollToPageNumber) {
				if (Math.abs(currentPage - scrollToPageNumber.page) > 1) {
					(listRef.current as any).scrollToItem(scrollToPageNumber.page - 1, 'start');
					setCurrentPage(scrollToPageNumber.page);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [scrollToPageNumber]);

		const [isLandScape, setIsLandScape] = useState(
			window.innerWidth > window.innerHeight
		);

		useEffect(() => {
			const handleResize = () => {
				const orientationChange = window.innerWidth > window.innerHeight;
				if (orientationChange !== isLandScape) {
					setIsLandScape(orientationChange);
					setZoomLevel(1);
				}
			};

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, [isLandScape]);

		useEffect(() => {
			const setViewport = (content: string) => {
				let viewportMeta = document.querySelector('meta[name="viewport"]');
				if (!viewportMeta) {
					viewportMeta = document.createElement('meta');
					viewportMeta.setAttribute('name', 'viewport');
					document.head.appendChild(viewportMeta);
				}
				viewportMeta.setAttribute('content', content);
			};

			if (screens.xs) {
				setViewport('width=device-width, initial-scale=1.0, maximum-scale=1.0');
			} else {
				setViewport('width=device-width, initial-scale=1.0');
			}

			return () => {
				setViewport('width=device-width, initial-scale=1.0');
			};
		}, [screens.xs]);

		const triggerReflow = (element: HTMLDivElement) => {
			return element.offsetHeight;
		};

		const repositionPage = useCallback(({ scrollLeft, scrollTop }) => {
			const documentContainer = documentContainerRef.current;

			// Webkit engine specific changes to force reflow
			documentContainer.style.display = 'none';
			triggerReflow(documentContainer);
			documentContainer.style.display = 'block';

			documentContainer.scrollLeft = scrollLeft;
			documentContainer.scrollTop = scrollTop;
		}, []);

		useEffect(() => {
			const handleKeyDown = (event: KeyboardEvent) => {
				if ((event.ctrlKey || event.metaKey) && event.key === '=') {
					event.preventDefault();
					handleZoomIn();
				} else if ((event.ctrlKey || event.metaKey) && event.key === '-') {
					event.preventDefault();
					handleZoomOut();
				} else if (event.key === 'ArrowUp') {
					//To fix bug in react-window where it doesn't scroll to the top when zoomed in
					if (
						zoomLevel.current !== 1 &&
						(listRef.current as any).state.scrollOffset === 0
					) {
						const tempOffset = documentContainerRef.current.scrollTop / zoomLevel.current;
						repositionPage({
							scrollLeft: documentContainerRef.current.scrollLeft,
							scrollTop: 0,
						});
						(listRef.current as any).scrollTo(tempOffset);
					}
				}
			};

			window.addEventListener('keydown', handleKeyDown);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}, [handleZoomIn, handleZoomOut, repositionPage]);

		const isPointerInsideDocument = useCallback((mouseX: number, mouseY: number) => {
			const documentContainer = documentContainerRef.current;
			if (!documentContainer) {
				return false;
			}

			const rect = documentContainer.getBoundingClientRect();
			return (
				mouseX >= rect.left &&
				mouseX <= rect.right &&
				mouseY >= rect.top &&
				mouseY <= rect.bottom
			);
		}, []);

		const calculateScrollValues = useCallback(
			({ mouseX, mouseY, zoomAmount = null, increment = true }) => {
				const documentContainer = documentContainerRef.current;
				const rect = documentContainer.getBoundingClientRect();
				mouseX -= rect.left;
				mouseY -= rect.top;

				const requiredZoomIncrement = zoomAmount ? zoomAmount : zoomIncrement;

				let newZoom = 0;
				if (increment) {
					newZoom = Math.min(
						roundOffValue(zoomLevel.current + requiredZoomIncrement),
						maxZoomLimit
					);
				} else {
					newZoom = Math.max(
						roundOffValue(zoomLevel.current - requiredZoomIncrement),
						minZoomLimit
					);
				}

				const scrollLeft = (mouseX + documentContainer.scrollLeft) / zoomLevel.current;
				const scrollTop = (mouseY + documentContainer.scrollTop) / zoomLevel.current;

				const newScrollLeft = scrollLeft * newZoom - mouseX;
				const newScrollTop = scrollTop * newZoom - mouseY;

				return {
					scrollLeft: roundOffValue(newScrollLeft),
					scrollTop: roundOffValue(newScrollTop),
				};
			},
			[]
		);

		useEffect(() => {
			const handleWheel = (event: WheelEvent) => {
				if (event.ctrlKey) {
					event.preventDefault();

					let mouseX = event.clientX;
					let mouseY = event.clientY;
					if (isPointerInsideDocument(mouseX, mouseY)) {
						if (event.deltaY < 0) {
							const { scrollLeft, scrollTop } = calculateScrollValues({ mouseX, mouseY });
							handleZoomIn();
							repositionPage({ scrollLeft, scrollTop });
						} else {
							const { scrollLeft, scrollTop } = calculateScrollValues({
								mouseX,
								mouseY,
								increment: false,
							});
							handleZoomOut();
							repositionPage({ scrollLeft, scrollTop });
						}
					}
				}
			};

			window.addEventListener('wheel', handleWheel, { passive: false });

			return () => {
				window.removeEventListener('wheel', handleWheel);
			};
		}, [
			handleZoomIn,
			handleZoomOut,
			isPointerInsideDocument,
			calculateScrollValues,
			repositionPage,
		]);

		useEffect(() => {
			let initialDistance = null;
			let currentCenter = null;

			const getDistance = (touch1: Touch, touch2: Touch) => {
				const dx = touch2.clientX - touch1.clientX;
				const dy = touch2.clientY - touch1.clientY;
				return Math.sqrt(dx * dx + dy * dy);
			};

			const getCenter = (touch1: Touch, touch2: Touch) => {
				return {
					x: (touch1.clientX + touch2.clientX) / 2,
					y: (touch1.clientY + touch2.clientY) / 2,
				};
			};

			const handleTouchStart = (event: TouchEvent) => {
				if (event.touches.length === 2) {
					initialDistance = getDistance(event.touches[0], event.touches[1]);
					currentCenter = getCenter(event.touches[0], event.touches[1]);
				}
			};

			const handleTouchMove = (event: TouchEvent) => {
				if (event.touches.length === 2 && initialDistance) {
					event.preventDefault();

					const currentDistance = getDistance(event.touches[0], event.touches[1]);

					const zoomAmount = 0.05;

					if (isPointerInsideDocument(currentCenter.x, currentCenter.y)) {
						if (currentDistance > initialDistance) {
							const { scrollLeft, scrollTop } = calculateScrollValues({
								mouseX: currentCenter.x,
								mouseY: currentCenter.y,
								zoomAmount: zoomAmount,
							});
							handleZoomIn(zoomAmount);
							repositionPage({ scrollLeft, scrollTop });
						} else if (currentDistance < initialDistance) {
							const { scrollLeft, scrollTop } = calculateScrollValues({
								mouseX: currentCenter.x,
								mouseY: currentCenter.y,
								zoomAmount: zoomAmount,
								increment: false,
							});
							handleZoomOut(zoomAmount);
							repositionPage({ scrollLeft, scrollTop });
						}
					}

					initialDistance = currentDistance;
				}
			};

			const handleTouchEnd = (event: TouchEvent) => {
				if (event.touches.length < 2) {
					initialDistance = null;
					currentCenter = null;
				}
			};

			window.addEventListener('touchstart', handleTouchStart, { passive: false });
			window.addEventListener('touchmove', handleTouchMove, { passive: false });
			window.addEventListener('touchend', handleTouchEnd, { passive: true });

			return () => {
				window.removeEventListener('touchstart', handleTouchStart);
				window.removeEventListener('touchmove', handleTouchMove);
				window.removeEventListener('touchend', handleTouchEnd);
			};
		}, [
			handleZoomIn,
			handleZoomOut,
			isPointerInsideDocument,
			calculateScrollValues,
			maxZoomLimit,
			repositionPage,
		]);

		const loading = loader || defaultLoader;

		if (!pdfFile) {
			return <>{loading}</>;
		}

		return (
			<PDFViewerContainer style={{ ...pageContainerStyle }} token={token}>
				{components && signer && (
					<Navigation
						components={components}
						signer={signer}
						loaded={numPages > 0}
						resize={updatePageOnResize}
					/>
				)}
				<Space
					direction="vertical"
					size={0}
					style={{
						display: 'block',
						height: '100%',
						width: '100%',
						maxWidth: '1100px',
						overflow: 'scroll',
						scrollbarWidth: 'none',
					}}
					ref={documentContainerRef}
					className="viewer-container-wrapper"
				>
					<DocumentContainer
						ref={documentRef}
						data-testid={`viewer-container`}
						className="viewer-container"
						style={{ minHeight: '50vh' }}
					>
						<Document
							file={pdfFile}
							onLoadSuccess={onDocumentLoadSuccess}
							renderMode="canvas"
							options={{ disableAutoFetch: true, disableStream: true }}
							loading={loading}
						>
							{numPages && (
								<VariableSizeList
									outerRef={elementRefOrNull => {
										if (elementRefOrNull) {
											elementRefOrNull.tabIndex = -1;
										}
									}}
									height={containerHeight}
									itemCount={numPages}
									itemSize={computeRowHeight}
									itemData={{
										pageWidth: pageWidth,
										updatePageOnResize,
										hasDifferentPageSizes: hasDifferentPageSizes.current,
										pageIndexRatio,
										overlay,
										onPageClick,
										selectedPage,
										setSelectedPage,
										highlightClickedPage,
										pageStyle,
										screenXs: screens.xs,
									}}
									overscanCount={1}
									ref={listRef}
									onScroll={onScroll}
									className={'signer-document-viewer'}
									style={{ overflow: 'hidden auto', outline: 'none' }}
								>
									{PageRenderer}
								</VariableSizeList>
							)}
						</Document>
					</DocumentContainer>
				</Space>
				{enablePagination && (
					<DocumentNavbar data-testid={'document-navbar'}>
						<div style={{ display: numPages ? 'flex' : 'none' }}>
							{/* {!props.screenSize.onMobile && ( */}
							<PageSelector
								currentPage={currentPage}
								totalPages={numPages}
								onPageChange={onPageChange}
							/>
							{/* )} */}
							{canShowZoomInOutOptions && (
								<ZoomControls
									zoomFactor={zoomLevel.current}
									onZoomIn={handleZoomIn}
									onZoomOut={handleZoomOut}
								/>
							)}
						</div>
					</DocumentNavbar>
				)}
			</PDFViewerContainer>
		);
	}
);

interface PageRendererProps {
	index: number;
	data: any;
	style: any;
}

const PageRenderer = (props: PageRendererProps) => {
	const { index, data, style } = props;
	const pageNumber = index + 1;
	const {
		pageWidth,
		updatePageOnResize,
		hasDifferentPageSizes,
		overlay,
		pageIndexRatio,
		onPageClick,
		selectedPage,
		setSelectedPage,
		highlightClickedPage,
		pageStyle,
	} = data;

	const pageMetadata = {
		pageNumber,
		pageWidth,
		pageHeight: pageIndexRatio.get(pageNumber) * pageWidth,
		scale: pageIndexRatio.get(pageNumber),
	};

	return (
		<div style={{ ...style }} id={`page-${pageNumber}`}>
			<div
				style={{
					position: 'relative',
					height: hasDifferentPageSizes ? `calc(100% - ${MARGIN_BOTTOM}px)` : '',
				}}
			>
				{overlay && overlay(pageMetadata)}
				<MemoisedDocumentViewerPage
					pageNumber={pageNumber}
					pageWidth={pageWidth}
					updatePageOnResize={updatePageOnResize}
					onPageClick={onPageClick}
					selectedPage={selectedPage}
					setSelectedPage={setSelectedPage}
					highlightClickedPage={highlightClickedPage}
					pageStyle={pageStyle}
				/>
			</div>
		</div>
	);
};

interface MemoisedDocumentViewerPageProps {
	pageNumber: number;
	pageWidth: number;
	updatePageOnResize?: any;
	onPageClick?: PDFViewerProps['onPageClick'];
	selectedPage?: number;
	setSelectedPage?: (page: number) => void;
	highlightClickedPage?: PDFViewerProps['highlightClickedPage'];
	pageStyle?: React.CSSProperties;
}

const MemoisedDocumentViewerPage = memo(
	({
		onPageClick,
		pageNumber,
		pageWidth,
		updatePageOnResize,
		selectedPage,
		setSelectedPage,
		highlightClickedPage,
		pageStyle,
	}: MemoisedDocumentViewerPageProps) => {
		return (
			<StyledPage
				pageNumber={pageNumber}
				width={pageWidth}
				renderTextLayer={false}
				renderAnnotationLayer={false}
				loading={defaultLoader}
				style={{ display: updatePageOnResize ? 'block' : 'none', ...pageStyle }}
				onClick={() => {
					onPageClick!(pageNumber);
					setSelectedPage && setSelectedPage(pageNumber);
				}}
				selected={highlightClickedPage && selectedPage === pageNumber}
			/>
		);
	}
);
