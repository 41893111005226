import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { ArrowSync, DeclineDocument, Download, FloppyDisk } from '@sharefiledev/icons';
import { Button, Flex } from 'antd';
import { t } from '../../../util';

const actionStyles: React.CSSProperties = {
	textAlign: 'left',
	fontSize: '14px',
	marginLeft: `-${sizes.SM}`,
	padding: `0px ${sizes.SM}`,
};

export interface Props {
	onSave: () => Promise<any>;
	onDownload: () => void;
	onDecline: () => void;
	enableDownload: boolean;
	onSwitchExperience: () => void;
}

export const DocumentActionsDrawerContent: React.FC<Props> = (props: Props) => {
	return (
		<Flex vertical gap={4}>
			<Button
				icon={<FloppyDisk size={16} />}
				type="text"
				onClick={props.onSave}
				block
				style={actionStyles}
			>
				{t('esign-pilet-ui:signerPage.header.documentActions.saveProgress')}
			</Button>
			{props.enableDownload ? (
				<Button
					icon={<Download size={16} />}
					type="text"
					onClick={props.onDownload}
					block
					style={actionStyles}
				>
					{t('esign-pilet-ui:signerPage.header.documentActions.downloadOriginal')}
				</Button>
			) : (
				<></>
			)}
			<Button
				icon={<ArrowSync size={16} />}
				type="text"
				onClick={props.onSwitchExperience}
				block
				style={actionStyles}
			>
				{t('esign-pilet-ui:signerPage.header.documentActions.switchExperience')}
			</Button>
			<Button
				icon={<DeclineDocument size={16} />}
				type="text"
				onClick={props.onDecline}
				style={actionStyles}
				danger
			>
				{t('esign-pilet-ui:signerPage.header.documentActions.declineDocument')}
			</Button>
		</Flex>
	);
};
