import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { CollapseProps, Drawer, Grid, Typography } from 'antd';
import { useAppStore } from '../../Common/store';
import { t } from '../../util';
import { StyledCollapse } from './HelpAndFaqDrawer.styled';

const { useBreakpoint } = Grid;

const commonTextStyles: React.CSSProperties = {
	fontSize: sizes.base,
	lineHeight: sizes.MS,
	whiteSpace: 'pre-line',
};

const listStyles: React.CSSProperties = {
	paddingInlineStart: sizes.MS,
	marginBlockStart: sizes.MS,
	marginBlockEnd: 0,
};

const drawerBodyStyles: React.CSSProperties = {
	padding: `${sizes.XS} ${sizes.MS}`,
	scrollbarWidth: 'none',
};

interface HelpAndFaqDrawerProps {
	open: boolean;
	setIsOpen: (open: boolean) => void;
}

export const HelpAndFaqDrawer: React.FC<HelpAndFaqDrawerProps> = (
	props: HelpAndFaqDrawerProps
) => {
	const screens = useBreakpoint();
	const esignDocument = useAppStore(state => state.esignDocument);

	const closeDrawer = () => {
		props.setIsOpen(false);
	};

	const items: CollapseProps['items'] = [
		{
			key: '1',
			label: t('esign-pilet-ui:signerPage.helpAndFaq.haveQuestionsTitle'),
			children: (
				<Typography.Text style={commonTextStyles}>
					{t('esign-pilet-ui:signerPage.helpAndFaq.haveQuestionsContent', {
						senderName: esignDocument.sender_name,
						senderEmail: esignDocument.sender_email,
					})}
				</Typography.Text>
			),
		},
		{
			key: '2',
			label: t('esign-pilet-ui:signerPage.helpAndFaq.howToCompleteTitle'),
			children: (
				<>
					<Typography.Text style={commonTextStyles}>
						{t('esign-pilet-ui:signerPage.helpAndFaq.howToCompleteContentTitle')}
					</Typography.Text>
					<ul style={listStyles}>
						<li>
							<Typography.Text style={commonTextStyles}>
								{t('esign-pilet-ui:signerPage.helpAndFaq.howToCompleteFirst')}
							</Typography.Text>
						</li>
						<li>
							<Typography.Text style={commonTextStyles}>
								{t('esign-pilet-ui:signerPage.helpAndFaq.howToCompleteSecond')}
							</Typography.Text>
						</li>
						<li>
							<Typography.Text style={commonTextStyles}>
								{t('esign-pilet-ui:signerPage.helpAndFaq.howToCompleteThird')}
							</Typography.Text>
						</li>
						<li>
							<Typography.Text style={commonTextStyles}>
								{t('esign-pilet-ui:signerPage.helpAndFaq.howToCompleteFourth')}
							</Typography.Text>
						</li>
					</ul>
				</>
			),
		},
		{
			key: '3',
			label: t('esign-pilet-ui:signerPage.helpAndFaq.isLegallyTitle'),
			children: (
				<Typography.Text style={commonTextStyles}>
					{t('esign-pilet-ui:signerPage.helpAndFaq.isLegallyContent')}
				</Typography.Text>
			),
		},
		{
			key: '4',
			label: t('esign-pilet-ui:signerPage.helpAndFaq.whatIsShareFileTitle'),
			children: (
				<Typography.Text style={commonTextStyles}>
					{t('esign-pilet-ui:signerPage.helpAndFaq.whatIsShareFileContent')}
				</Typography.Text>
			),
		},
	];

	return (
		<>
			<Drawer
				title={t('esign-pilet-ui:signerPage.helpAndFaq.title')}
				onClose={closeDrawer}
				open={props.open}
				destroyOnClose={true}
				width={!screens.md ? '100vw' : 720}
				styles={{
					body: drawerBodyStyles,
					header: { borderBottom: 'unset' },
				}}
				data-testid="help-and-faq-drawer"
				id="help-and-faq-drawer"
			>
				<StyledCollapse items={items} bordered={false} />
			</Drawer>
		</>
	);
};
