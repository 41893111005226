import React from 'react';
import { colorPalette } from '@sharefiledev/antd-config';
import { Initials } from '@sharefiledev/icons';
import { Flex, theme, Typography } from 'antd';
import { ComponentValue } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { isComponentValid } from '../../util';
import { StyledInputWrapper, StyledPlaceholder } from '../Text/TextComponent.styled';
import { StyledInitialsComponentWrapper } from './InitialsComponent.styled';

const opacitySuffix = '1A';
const { useToken } = theme;

const componentStyles: React.CSSProperties = {
	border: '1px solid',
	padding: '4px 16px 4px 16px',
};

const validComponentStyles: React.CSSProperties = {
	border: '1px solid',
};

interface Props {
	componentValue: ComponentValue;
	textAlign: string;
	onChange: (value: string) => void;
	selected: boolean;
	scale: number;
}

export const InitialsComponent = (props: Props) => {
	const value = props.componentValue.value;
	const isValid = isComponentValid(value, ComponentTypes.InitialsComponent);

	const { token } = useToken();

	const borderColor = isValid ? colorPalette.green8 : colorPalette.lavender6;
	const backgroundColor = borderColor + opacitySuffix;
	const scale = props.scale;

	if (!props.selected) {
		return (
			<StyledInitialsComponentWrapper
				style={isValid ? validComponentStyles : componentStyles}
				textAlign={props.textAlign}
				borderColor={borderColor}
				backgroundColor={backgroundColor}
				token={token}
				data-testid={`InitialsComponent-${props.componentValue.component_id}-unselected`}
			>
				{value ? (
					<Typography.Text
						style={{
							fontSize: 14 * scale,
						}}
					>
						{value}
					</Typography.Text>
				) : (
					<Flex
						align="start"
						gap={8}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: props.textAlign,
							width: '100%',
						}}
					>
						<Initials size={24 * scale} />
						<StyledPlaceholder scale={props.scale}>
							{t('esign-pilet-ui:signerPage.content.fields.addInitials')}
						</StyledPlaceholder>
					</Flex>
				)}
			</StyledInitialsComponentWrapper>
		);
	}

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		props.onChange(e.target.value);
	};

	return (
		<StyledInputWrapper
			textAlign={props.textAlign}
			borderColor={borderColor}
			backgroundColor={backgroundColor}
			color={colorPalette.neutral13}
			token={token}
			autoFocus
			onChange={onChange}
			value={value}
			data-testid={`InitialsComponent-${props.componentValue.component_id}-selected`}
			scale={props.scale}
		/>
	);
};
