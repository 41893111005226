import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { Button, Grid, theme, Typography } from 'antd';
import { redirectToRS } from '../../../../Common/rsRedirection';
import { useAppStore } from '../../../../Common/store';
import { t } from '../../../../util';
import { StyledSwitchExperienceModal } from './SwitchExperienceModal.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

export interface Props {
	isModalOpen: boolean;
	onClose: () => void;
}

const SwitchExperienceModal: React.FC<Props> = (props: Props) => {
	const { token } = useToken();
	const screens = useBreakpoint();
	const [signer, signerSearchParams, isSignedInUserFlow] = useAppStore(store => [
		store.signer,
		store.searchParams,
		store.isSignedInUserFlow,
	]);

	const handleSwitchExperience = () => {
		if (isSignedInUserFlow) {
			redirectToRS(false, signerSearchParams, `/signers/${signer.id}/sign`);
		} else {
			redirectToRS(true);
		}
	};

	return (
		<>
			<StyledSwitchExperienceModal
				title={t('esign-pilet-ui:signerPage.modals.switchExperience.title')}
				open={props.isModalOpen}
				onCancel={props.onClose}
				token={token}
				centered={screens.sm}
				data-testid="switch-experience-modal"
				footer={[
					<Button
						key="switch"
						type="primary"
						onClick={handleSwitchExperience}
						data-testid="switch-experience-btn"
					>
						{t('esign-pilet-ui:signerPage.modals.switchExperience.switch')}
					</Button>,
				]}
			>
				<Typography.Text
					style={{ display: 'flex', textAlign: 'justify', paddingRight: sizes.MS }}
				>
					{t('esign-pilet-ui:signerPage.modals.switchExperience.content')}
				</Typography.Text>
			</StyledSwitchExperienceModal>
		</>
	);
};

export default SwitchExperienceModal;
