import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Block, BlockParams } from '@sharefiledev/solution-view-engine';
import { DashboardSignButton, DashboardSignButtonProps } from './DashboardSignButton';

export interface DashboardSignButtonBlockProps
	extends DashboardSignButtonProps,
		BlockParams {
	piletApi: PiletApi;
}

export const DashboardSignButtonBlock: React.FC<
	DashboardSignButtonBlockProps
> = props => {
	return (
		<Block piletApi={props.piletApi as any}>
			<DashboardSignButton {...props} />
		</Block>
	);
};
