import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { ChevronDown, Payment } from '@sharefiledev/icons';
import { Button, Dropdown, GlobalToken } from 'antd';

export const StyledPaymentComponentWrapper = styled.div<{
	textAlign: string;
	valid: boolean;
	multiline: boolean;
	token: GlobalToken;
}>`
	/* styles for multiline component */
	white-space: ${props => (props.multiline ? 'pre-line' : 'nowrap')};
	height: ${props => (props.multiline ? 'fit-content' : '100%')};
	word-break: ${props => (props.multiline ? 'break-word' : 'unset')};
	text-overflow: ${props => (props.multiline ? 'unset' : 'ellipsis')};

	@media (max-width: ${props => props.token.screenXL}px) {
		font-size: 14px;
		align-content: baseline;
		padding: ${sizes.XXS};

		.ant-space-gap-col-small {
			column-gap: ${sizes.XXS};
		}

		.ant-btn-icon {
			margin-inline-end: ${sizes.XXS};
		}
	}

	@media (max-width: ${props => props.token.screenMD}px) {
		font-size: ${sizes.SM};
		align-content: baseline;
		padding: 0px;

		.ant-space-gap-col-small {
			column-gap: 2px;
		}

		.ant-btn-icon {
			margin-inline-end: 2px;
		}
	}

	@media (max-width: ${props => props.token.screenSM}px) {
		font-size: ${sizes.XS};
		align-content: baseline;
		padding: 0px;

		.ant-space-gap-col-small {
			column-gap: 2px;
		}

		.ant-btn-icon {
			margin-inline-end: 2px;
		}
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS};
		align-content: baseline;
		padding: 0px;

		.ant-space-gap-col-small {
			column-gap: 1px;
		}

		.ant-btn-icon {
			margin-inline-end: 1px;
		}

		.ant-dropdown {
			font-size: ${sizes.XS} !important;
		}
	}

	overflow: hidden;
	display: flex;
	align-items: center;
	align-self: baseline;
	width: 100%;
	min-height: 100%;
	padding: ${props =>
		props.valid ? '0px' : `${sizes.XXS} ${sizes.XXS} ${sizes.XXS} ${sizes.XS}`};
	border-radius: ${sizes.XXS};
	font-size: ${sizes.base};

	border: 1px solid
		${props => (props.valid ? colorPalette.green8 : colorPalette.lavender6)} !important;
	background-color: ${props =>
		props.valid ? colorPalette.green8 + '1A' : colorPalette.lavender6 + '1A'} !important;
	text-align: ${props => props.textAlign};
	justify-content: ${props => props.textAlign};
	cursor: pointer;

	&:hover {
		cursor: pointer;
		border: 2px solid
			${props => (props.valid ? colorPalette.green8 : colorPalette.lavender6)} !important;
	}
`;

export const StyledDropDown = styled(Dropdown)<{
	token: GlobalToken;
}>`
	.ant-dropdown-menu-item {
		padding: 2px 0px !important;
		gap: 2px !important;
	}

	.amount-label {
		color: ${colorPalette.neutral7};
	}

	.ant-dropdown-menu-item-active {
		margin-inline-end: ${sizes.XXS} !important;
		gap: 2px !important;
	}

	@media (max-width: ${props => props.token.screenMD}px) {
		.ant-dropdown-menu {
			font-size: 14px;
		}
	}

	@media (max-width: ${props => props.token.screenSM}px) {
		.ant-dropdown-menu {
			font-size: ${sizes.SM};
		}
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS};

		.ant-dropdown-menu-item {
			padding: 2px 0px !important;
			gap: 2px;
		}

		.ant-dropdown-menu-item-icon {
			margin-inline-end: ${sizes.XXS} !important;
		}

		.amount-label {
			font-size: 7px;
		}

		.ant-dropdown-menu-title-content {
			font-size: ${sizes.XS} !important;
		}

		.ant-dropdown-menu,
		.ant-dropdown {
			font-size: ${sizes.XS} !important;
		}
	}
`;

export const StyledAmountDivWrapper = styled.div<{
	token: GlobalToken;
}>`
	color: ${colorPalette.neutral7};

	@media (min-width: ${props => props.token.screenMD}px) {
		font-size: ${sizes.SM} !important;
	}

	@media (max-width: ${props => props.token.screenSM}px) {
		font-size: 10px !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: 7px !important;
	}
`;

export const StyledDropDownButton = styled(Button)<{
	token: GlobalToken;
}>`
	color: ${colorPalette.neutral13};
	width: 100%;
	display: flex;
	align-items: center;
	padding: ${sizes.XXS} ${sizes.XXS};
	height: ${sizes.MD};
	align-items: center;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	font-size: 14px;

	&:active,
	&:hover {
		background: rgba(0, 0, 0, 0) !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		height: ${sizes.XXS};
	}
`;

export const StyledDropdownLabel = styled.label<{
	token: GlobalToken;
	pageWidth: number;
	componentWidth: number;
}>`
	white-space: nowrap;
	overflow: hidden;
	width: calc(${props => props.pageWidth * props.componentWidth}px - 66px);
	text-align: start;
	padding-left: 5px;
	cursor: pointer;

	@media (max-width: ${props => props.token.screenXS}px) {
		width: calc(${props => props.pageWidth * props.componentWidth}px - 35px);
		padding-left: 0px;
	}
`;

export const StyledChevronDown = styled(ChevronDown)<{
	token: GlobalToken;
	scale: number;
}>`
	position: absolute;
	right: 0;
	max-width: 25px;
	@media (max-width: ${props => props.token.screenMD}px) {
		font-size: ${sizes.base} !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: 10px !important;
		padding-right: 1px;
	}

	font-size: ${props => 24 * props.scale}px !important;
`;

export const StyledPayment = styled(Payment)<{
	token: GlobalToken;
	isMenuItem: boolean;
}>`
	padding: ${props => (props.isMenuItem ? '-4px' : '0px 4px')};
	font-size: 24px !important;

	@media (max-width: ${props => props.token.screenLG}px) {
		font-size: 19px !important;
		margin-inline-end: ${sizes.XXS} !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: 14px !important;
		padding: 0px 0px !important;
		margin-inline-end: ${sizes.XXS} !important;
	}
`;
