export const AWS_S3_BUCKET = {
	X_AMZ_SERVER_SIDE_ENCRYPTION: 'AES256',
	X_AMZ_ALGORITHM: 'AWS4-HMAC-SHA256',
	SUCCESS_ACTION_STATUS: 200,
};

export const EsignExtensionSlots = {
	SignFromDashboardButton: 'urn:sfextslot:esign:sign-from-dashboard-button',
};

export const SUPPORTED_FILE_EXTENSIONS = ['.doc', '.docx', '.txt', '.pdf'];
export const SUPPORTED_FILE_EXTENSIONS_BULK_SEND = ['.csv'];

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

export const PAGE_WIDTH_THRESHOLD = 10;

export const MAX_CSV_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const SignerPageTabIndexes = {
	StartButton: 100,
	KeyboardShortcut: 102,
	HelpSection: 103,
	MoreActionsButton: 104,
	SubmitButton: 105,
	FirstField: 200,
	ControlsStart: 300,
	PageSelector: 301,
	NextPageButton: 302,
	ZoomOut: 303,
	ZoomIn: 304,
	SidePanelStart: 400,
	FieldsTab: 401,
	DetailsTab: 402,
	SidePanelFields: 500,
};

export const SignatureDrawerIndexes = {
	TypeTab: 901,
	DrawTab: 902,
	UploadTab: 903,
	TypeInput: 921,
	TypeClearButton: 922,
	DrawCanvas: 925,
	DrawClearButton: 926,
	UploadFile: 927,
	UploadClearButton: 928,
	OkButton: 931,
	CancelButton: 932,
	CloseIcon: 941,
};

export const DOCGEN_ENTITLEMENT_CREATE_TEMPLATE =
	'sharefile:entitlementdef-create-doctemplate';

export const SEND_FOR_SIGNATURE_ENTITLEMENT =
	'sharefile:entitlementdef-send-for-signature';

export enum KBAStates {
	Identify = 'identify',
	Verify = 'verify',
}

export enum DateFormatTypes {
	MMDDYYYY = 'MM/DD/YYYY',
	//To Do: Include additional date formats and update the code to use these constants
}

export const TIMEZONES: { [key: string]: string } = {
	'-720': 'Etc/GMT+12',
	'-600': 'Pacific/Honolulu',
	'-540': 'America/Juneau',
	'-480': 'America/Los_Angeles',
	'-420': 'America/Phoenix',
	'-360': 'America/Chicago',
	'-300': 'America/New_York',
	'-270': 'America/Caracas',
	'-240': 'America/Halifax',
	'-210': 'America/St_Johns',
	'-180': 'America/Sao_Paulo',
	'-120': 'Atlantic/South_Georgia',
	'-60': 'Atlantic/Azores',
	'0': 'UTC',
	'60': 'Europe/Amsterdam',
	'120': 'Europe/Athens',
	'180': 'Asia/Baghdad',
	'240': 'Asia/Muscat',
	'270': 'Asia/Kabul',
	'300': 'Asia/Karachi',
	'330': 'Asia/Kolkata',
	'345': 'Asia/Kathmandu',
	'360': 'Asia/Dhaka',
	'390': 'Asia/Rangoon',
	'420': 'Asia/Bangkok',
	'480': 'Asia/Shanghai',
	'540': 'Asia/Tokyo',
	'570': 'Australia/Adelaide',
	'600': 'Australia/Brisbane',
	'660': 'Pacific/Guadalcanal',
	'720': 'Pacific/Auckland',
	'780': 'Pacific/Apia',
};
