const SAVE_SIGNATURE_EVENT = 'SAVE_SIGNATURE_EVENT';

const listenersMap = new Map<string, EventListener>(),
	on = (listener: EventListener) => {
		document.addEventListener(SAVE_SIGNATURE_EVENT, listener);
	},
	off = (listener: EventListener) => {
		document.removeEventListener(SAVE_SIGNATURE_EVENT, listener);
	};

export const SaveSignatureEvent = {
	registerHandler: (listenerKey: string, listener: EventListener) => {
		on(listener);
		listenersMap.set(listenerKey, listener);
	},
	unRegisterHandler: (listenerKey: string) => {
		off(listenersMap.get(listenerKey));
		listenersMap.delete(listenerKey);
	},
	dispatch: (data?: any) => {
		const event = new CustomEvent(SAVE_SIGNATURE_EVENT, { detail: data });
		document.dispatchEvent(event);
	},
	count: () => {
		return Object.keys(listenersMap).length;
	},
};
