import React from 'react';
import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { DatePicker, DatePickerProps, GlobalToken } from 'antd';

const getPadding = (textAlign: string) =>
	`0px ${textAlign === 'right' ? sizes.base : sizes.XXS} 0px ${sizes.XXS}`;

interface StyledDatePickerProps {
	textAlign: string;
	borderColor: string;
	token: GlobalToken;
	scale: number;
	inputReadOnly: boolean;
	width: number;
	value: any;
	height: number;
}

const MIN_ICON_SIZE = 8;
const MAX_ICON_SIZE = 12;

const clearIconSize = (height: number, width: number, scale: number) => {
	const baseSize = Math.min(height, width) * scale;
	return Math.max(MIN_ICON_SIZE, Math.min(baseSize, MAX_ICON_SIZE));
};

export const StyledDatePicker = styled(
	({ inputReadOnly, ...props }: StyledDatePickerProps) => (
		<DatePicker {...props} inputReadOnly={inputReadOnly} />
	)
)<StyledDatePickerProps>`
	width: 100%;
	height: 100%;

	@media (max-width: ${props => props.token.screenLGMax}px) {
		padding: ${props => getPadding(props.textAlign)} !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		padding: ${props => getPadding(props.textAlign)} !important;
	}

	background-color: ${colorPalette.neutral1};
	color: ${colorPalette.neutral13};
	padding: ${sizes.XXS} ${props => (props.textAlign === 'right' ? sizes.base : sizes.XXS)}
		${sizes.XXS} ${sizes.XS};
	border-radius: ${sizes.XXS};
	border: 2px solid ${props => props.borderColor};

	.ant-picker-input > input {
		text-align: ${props => props.textAlign};
		font-size: ${props => 14 * props.scale}px !important;
		max-width: ${props => (props.value ? 0.75 * props.width * props.scale + 'px' : null)};
	}

	&:hover,
	&:focus,
	&:focus-within {
		border-color: ${props => props.borderColor};
		box-shadow: unset;
	}
	.ant-picker-clear {
		left: ${props => (props.textAlign === 'right' ? 0 : 'unset')};
		width: fit-content;
		font-size: ${props => clearIconSize(props.height, props.width, props.scale)}px;
		@media screen and (max-width: 320px) {
			font-size: ${props => clearIconSize(props.height, props.width, props.scale) - 2}px;
		}
		opacity: 1;
	}

	.ant-picker-input > input::placeholder {
		color: ${colorPalette.neutral6};
	}
` as React.ComponentType<StyledDatePickerProps | DatePickerProps>;
