import * as React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { Button, Card, Grid, Row, Space, theme, Typography } from 'antd';
import { useAppStore } from '../../Common/store';
import { t } from '../../util';
import { LinkStyled, TermsModalStyled } from './TermsAndConditionsModal.styled';

const commonStyles: React.CSSProperties = {
	fontSize: sizes.base,
};

const buttonMobileWidth: React.CSSProperties = {
	width: '100%',
};

const buttonDeskWidth: React.CSSProperties = {
	width: 'unset',
};

const linkStyles: React.CSSProperties = {
	...commonStyles,
	padding: '0',
	height: 'unset',
};

const { useBreakpoint } = Grid;
const { useToken } = theme;

export function TermsAndConditionsModal() {
	const [isTermsandConditionsModalOpen, setIsModalOpen] = React.useState<boolean>(true);
	const screens = useBreakpoint();
	const { token } = useToken();
	const [signer, esignDocument] = useAppStore(store => [
		store.signer,
		store.esignDocument,
	]);

	const handleTermsandConditionsModalClose = () => {
		setIsModalOpen(false);
	};

	return (
		<TermsModalStyled
			open={isTermsandConditionsModalOpen}
			className="terms-conditions-modal"
			title={<SharefileTextLogoIcon width={140} />}
			token={token}
			centered={screens.sm}
			width={screens.xs ? '100%' : 640}
			closeIcon={null}
			style={commonStyles}
			footer={[
				<Button
					size="middle"
					shape="round"
					key="submit"
					type="primary"
					onClick={handleTermsandConditionsModalClose}
					style={screens.sm ? buttonMobileWidth : buttonDeskWidth}
					data-testid="modal-button-continue"
				>
					{t('esign-pilet-ui:signerPage.modals.termsAndConditions.continueButton')}
				</Button>,
			]}
		>
			<Space wrap direction="vertical">
				<Row style={{ marginTop: sizes.MS, marginBottom: sizes.MD }}>
					<Typography.Text
						style={{ paddingRight: sizes.XXS, ...commonStyles }}
						data-testid="modal-greetings"
					>
						{t('esign-pilet-ui:signerPage.modals.termsAndConditions.greetings', {
							signerName: signer.name,
						})}
					</Typography.Text>
					<Typography.Text style={commonStyles}>
						{t('esign-pilet-ui:signerPage.modals.termsAndConditions.text', {
							senderName: esignDocument.sender_name,
							senderEmail: esignDocument.sender_email,
							documentName: (
								<Typography.Text
									style={commonStyles}
									strong
								>{`${esignDocument.name}`}</Typography.Text>
							),
						})}
					</Typography.Text>
				</Row>
				{signer.message && (
					<Card
						style={{
							marginBottom: sizes.MD,
							backgroundColor: colorPalette.neutral2,
						}}
					>
						<Row>
							<Typography.Text
								strong
								style={{ paddingBottom: sizes.XS, ...commonStyles }}
							>
								{t(
									'esign-pilet-ui:signerPage.modals.termsAndConditions.senderMessageTitle'
								)}
							</Typography.Text>
						</Row>
						<Row style={commonStyles}>{signer.message}</Row>
					</Card>
				)}
				<Row
					style={{ marginBottom: sizes.SM, ...commonStyles }}
					className="terms-links-wrap"
				>
					{t('esign-pilet-ui:signerPage.modals.termsAndConditions.footerTitle')}
					<ul style={{ marginTop: sizes.XS }}>
						<li key="terms">
							<span>
								{t('esign-pilet-ui:signerPage.modals.termsAndConditions.footerTerms', {
									linkTermsOfUse: (
										<LinkStyled
											type="link"
											href="https://www.progress.com/legal"
											target="_blank"
											rel="noopener noreferrer"
											style={linkStyles}
										>
											{t(
												'esign-pilet-ui:signerPage.modals.termsAndConditions.termsOfUse'
											)}
										</LinkStyled>
									),
									linkPrivacyPolicy: (
										<LinkStyled
											type="link"
											href="https://www.progress.com/legal/privacy-center"
											target="_blank"
											rel="noopener noreferrer"
											style={linkStyles}
										>
											{t(
												'esign-pilet-ui:signerPage.modals.termsAndConditions.privacyPolicy'
											)}
										</LinkStyled>
									),
								})}
							</span>
						</li>
						<li key="consent">
							<span>
								{t(
									'esign-pilet-ui:signerPage.modals.termsAndConditions.footerEsignConsent',
									{
										link: (
											<LinkStyled
												type="link"
												href="https://www.sharefile.com/rightsignature/customer-disclosure"
												target="_blank"
												rel="noopener noreferrer"
												style={linkStyles}
											>
												{t(
													'esign-pilet-ui:signerPage.modals.termsAndConditions.esignConsent'
												)}
											</LinkStyled>
										),
									}
								)}
							</span>
						</li>
					</ul>
				</Row>
			</Space>
		</TermsModalStyled>
	);
}
