import styled from '@emotion/styled';
import { colorPalette } from '@sharefiledev/antd-config';
import { Select } from 'antd';

export const StyledSelectWrapper = styled(Select)`
	width: 100%;

	div > span {
		color: ${colorPalette.neutral13} !important;
	}
`;
