import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { Col, Flex, Row, Typography } from 'antd';
import { t } from '../../util';
import {
	ButtonInfoContainer,
	ShortcutsModalStyled,
	StyledCloseIcon,
} from './ShortcutsModal.styled';

const modalBodyStyles: React.CSSProperties = {
	overflow: 'auto',
	scrollbarWidth: 'none',
};

const commonTextStyles: React.CSSProperties = {
	fontSize: sizes.base,
};

export interface Props {
	isModalOpen: boolean;
	setIsModalOpen: (isModalOpen: boolean) => void;
}

export function ShortcutsModal(props: Props) {
	const shortcuts = React.useMemo(
		() => [
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.showHideShortcuts'),
				buttons: ['Shift', '?'],
				separator: '+',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.goToNextField'),
				buttons: ['Tab'],
				separator: '',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.goToPreviousField'),
				buttons: ['Shift', 'Tab'],
				separator: '+',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.interactWithField'),
				buttons: ['Space', 'Enter'],
				separator: t('esign-pilet-ui:signerPage.modals.shortcuts.or'),
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.openDropdownMenu'),
				buttons: ['Space', 'Enter'],
				separator: t('esign-pilet-ui:signerPage.modals.shortcuts.or'),
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.closeModal'),
				buttons: ['Esc'],
				separator: '',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.selectOption'),
				buttons: ['Enter'],
				separator: '',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.zoomIn'),
				buttons: ['Cmd/Ctrl', '+'],
				separator: '+',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.zoomOut'),
				buttons: ['Cmd/Ctrl', '-'],
				separator: '+',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.undo'),
				buttons: ['Cmd/Ctrl', 'z'],
				separator: '+',
			},
			{
				description: t('esign-pilet-ui:signerPage.modals.shortcuts.redo'),
				buttons: ['Cmd/Ctrl', 'Shift', 'z'],
				separator: '+',
			},
		],
		[]
	);

	return (
		<>
			<ShortcutsModalStyled
				open={props.isModalOpen}
				onCancel={() => props.setIsModalOpen(false)}
				title={t('esign-pilet-ui:signerPage.modals.shortcuts.title')}
				centered
				styles={{ body: modalBodyStyles }}
				width={687}
				footer={null}
				closeIcon={<StyledCloseIcon />}
			>
				<Flex vertical gap={24}>
					{shortcuts.map((shortcut, index) => (
						<Row align={'middle'} key={index} data-testid="shortcut">
							<Col span={12}>
								<Typography.Text style={commonTextStyles}>
									{shortcut.description}
								</Typography.Text>
							</Col>
							<Col span={12}>
								<Flex gap={8} align={'center'}>
									{shortcut.buttons.map((buttonText, index) => (
										<React.Fragment key={index}>
											{index > 0 && (
												<Typography.Text style={commonTextStyles}>
													{shortcut.separator}
												</Typography.Text>
											)}
											<ButtonInfoContainer>{buttonText}</ButtonInfoContainer>
										</React.Fragment>
									))}
								</Flex>
							</Col>
						</Row>
					))}
				</Flex>
			</ShortcutsModalStyled>
		</>
	);
}
