import React from 'react';
import { fromThemeColors, IconProps, ThemeColor } from '@sharefiledev/flow-web';
import { CheckBoxCheckedIcon } from '../../../icons';

interface ISignerCheckboxAnnotationComponentProps {
	size: number;
}

export const SignerCheckboxAnnotationComponent = ({
	size,
}: ISignerCheckboxAnnotationComponentProps) => {
	const Icon: React.FC<IconProps> = CheckBoxCheckedIcon;
	return (
		<Icon
			size={size}
			color={fromThemeColors(ThemeColor.primaryText)({ theme: {} })}
			data-testid="checkbox-icon"
		/>
	);
};
