import React, { useCallback, useEffect } from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { Grid } from 'antd';
import {
	PageMetaDataType,
	SignerOverlayerPage,
} from '../../Common/OverlayerPage/SignerOverlayerPage';
import { PDFViewer } from '../../Common/PDFViewer';
import { useAppStore } from '../../Common/store';
import { BaseFiles, ScrollToPageNumber } from '../../data/rsTypes';

const { useBreakpoint } = Grid;

type DocumentViewContainerProps = {
	baseFiles: BaseFiles;
};

export const DocumentViewContainer: React.FC<DocumentViewContainerProps> = props => {
	const screens = useBreakpoint();
	const esignDocument = useAppStore(store => store.esignDocument);
	const selectedComponentId = useAppStore(state => state.selectedComponentId);
	const signer = useAppStore(state => state.signer);
	const [scrollToPageNumber, setScrollToPageNumber] = React.useState<ScrollToPageNumber>({
		page: 1,
	});

	useEffect(() => {
		setScrollToPageNumber({
			page:
				esignDocument.components?.find(c => c.id === selectedComponentId)?.page ||
				scrollToPageNumber.page,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedComponentId, esignDocument]);

	const overlayFn = useCallback(
		(pageMetadata: PageMetaDataType) => {
			return (
				<SignerOverlayerPage
					components={
						esignDocument.components?.filter(c => c.page === pageMetadata.pageNumber) ||
						[]
					}
					scale={pageMetadata.scale}
					pageNumber={pageMetadata.pageNumber}
					signatureColor={esignDocument['signature_color']}
				/>
			);
		},
		[esignDocument]
	);

	return (
		<>
			<PDFViewer
				pageContainerStyle={{ top: screens.xs ? 'unset' : sizes.XXL }}
				pdf={props.baseFiles.processed_pdf_url}
				enablePagination={screens.lg}
				overlay={overlayFn}
				components={esignDocument?.components}
				signer={signer}
				scrollToPageNumber={scrollToPageNumber}
				canShowZoomInOutOptions={false}
			/>
		</>
	);
};
