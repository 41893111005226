import styled from '@emotion/styled';
import { Checkbox, GlobalToken } from 'antd';

export const StyledCheckbox = styled(Checkbox)<{
	color: string;
	selected: boolean;
	token: GlobalToken;
}>`
	width: 100%;
	height: 100%;

	span {
		width: 100% !important;
		height: 100% !important;
	}

	span > span.ant-checkbox-inner {
		border-color: ${props => props.color} !important;
		border-radius: 2px;
		background-color: transparent;
	}

	span.ant-checkbox-checked > span.ant-checkbox-inner {
		background-color: ${props => props.color} !important;

		@media (max-width: ${props => props.token.screenXS}px) {
			&:after {
				border-width: calc(${props => props.token.borderRadiusXS}px / 2) !important;
			}
		}

		&:after {
			width: 30% !important;
			height: 50% !important;
		}
	}

	span:hover > span.ant-checkbox-inner {
		border: 2px solid ${props => props.color} !important;
	}

	span > span.ant-checkbox-inner {
		border: ${props => (props.selected ? '2px' : '1px')} solid ${props => props.color} !important;
	}

	.ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
		outline: none !important;
	}
`;
