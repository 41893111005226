import React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { CheckCircle, ChevronDown } from '@sharefiledev/icons';
import { Drawer, Flex, Grid, Progress, theme, Typography } from 'antd';
import {
	groupComponents,
	isComponentValueValid,
} from '../../Common/ComponentsRenderer/util';
import { useAppStore } from '../../Common/store';
import { t } from '../../util';
import { StyledAlert } from './ProgressBarWrapper.styled';
import { ProgressTracker } from './ProgressTracker';

const { Text } = Typography;
const { useBreakpoint } = Grid;
const { useToken } = theme;

const wrapperStyles: React.CSSProperties = {
	height: sizes.XXL,
	backgroundColor: colorPalette.neutral1,
	borderBottom: `1px solid ${colorPalette.neutral3}`,
	padding: `0 ${sizes.base}`,
};

const buttonStyles: React.CSSProperties = {
	color: colorPalette.lavender6,
};

const progressStyles: React.CSSProperties = {
	width: '120px',
};

export const ProgressBarWrapper: React.FC = () => {
	const screens = useBreakpoint();
	const { token } = useToken();
	const [isMoreActionsDrawerVisible, setIsMoreActionsDrawerVisible] =
		React.useState(false);

	const [componentValues, signer, esignDocument, setSelectedComponentId] = useAppStore(
		state => [
			state.componentValues,
			state.signer,
			state.esignDocument,
			state.setSelectedComponentId,
		]
	);

	const showDrawer = () => {
		setIsMoreActionsDrawerVisible(true);
	};

	const onDrawerClose = () => {
		setIsMoreActionsDrawerVisible(false);
	};

	const onSelectComponentId = componentId => {
		onDrawerClose();
		// wait till onClose animation finish so focus on fields works properly
		setTimeout(() => setSelectedComponentId(componentId), 500);
	};

	const signerRequiredComponents = groupComponents(
		esignDocument.components.filter(
			comps => comps.assigned_to === signer.role_name && comps.is_required
		)
	);
	const validComponentValues = signerRequiredComponents.filter(comp =>
		isComponentValueValid(componentValues, comp)
	).length;

	const progressPrecent = (validComponentValues / signerRequiredComponents.length) * 100;
	return (
		<div
			style={{
				width: '100%',
			}}
		>
			{validComponentValues === signerRequiredComponents.length ? (
				<StyledAlert
					token={token}
					banner
					message={
						screens.sm
							? t('esign-pilet-ui:signerPage.content.progressBar.successText', {
									submitAction: (
										<Text strong>
											{t('esign-pilet-ui:signerPage.content.progressBar.submitAction')}
										</Text>
									),
							  })
							: t('esign-pilet-ui:signerPage.content.progressBar.shortSuccessText')
					}
					icon={
						<CheckCircle
							size={24}
							color={colorPalette.green8}
							data-testid="checkmark-icon"
						/>
					}
					type="success"
				/>
			) : (
				<>
					<Flex
						align="center"
						justify={screens.xs ? 'space-between' : 'center'}
						style={wrapperStyles}
						gap={40}
						onClick={!screens.lg ? showDrawer : null}
					>
						<Flex align="center" justify="center" gap={'small'}>
							<Text>
								{t('esign-pilet-ui:signerPage.content.progressBar.status', {
									fieldsFilled: validComponentValues,
									totalFields: signerRequiredComponents.length,
								})}
							</Text>
							<div style={progressStyles}>
								<Progress
									percent={progressPrecent}
									showInfo={false}
									style={{ marginBottom: '4px' } as React.CSSProperties}
									strokeColor={colorPalette.green8}
								/>
							</div>
						</Flex>
						{!screens.lg && (
							<>
								{screens.xs && <ChevronDown />}
								{screens.sm && (
									<>
										<Flex gap={8}>
											<Text underline style={buttonStyles}>
												{t(
													'esign-pilet-ui:signerPage.content.progressComponent.buttonLabel'
												)}
											</Text>
											<ChevronDown color={colorPalette.lavender6} size={16} />
										</Flex>
									</>
								)}
							</>
						)}
					</Flex>
					<Drawer
						title={t('esign-pilet-ui:signerPage.content.sidePanelTabs.label.fields')}
						onClose={onDrawerClose}
						open={isMoreActionsDrawerVisible}
						width={screens.xs ? '100vw' : '432px'}
					>
						<ProgressTracker onSelectComponentId={onSelectComponentId} />
					</Drawer>
				</>
			)}
		</div>
	);
};
