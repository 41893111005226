import styled from '@emotion/styled';
import { fromThemeColors, Text, ThemeColor } from '@sharefiledev/flow-web';

export const ZoomControlsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

export const StyledText = styled(Text)`
	width: 40px;
	text-align: center;
`;

export const StyledIconButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	background: ${fromThemeColors(ThemeColor.primaryContainerBackground)};
	border: 1px solid ${fromThemeColors(ThemeColor.border)};
`;
