import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Account, User, UserRole } from '@citrite/sf-api';
import dayjs from 'dayjs';
import {
	DocumentResponse,
	ExtendedRSDocument,
	RSTokenHeaderTypes,
} from '../data/rsTypes';
import { FeatureFlag } from '../FeatureFlags';

export const isAdminOrSender = (
	sfUser: User,
	sfAccount: Account,
	documentData: DocumentResponse
) => {
	return (
		isAdmin(sfUser, sfAccount, documentData) || isSender(sfUser, documentData.document)
	);
};

export const isAdmin = (
	sfUser: User,
	sfAccount: Account,
	documentData: DocumentResponse
) => {
	return (
		documentData.accounts[0].sharefile_account_id === sfAccount?.Id &&
		(sfUser.Roles.includes(UserRole.MasterAdmin) ||
			sfUser.Roles.includes(UserRole.CanManageSignatureTemplates))
	);
};

export const isShareFileAdmin = (sfUser: User) => {
	return (
		sfUser.Roles.includes(UserRole.MasterAdmin) ||
		sfUser.Roles.includes(UserRole.CanManageSignatureTemplates)
	);
};

export const isSender = (sfUser: User, document: ExtendedRSDocument) => {
	return sfUser?.Email?.toLowerCase() === document.sender_email?.toLowerCase();
};

export const getSignerToken = (searchParams: URLSearchParams) => {
	const identityToken = searchParams.get('identity_token');
	const accessToken = searchParams.get('access_token');

	if (identityToken) {
		return { token: identityToken, tokenType: RSTokenHeaderTypes.IdentityToken };
	}
	return { token: accessToken, tokenType: RSTokenHeaderTypes.AccessToken };
};

export const isUploadSignatureFeatureEnabled = account_creation_date => {
	const uploadSignatureAfterAccountCreationDate = dayjs(
		getFeatureFlagValue(FeatureFlag.EnableUploadSignatureAfterAccountCreationDate)
	);
	const accountCreationDate = dayjs(account_creation_date);

	return accountCreationDate.isAfter(uploadSignatureAfterAccountCreationDate);
};
