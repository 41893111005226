import styled from '@emotion/styled';
import { fromThemeColors, ThemeColor, typography } from '@sharefiledev/flow-web';

export const StyledMessage = styled.div`
	font-size: ${typography.sizes.base};
	color: ${fromThemeColors(ThemeColor.primaryText)};
	padding: 24px 0 8px;
`;

export const StyledSignatureCanvasWrapper = styled.div`
	border: 1px solid ${fromThemeColors(ThemeColor.metadataText)};
	display: flex;
	justify-content: center;
`;

export const StyledClearSignature = styled.a`
	position: absolute;
	right: 10px;
	border: 0;
	text-decoration: underline;
	color: ${fromThemeColors(ThemeColor.primary)};
	text-transform: capitalize;
	top: 60px;
	cursor: pointer;
`;
