// This storage is used to save signer object
import { StateCreator } from 'zustand';
import { DocumentSigner } from '../../data/rsTypes';
import { storeResetFns } from '.';

type State = {
	signer: DocumentSigner;
	searchParams: URLSearchParams;
	isSignedInUserFlow: boolean;
};

type Actions = {
	setSigner: (signer: DocumentSigner) => void;
	setSearchParams: (searchParams: URLSearchParams) => void;
	setIsSignedInUserFlow: (isSignedInUserFlow: boolean) => void;
};

export type SignerStore = State & Actions;

const initialState: State = {
	signer: undefined,
	searchParams: new URLSearchParams(),
	isSignedInUserFlow: false,
};

export const createSignerSlice: StateCreator<SignerStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setSigner(signer) {
			set(() => ({ signer: signer }));
		},
		setSearchParams(searchParams) {
			set(() => ({ searchParams: searchParams }));
		},
		setIsSignedInUserFlow(isSignedInUserFlow) {
			set(() => ({ isSignedInUserFlow: isSignedInUserFlow }));
		},
	};
};
