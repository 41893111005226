import styled from '@emotion/styled';
import { sizes } from '@sharefiledev/antd-config';
import { GlobalToken, Modal } from 'antd';

export const StyledAdvisoryModal = styled(Modal)<{
	token: GlobalToken;
}>`
	.ant-modal-content {
		@media (max-width: ${props => props.token.screenXS}px) {
			margin: ${sizes.base};
			width: fit-content !important;
		}
	}
`;
