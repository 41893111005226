import React, { useEffect, useMemo, useRef, useState } from 'react';
import { css, Global } from '@emotion/react';
import { sizes } from '@sharefiledev/antd-config';
import { FillAndSign } from '@sharefiledev/icons';
import { Dropdown, Flex, Grid, MenuProps, Spin, theme, Typography } from 'antd';
import { ESignatureClient } from '../../../../data/eSignatureClient';
import { Component, ComponentValue, Signature } from '../../../../data/rsTypes';
import { SignatureType } from '../../../../PreparePage/SignaturePad/constants';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { getSignerToken } from '../../../helper';
import { useImageLoaded } from '../../../Hooks/useImageLoaded';
import { useAppStore } from '../../../store';
import { isComponentValid } from '../../util';
import { SignatureDrawer } from './Modals/SignatureDrawer';
import {
	StyledOpenDrawerButton,
	StyledOpenDrawerDropdownButton,
} from './SignatureComponent.styled';

interface Props {
	component: Component;
	componentValue: ComponentValue;
	savedSignature: any;
	signatureColor: string;
	pageBoundingBox: DOMRect;
	onChange: (value: string) => void;
	selected: boolean;
	required: boolean;
	scale: number;
	componentVisibility: boolean;
}

const { useBreakpoint } = Grid;
const { useToken } = theme;

export const SignatureComponent = (props: Props) => {
	const { token } = useToken();
	const screens = useBreakpoint();
	const [signer, personalSettings, esignDocument, searchParams] = useAppStore(store => [
		store.signer,
		store.personalSettings,
		store.esignDocument,
		store.searchParams, // This will have identity_token, access_token and kba search params
	]);
	const [signature, setSignature] = useState<Signature | null>(null);
	const [isSignatureDrawerOpen, setSignatureDrawerOpen] = React.useState(false);
	const [isSigantureDropDownOpen, setIsSignatureDropDownOpen] = useState(false);
	const clearRef = useRef<HTMLAnchorElement>(null);
	const isValid = isComponentValid(
		props.componentValue.value,
		ComponentTypes.SignatureComponent
	);
	const [previousValue, setPreviousValue] = useState<Signature | null>(null);
	const openDrawerButtonRef = useRef<HTMLButtonElement>(null);
	const openDrawerDropdownRef = useRef<HTMLSpanElement>(null);
	const signerToken = useMemo(() => getSignerToken(searchParams), [searchParams]);
	const signatureDropdownRef = useRef<HTMLDivElement>(null);

	const imageLoaded = useImageLoaded(signature?.image_url);

	const { lastSavedSignature, setLastSavedSignature } = useAppStore(store => ({
		lastSavedSignature: store.lastSavedSignature,
		setLastSavedSignature: store.setLastSavedSignature,
	}));

	const openSignatureDrawer = (fromComponentEdit: boolean) => {
		if (fromComponentEdit || (!fromComponentEdit && !lastSavedSignature)) {
			setSignatureDrawerOpen(true);
		} else if (lastSavedSignature) {
			saveSignatureAutomatically();
		}
	};

	const saveSignatureAutomatically = async () => {
		await ESignatureClient.saveSignature(lastSavedSignature).then((res: any) => {
			saveSignature(res.signature);
		});
	};

	const saveSignature = savedSignature => {
		setSignature(null);
		setPreviousValue(savedSignature);
		setSignature(savedSignature);
		props.onChange(savedSignature.id);
	};

	useEffect(() => {
		if (!document.getElementById('Cedarville-Cursive')) {
			const link = document.createElement('link');
			link.type = 'text/css';
			link.href = '//fonts.googleapis.com/css?family=Cedarville+Cursive';
			link.rel = 'stylesheet';
			link.id = 'Cedarville-Cursive';
			document.head.appendChild(link);
		}

		if (props.componentValue.value) {
			ESignatureClient.getSignature(
				props.componentValue.value,
				esignDocument.id,
				signerToken,
				signer.id
			).then(res => {
				setPreviousValue(res.signature);
				setSignature(res.signature);
			});
		}
	}, [esignDocument.id, signerToken, props.componentValue.value, signer.id]);

	const clearSignature = () => {
		props.onChange(null);
		setSignature(null);
		setPreviousValue(null);
		setLastSavedSignature(null);
		clearRef.current?.click();
	};

	const editSignature = () => {
		openSignatureDrawer(true);
		setPreviousValue(signature);
	};

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<a
					className={`edit-signature-button-${props.component.id}`}
					onClick={editSignature}
				>
					{t(
						'esign-pilet-ui:signerPage.content.fields.signature.editSignatureButtonText'
					)}
				</a>
			),
		},
		{
			key: '2',
			label: (
				<a onClick={clearSignature}>
					{t(
						'esign-pilet-ui:signerPage.content.fields.signature.clearSignatureButtonText'
					)}
				</a>
			),
		},
	];

	useEffect(() => {
		const setDropdownPopupStyle = (displayState: string) => {
			const elem = signatureDropdownRef.current?.querySelector('.signature-dropdown');
			if (elem) {
				(elem as HTMLElement).style.display = displayState;
			}
		};

		const handleKeyDown = event => {
			if (props.selected) {
				const editSignatureButton = document.querySelectorAll(
					`.edit-signature-button-${props.component.id}`
				)[0];
				if (
					(event.key === 'ArrowUp' || event.key === 'ArrowDown') &&
					editSignatureButton?.checkVisibility()
				) {
					(editSignatureButton.parentElement.parentElement as HTMLElement).focus();
				} else if (event.code === 'Space' && isSignatureDrawerOpen) {
					if (editSignatureButton?.checkVisibility()) {
						event.preventDefault();
					}
				} else if (event.key === 'Enter' || event.code === 'Space') {
					if (editSignatureButton?.checkVisibility()) {
						event.preventDefault();
						(event.target.firstElementChild.firstElementChild as HTMLElement).click();
					} else if (
						props.selected &&
						isValid &&
						!document.getElementById('signature-drawer')?.checkVisibility()
					) {
						event.preventDefault();
						setDropdownPopupStyle('block');
						openDrawerDropdownRef.current?.click();
						(
							document.querySelectorAll(`.edit-signature-button-${props.component.id}`)[0]
								.parentElement.parentElement as HTMLElement
						).focus();
					} else if (document.getElementById('signature-drawer')?.checkVisibility()) {
						if (!isValid) {
							setTimeout(() => {
								openDrawerButtonRef.current.focus();
							}, 0);
						}
					}
				} else if (!document.getElementById('signature-drawer')?.checkVisibility()) {
					setDropdownPopupStyle('none');
				} else {
					setDropdownPopupStyle('block');
				}
			}
		};
		if (props.selected) {
			isValid
				? openDrawerDropdownRef.current?.focus()
				: openDrawerButtonRef.current?.focus();
		}
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isValid, props.component.id, props.selected, isSignatureDrawerOpen]);

	useEffect(() => {
		if (!props.componentVisibility) {
			setIsSignatureDropDownOpen(false);
		}
	}, [props.componentVisibility]);

	return (
		<>
			<Global
				styles={css`
					.open-drawer-dropdown-button .ant-spin-spinning span {
						font-size: ${props.scale * 20} 'px !important';
					}
				`}
			/>
			{!isValid ? (
				<StyledOpenDrawerButton
					ref={openDrawerButtonRef}
					token={token}
					onClick={() => openSignatureDrawer(false)}
					icon={<FillAndSign size={props.scale * 24} />}
					title={t('esign-pilet-ui:addSignature')}
					data-testid="open-drawer-button"
				>
					<Typography.Text
						style={{
							maxWidth: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							fontSize: props.scale * 18,
						}}
					>
						{t(
							'esign-pilet-ui:signerPage.content.fields.signature.openSignatureModalText'
						)}
					</Typography.Text>
				</StyledOpenDrawerButton>
			) : (
				<Flex ref={signatureDropdownRef} style={{ width: '100%', height: '100%' }}>
					<Dropdown
						destroyPopupOnHide={true}
						trigger={['click']}
						menu={{ items }}
						placement="bottomLeft"
						overlayClassName="signature-dropdown"
						open={isSigantureDropDownOpen}
						onOpenChange={setIsSignatureDropDownOpen}
					>
						<StyledOpenDrawerDropdownButton
							ref={openDrawerDropdownRef}
							token={token}
							isSelected={props.selected}
							className="open-drawer-dropdown-button"
						>
							{imageLoaded && signature ? (
								<img
									style={
										signature.signature_type === SignatureType.DRAWN ||
										signature.signature_type === SignatureType.UPLOADED
											? { maxWidth: '100%', maxHeight: '100%' }
											: screens.md
											? { marginTop: sizes.XXS, maxWidth: '100%' }
											: { marginTop: '2px', maxWidth: '100%' }
									}
									src={signature.image_url}
								/>
							) : (
								<Spin size="small" style={{ transform: `scale(${props.scale})` }} />
							)}
						</StyledOpenDrawerDropdownButton>
					</Dropdown>
				</Flex>
			)}
			{isSignatureDrawerOpen && (
				<SignatureDrawer
					signer={signer}
					component={props.component}
					signatureColor={props.signatureColor}
					pageBoundingBox={props.pageBoundingBox}
					personalSettings={personalSettings}
					clearRef={clearRef}
					previousValue={previousValue}
					isSignatureDrawerOpened={isSignatureDrawerOpen}
					setSignatureDrawerOpen={setSignatureDrawerOpen}
					saveSignature={saveSignature}
					setPreviousValue={setPreviousValue}
				/>
			)}
		</>
	);
};
