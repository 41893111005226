import React, { useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { CheckCircle } from '@sharefiledev/icons';
import { Button, Col, Grid, Row, Space, theme, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { DocumentStates } from '../../data/rsTypes';
import { DownloadModal } from '../../PreviewPage/Download/DownloadModal';
import { previewPagePath } from '../../routes';
import { t } from '../../util';
import { StyledModal } from './SuccessModal.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const modalBodyStyles: React.CSSProperties = {
	overflow: 'auto',
	scrollbarWidth: 'none',
	padding: `${sizes.XXS}`,
};

const headerStyles: React.CSSProperties = {
	fontWeight: 600,
	lineHeight: '28px',
	fontSize: '20px',
	textAlign: 'center',
};

const commonStyles: React.CSSProperties = {
	justifyContent: 'center',
	display: 'flex',
};

const textStyles: React.CSSProperties = {
	fontSize: sizes.base,
	textAlign: 'center',
	fontWeight: 400,
	lineHeight: sizes.MS,
};

const maskStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.green5} , ${colorPalette.lavender4}, ${colorPalette.pink3})`,
};

export interface Props {
	signerEmail: string;
	documentState: DocumentStates;
	documentId: string;
	token: string;
}

export function SuccessModal(props: Props) {
	const { token } = useToken();
	const screens = useBreakpoint();
	const history = useHistory();
	const isExecuted = props.documentState === DocumentStates.Executed;
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

	const openReviewPage = () => {
		history.replace(
			`${previewPagePath.replace(':documentId', props.documentId)}?redirectToken=${
				props.token
			}`
		);
	};

	return (
		<>
			<StyledModal
				open={true}
				closeIcon={null}
				centered={screens.sm}
				styles={{ body: modalBodyStyles, mask: maskStyle }}
				width={576}
				footer={null}
				token={token}
			>
				<Space wrap direction="vertical" size={12} style={{ width: '100%' }}>
					<Row style={{ ...commonStyles, marginBottom: sizes.MD }}>
						<SharefileTextLogoIcon width={140} />
					</Row>

					<Row style={{ ...commonStyles, marginBottom: sizes.XXS }}>
						<CheckCircle size={44} color={colorPalette.green8} />
					</Row>

					<Row style={commonStyles}>
						<Typography.Text style={headerStyles}>
							{t('esign-pilet-ui:signerPage.modals.success.title')}
						</Typography.Text>
					</Row>

					<Row style={{ ...commonStyles, marginBottom: sizes.SM }}>
						<Typography.Text style={textStyles}>
							{isExecuted
								? t('esign-pilet-ui:signerPage.modals.success.allSignedText', {
										email: (
											<Typography.Text strong style={textStyles}>
												{props.signerEmail}.
											</Typography.Text>
										),
								  })
								: t('esign-pilet-ui:signerPage.modals.success.notAllSignedText', {
										email: (
											<Typography.Text strong style={textStyles}>
												{props.signerEmail}
											</Typography.Text>
										),
								  })}
						</Typography.Text>
					</Row>

					<Row style={commonStyles} gutter={[8, 8]}>
						{isExecuted ? (
							<>
								<Col sm={12} xs={24}>
									<Button
										type="primary"
										style={{ width: '100%' }}
										onClick={openReviewPage}
										data-testid="review-button"
									>
										{t('esign-pilet-ui:signerPage.modals.success.reviewButton')}
									</Button>
								</Col>
								<Col sm={12} xs={24}>
									<Button
										style={{ width: '100%' }}
										data-testid="download-button"
										onClick={() => setIsDownloadModalOpen(true)}
									>
										{t('esign-pilet-ui:signerPage.modals.success.downloadButton')}
									</Button>
								</Col>
							</>
						) : (
							<Col span={24}>
								<Button
									type="primary"
									style={{ width: '100%' }}
									onClick={openReviewPage}
									data-testid="review-button"
								>
									{t('esign-pilet-ui:signerPage.modals.success.reviewButton')}
								</Button>
							</Col>
						)}
					</Row>
				</Space>
			</StyledModal>

			{isExecuted && (
				<DownloadModal
					isModalOpen={isDownloadModalOpen}
					setIsModalOpen={setIsDownloadModalOpen}
					documentId={props.documentId}
					redirectToken={props.token}
				/>
			)}
		</>
	);
}
