import React from 'react';
import styled from '@emotion/styled';
import { theme } from 'antd';
import { AsteriskIcon } from '../../icons';

export interface MandatoryFieldIndicatorProps {
	displayOutside: boolean;
	scale: number;
	textAligned?: boolean;
}

const { useToken } = theme;

export const MandatoryFieldIndicator = ({
	displayOutside,
	scale,
	textAligned,
}: MandatoryFieldIndicatorProps) => {
	const { token } = useToken();

	return (
		<Wrapper {...{ scale, textAligned, size: token.sizeMS }}>
			<StyledAsteriskIcon size={8} {...{ displayOutside }} data-testid="asterisk-icon" />
		</Wrapper>
	);
};

const Wrapper = styled.span<{ scale: number; textAligned: boolean; size: number }>`
	transform: ${({ scale }) => `scale(${scale})`};
	position: ${({ textAligned }) => (textAligned ? 'relative' : 'absolute')};
	margin-left: ${({ textAligned, size }) => (textAligned ? `${size}px` : '0px')};
	right: ${({ textAligned }) => (textAligned ? '6px' : 0)};
	top: ${({ textAligned }) => (textAligned ? '-2px' : 0)};
`;

const StyledAsteriskIcon = styled(AsteriskIcon)<{ displayOutside: boolean }>(
	({ displayOutside }) => ({
		position: 'absolute',
		top: displayOutside ? '-5px' : '4px',
		right: displayOutside ? '-9px' : '4px',
	})
);
