import React, { useRef, useState } from 'react';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { CalendarMark } from '@sharefiledev/icons';
import { Flex, Grid, theme, Typography } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { ComponentValue } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { isComponentValid } from '../../util';
import {
	StyledPlaceholder,
	StyledTextComponentWrapper,
} from '../Text/TextComponent.styled';
import { StyledDatePicker } from './DateComponent.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const opacitySuffix = '1A';

const componentStyles: React.CSSProperties = {
	border: '1px solid',
};

const selectedComponentStyles: React.CSSProperties = {
	border: '2px solid',
};

const mobileDatePickerStyles: React.CSSProperties = {
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	background: 'rgba(0, 0, 0, 0.45)',
	justifyContent: 'center',
	alignItems: 'end',
	paddingBottom: sizes.MS,
};

interface Props {
	componentValue: ComponentValue;
	textAlign: string;
	onChange: (value: string) => void;
	selected: boolean;
	dateFormat: string;
	isDateSigned: boolean;
	scale: number;
	componentVisibility: boolean;
	width: number;
	height: number;
}

export const DateComponent = (props: Props) => {
	// Extend dayjs with various plugins to support advanced formatting, custom parsing, localized data, and additional date calculations
	dayjs.extend(advancedFormat);
	dayjs.extend(customParseFormat);
	dayjs.extend(localeData);
	dayjs.extend(weekday);
	dayjs.extend(weekOfYear);
	dayjs.extend(weekYear);

	const [value, setValue] = useState<string>(props.componentValue.value);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [calendarViewDate, setCalendarViewDate] = useState<dayjs.Dayjs | null>(
		props.componentValue.value
			? dayjs(props.componentValue.value, props.dateFormat)
			: null
	);
	const datePickerRef = useRef(null);
	const isValid = isComponentValid(value, ComponentTypes.DateComponent);

	const borderColor =
		isValid || props.isDateSigned ? colorPalette.green8 : colorPalette.lavender6;
	const backgroundColor = borderColor + opacitySuffix;

	const { token } = useToken();
	const screens = useBreakpoint();
	const scale = props.scale;

	React.useEffect(() => {
		if (props.isDateSigned) {
			const today = dayjs(Date.now()).format(props.dateFormat);
			props.onChange(today);
			setValue(today);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (isOpen && !props.componentVisibility) {
			setIsOpen(false);
		}
	}, [isOpen, props.componentVisibility]);

	if (!props.selected || props.isDateSigned) {
		return (
			<StyledTextComponentWrapper
				style={
					props.isDateSigned
						? props.selected
							? selectedComponentStyles
							: null
						: componentStyles
				}
				textAlign={props.textAlign}
				borderColor={borderColor}
				backgroundColor={backgroundColor}
				token={token}
				multiline={false}
				isReadOnly={props.isDateSigned}
				isSelected={props.selected}
				data-testid={`DateComponent-${props.componentValue.component_id}-unselected`}
			>
				{value ? (
					<Typography.Text
						style={{
							fontSize: 14 * scale,
						}}
					>
						{value}
					</Typography.Text>
				) : (
					<Flex
						align="start"
						gap={8}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: props.textAlign,
							width: '100%',
						}}
					>
						<CalendarMark size={24 * scale} />
						<StyledPlaceholder scale={scale}>
							{t('esign-pilet-ui:signerPage.content.fields.selectDate')}
						</StyledPlaceholder>
					</Flex>
				)}
			</StyledTextComponentWrapper>
		);
	}

	const onChange = (_date, dateString: string) => {
		props.onChange(dateString);
		setValue(dateString);
		if (!dateString) {
			setCalendarViewDate(null);
		} else {
			setCalendarViewDate(dayjs(dateString, props.dateFormat));
		}
		datePickerRef.current?.focus();
	};

	const onOpenChange = (open: boolean) => {
		datePickerRef.current?.focus();
		setIsOpen(open && props.componentVisibility);
	};
	const datePickerMaskForLargeScreens =
		screens.xl || screens.lg
			? { format: props.dateFormat, type: 'mask' as const }
			: { format: props.dateFormat };
	return (
		<>
			<Global
				styles={css`
					.signer-date-picker-${props.componentValue.component_id} {
						display: ${isOpen ? 'flex' : 'none'};

						.ant-picker-date-panel-container,
						.ant-picker-year-panel-container,
						.ant-picker-month-panel-container,
						.ant-picker-decade-panel-container {
							margin-right: unset !important;
						}
					}
				`}
			/>
			<StyledDatePicker
				onChange={onChange}
				onOpenChange={onOpenChange}
				inputReadOnly={!(screens.xl || screens.lg)}
				open={isOpen}
				value={calendarViewDate}
				format={datePickerMaskForLargeScreens}
				textAlign={props.textAlign}
				borderColor={borderColor}
				suffixIcon={null}
				placeholder={props.dateFormat}
				disabled={props.isDateSigned}
				allowClear={true}
				token={token}
				ref={datePickerRef}
				autoFocus={props.selected}
				scale={scale}
				data-testid={`DateComponent-${props.componentValue.component_id}-selected`}
				popupClassName={
					screens.sm ? null : `signer-date-picker-${props.componentValue.component_id}`
				}
				popupStyle={screens.sm ? null : mobileDatePickerStyles}
				width={props.width}
				height={props.height}
			/>
		</>
	);
};
