import { IconProps } from '@sharefiledev/flow-web';
import { IRecipient } from '../../Contexts/DocumentContext';

export enum ComponentTypes {
	SignatureComponent = 'SignatureComponent',
	TextComponent = 'TextComponent',
	DateComponent = 'DateComponent',
	CheckComponent = 'CheckComponent',
	CheckGroupComponent = 'CheckGroupComponent',
	InitialsComponent = 'InitialsComponent',
	SelectComponent = 'SelectComponent',
	AttachmentComponent = 'AttachmentComponent',
	CheckmarkAnnotationComponent = 'CheckmarkAnnotationComponent',
	SignatureAnnotationComponent = 'SignatureAnnotationComponent',
	TextAnnotationComponent = 'TextAnnotationComponent',
	PaymentComponent = 'PaymentComponent',
}

export interface Component {
	id: string;
	type: ComponentTypes;
	x: number;
	y: number;
	width: number;
	height: number;
	page: number;
	name: string;
	assignedTo: IRecipient;
	isRequired: boolean;
	groupRequirement?: string;
	groupId?: string;
	fontSize?: number;
	multiline?: boolean;
	transparent?: boolean;
	value?: string;
	textAlign?: string;
	selectOptions?: string[];
	helpText?: string;
	chargeAfterExecuted?: boolean;
	paymentAmount?: number;
	isMergeField?: boolean;
	dateFormat?: string;
	isDateSigned?: boolean;
	isSourceMapped?: boolean;
	documentTemplateId: string;
	documentTemplateType: string;
	signatureId?: string;
	isAnnotation: boolean;
	content?: React.ReactNode;
	showErrorOnResizable?: boolean;
	sequence?: number;
}

export interface Field {
	type: string;
	label: string;
	icon: React.FC<IconProps>;
}

export interface IPersonalSettings {
	canManageUsers: boolean;
	attachPdfOnCompletion: boolean;
	allowDrawnSignature: boolean;
	allowTypedSignature: boolean;
	allowUploadedSignature: boolean;
	reminderFrequency: string;
}

export interface IAccountSettings {
	isHipaaAccount: boolean;
	signatureColorBlueDefault: boolean;
	enableSignerDownload: boolean;
}
