import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { SignatureDrawerIndexes } from '../../../constants';
import { t } from '../../../util';
import { canvasDimesions, SignatureType } from '../constants';
import ESignatureCanvas from '../ESignatureCanvas';
import { SaveSignatureEvent } from '../SaveSignatureEvent';
import {
	StyledClearSignature,
	StyledMessage,
	StyledSignatureCanvasWrapper,
} from './ESignaturePad.styled';

export const ESignaturePad = ({
	saveSignature,
	penColor,
	isSignatureDrawn,
	setIsSignatureDrawn,
	tabOpened = SignatureType.DRAWN,
	clearRef = undefined,
	isSignerFlow = false,
	previousValue = undefined,
	setPreviousValue = undefined,
	pageBoundingBox = undefined,
}) => {
	const canvasRef = useRef(null);

	const clearSignature = () => {
		canvasRef.current.clear();
		setIsSignatureDrawn(false);
	};

	const onSignEnd = () => {
		setIsSignatureDrawn(true);
	};

	const [canvasWidth] = useState(
		pageBoundingBox?.width < canvasDimesions.width
			? pageBoundingBox?.width
			: canvasDimesions.width
	);

	useEffect(() => {
		const canvasRefValue = canvasRef.current;

		if (
			isSignerFlow &&
			canvasRefValue &&
			previousValue?.signature_type === SignatureType.DRAWN
		) {
			canvasRefValue.fromData(JSON.parse(previousValue.json_data));
			setPreviousValue(null);
		}

		if (isSignerFlow && tabOpened === SignatureType.DRAWN) {
			canvasRefValue?.isEmpty() ? setIsSignatureDrawn(false) : setIsSignatureDrawn(true);
		}

		const onSaveEventHandler = () => {
			if (
				canvasRefValue &&
				(!isSignerFlow || (isSignerFlow && tabOpened === SignatureType.DRAWN))
			) {
				const signatureDataUrl = canvasRefValue.toDataURL('image/png'),
					data = canvasRefValue.toData();

				if (data.length > 0) {
					saveSignature(signatureDataUrl, JSON.stringify(data), null);
				}
			}
		};

		const key = 'ESignaturePad';
		SaveSignatureEvent.registerHandler(key, onSaveEventHandler);
		return () => {
			SaveSignatureEvent.unRegisterHandler(key);
		};
	}, [
		canvasRef,
		isSignerFlow,
		previousValue,
		saveSignature,
		setIsSignatureDrawn,
		setPreviousValue,
		tabOpened,
	]);

	return (
		<div className="signature-pad-wrapper">
			<StyledMessage>{t('esign-pilet-ui:signatureAnnotationMessage')}</StyledMessage>
			<div className="signature-draw-canvas-wrapper">
				<StyledSignatureCanvasWrapper className="signature-draw-wrapper">
					<ESignatureCanvas
						ref={canvasRef}
						canvasProps={{ ...{ width: canvasWidth, height: canvasDimesions.height } }}
						onEnd={onSignEnd}
						data-testid="signature-canvas-pad"
						penColor={penColor}
						minWidth={2}
					/>
				</StyledSignatureCanvasWrapper>
				{isSignatureDrawn &&
					(isSignerFlow ? (
						<Button
							type="link"
							onClick={clearSignature}
							ref={clearRef}
							id="signature-draw-clear-link"
							data-testid="signature-draw-clear-link"
							className="signature-pad-clear-link"
							tabIndex={SignatureDrawerIndexes.DrawClearButton}
							style={{
								position: 'relative',
								top: '-200px',
								float: 'right',
								marginRight: '10px',
							}}
						>
							{t('esign-pilet-ui:clear')}
						</Button>
					) : (
						<StyledClearSignature
							onClick={clearSignature}
							ref={clearRef}
							data-testid="signature-draw-clear-link"
						>
							{t('esign-pilet-ui:clear')}
						</StyledClearSignature>
					))}
			</div>
		</div>
	);
};
