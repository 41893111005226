import React, { useEffect, useState } from 'react';
import { LoadingRingIcon } from '@sharefiledev/flow-web';
import { useImageLoaded } from '../../../../Hooks/useImageLoaded';
import {
	SignerSignatureAnnotationImage,
	SignerSignatureAnnotationImageOverlayer,
} from './SignerSignatureAnnotationComponent.styled';

interface SignatureAnnotationProps {
	savedSignature: any;
	signatureColor: string;
}

const SignerSignatureAnnotation = ({ savedSignature }: SignatureAnnotationProps) => {
	const [imageSrc, setImageSrc] = useState('');
	const imageLoaded = useImageLoaded(imageSrc);

	useEffect(() => {
		if (savedSignature) {
			setImageSrc(savedSignature.imageUrl);
		}
	}, [savedSignature]);

	return imageSrc ? (
		<>
			<SignerSignatureAnnotationImageOverlayer />
			{imageLoaded ? (
				<SignerSignatureAnnotationImage
					data-testid="signature-annotation-image"
					src={imageSrc}
					signatureColorBlueDefault={
						savedSignature.color !== 'black' &&
						savedSignature.signatureType !== 'uploaded'
					}
				/>
			) : (
				<LoadingRingIcon size={18} data-testid="signature-image-loading-ring" />
			)}
		</>
	) : (
		<></>
	);
};

export default SignerSignatureAnnotation;
