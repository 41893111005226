export const KBA_KEY = `esign-${process.env.NODE_ENV.substring(0, 1)}-kba`;

export const setCookie = (name: string, value: string, expirationDate: Date): void => {
	document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
		value
	)}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const getCookie = (name: string): string | null => {
	const cookies = document.cookie.split(';').map(cookie => cookie.trim());
	for (const cookie of cookies) {
		const [cookieName, cookieValue] = cookie.split('=');
		if (decodeURIComponent(cookieName) === name) {
			return decodeURIComponent(cookieValue);
		}
	}
	return null;
};

export const deleteCookie = (name: string): void => {
	document.cookie = `${encodeURIComponent(
		name
	)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};
