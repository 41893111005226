import dayjs from 'dayjs';
import { Component, ComponentValue, DocumentSigner } from '../../data/rsTypes';
import { ComponentTypes } from '../Components/types';

export function isComponentValid(componentValue: string, componentType: ComponentTypes) {
	if (
		componentType === ComponentTypes.TextComponent ||
		componentType === ComponentTypes.AttachmentComponent ||
		componentType === ComponentTypes.SelectComponent ||
		componentType === ComponentTypes.InitialsComponent ||
		componentType === ComponentTypes.DateComponent ||
		componentType === ComponentTypes.SignatureComponent
	) {
		return !!componentValue?.length;
	}
	if (
		componentType === ComponentTypes.CheckComponent ||
		componentType === ComponentTypes.CheckGroupComponent
	) {
		return componentValue === 'true';
	}
	if (componentType === ComponentTypes.PaymentComponent) {
		return !!componentValue?.length;
	} else {
		// Unitil other components are implemented
		return false;
	}
}

export function isComponentValueValid(
	componentValues: Map<string, ComponentValue>,
	component: Component
) {
	let isValid = false;
	if (component.type === ComponentTypes.CheckGroupComponent) {
		for (const child of component.group_items) {
			const result = isComponentValid(
				componentValues.get(child.id).value,
				component.type as ComponentTypes
			);
			if (result) {
				isValid = true;
				break;
			}
		}
	} else {
		isValid = isComponentValid(
			componentValues.get(component.id).value,
			component.type as ComponentTypes
		);
	}
	return isValid;
}

export function groupComponents(components: Component[]): Component[] {
	const groupItems = sortComponentsByCoordinates(
		components.filter(value => value.type === ComponentTypes.CheckGroupComponent)
	);

	const groups = [] as Component[];
	groupItems.forEach(component => {
		const rootElement = groups.find(value => value.group_id === component.group_id);
		if (rootElement) {
			if (rootElement.group_items) {
				rootElement.group_items.push(component);
			} else {
				rootElement.group_items = [component];
			}
		} else {
			const group = { ...component, group_items: [component] };
			groups.push(group);
		}
	});
	const standaloneComponents = components.filter(
		value => value.type !== ComponentTypes.CheckGroupComponent
	);
	const grouppedComponents = [].concat(standaloneComponents, groups);
	return sortComponentsByCoordinates(grouppedComponents);
}

const BORDER_BOX_REF = { top: 10000, left: 10000, right: -10000, bottom: -10000 };
const BORDER_MARGIN = 1; // in pixels

export function updateCheckboxGroupBorderBox(
	checkboxGroupComps: Component[],
	borderBoxReference: React.MutableRefObject<any>,
	component: Component,
	pageBoundingBox: DOMRect,
	scale: number
) {
	let box = { ...BORDER_BOX_REF };

	checkboxGroupComps.forEach(groupComponent => {
		box.top = Math.min(box.top, groupComponent.y);
		box.left = Math.min(box.left, groupComponent.x);
		box.right = Math.max(box.right, groupComponent.x + groupComponent.width);
		box.bottom = Math.max(box.bottom, groupComponent.y + groupComponent.height);
	});

	const { groupTop, groupLeft, groupWidth, groupHeight } = getBorderBoxDimensions(
		box,
		component,
		pageBoundingBox,
		scale
	);
	borderBoxReference.current.style.top = `${groupTop}px`;
	borderBoxReference.current.style.left = `${groupLeft}px`;
	borderBoxReference.current.style.width = `${groupWidth}px`;
	borderBoxReference.current.style.height = `${groupHeight}px`;
}

// The method recursively reduces the font size until the text completely fits on the page
export function updateOverlappingFontSize(
	textBoxEl: HTMLDivElement,
	pageBoundingBox: DOMRect,
	originalFontSize: number,
	fontSizeSub: number
) {
	if (fontSizeSub <= 1) {
		textBoxEl.style.fontSize = `${originalFontSize}px`;
	}

	const rects = textBoxEl.getBoundingClientRect();

	const rightOverlap = rects.right - pageBoundingBox.right;
	const leftOverlap = rects.left - pageBoundingBox.left;

	if (rightOverlap > 0 || leftOverlap < 0) {
		const newFontSize =
			originalFontSize - fontSizeSub >= 8 ? originalFontSize - fontSizeSub : 8;

		if (newFontSize === 8) {
			return;
		}

		textBoxEl.style.fontSize = `${newFontSize}px`;
	} else {
		return;
	}

	updateOverlappingFontSize(
		textBoxEl,
		pageBoundingBox,
		originalFontSize,
		fontSizeSub + 1
	);
}

// TODO: remove the code when the real eIDAS sender configuration will be ready
export function getEidasAuthorizationFlag(): boolean {
	return !!window.sessionStorage.getItem('enable_eidas');
}

export async function getBase64EncodedPdf(url: string): Promise<string> {
	if (!url) {
		return Promise.reject();
	}

	return new Promise((resolve, _) => {
		fetch(url)
			.then(response => response.blob())
			.then(blob => {
				var reader = new FileReader();
				reader.onloadend = () => {
					const hashedString = reader.result as string;
					resolve(hashedString.replace('data:binary/octet-stream;base64,', ''));
				};
				reader.readAsDataURL(blob);
			});
	});
}

function getBorderBoxDimensions(
	selectedCheckBoxGroupBorder,
	component: Component,
	pageBoundingBox: DOMRect,
	scale: number
) {
	return {
		groupTop:
			(selectedCheckBoxGroupBorder.top - component.y) * pageBoundingBox.height -
			BORDER_MARGIN * scale,
		groupLeft:
			(selectedCheckBoxGroupBorder.left - component.x) * pageBoundingBox.width -
			BORDER_MARGIN * scale,
		groupWidth:
			(selectedCheckBoxGroupBorder.right - selectedCheckBoxGroupBorder.left) *
				pageBoundingBox.width +
			2 * BORDER_MARGIN * scale,
		groupHeight:
			(selectedCheckBoxGroupBorder.bottom - selectedCheckBoxGroupBorder.top) *
				pageBoundingBox.height +
			2 * BORDER_MARGIN * scale,
	};
}

export function formatComponentValues(
	components: Component[],
	signer: DocumentSigner,
	componentValues: ComponentValue[]
): ComponentValue[] {
	const componentsAssignedToSigner = components.filter(
		component => component.assigned_to === signer.role_name
	);
	const componentMap = new Map(
		componentsAssignedToSigner.map(component => [component.id, component])
	);
	return componentValues
		.filter(componentValue => componentMap.has(componentValue.component_id))
		.map(componentValue => {
			const component = componentMap.get(componentValue.component_id);

			if (component.type === ComponentTypes.DateComponent && componentValue.value) {
				componentValue.value = dayjs(componentValue.value, component.date_format).format(
					'ddd MMM D YYYY'
				);
			}
			return componentValue;
		});
}

export function isVisible(element: Element, boundingRect: DOMRect): boolean {
	if (element) {
		const elementBoudingRect = element.getBoundingClientRect();

		return (
			elementBoudingRect.top >= boundingRect.top &&
			elementBoudingRect.bottom <= boundingRect.bottom &&
			elementBoudingRect.left >= boundingRect.left &&
			elementBoudingRect.right <= boundingRect.right
		);
	}

	return false;
}

// sort component logically (by page, y, x; checkbox group components should be one by one)
export function sortComponents(components: Component[]): Component[] {
	const sortedComponents = sortComponentsByCoordinates(groupComponents(components));
	let flattenedComponents: Component[] = [];
	sortedComponents.forEach(component => {
		flattenedComponents.push(component);
		if (component.group_items?.length) {
			flattenedComponents = flattenedComponents.concat(component.group_items.slice(1));
		}
	});

	return flattenedComponents;
}

function sortComponentsByCoordinates(components: Component[]): Component[] {
	return components.sort((a, b) => {
		if (a.page === b.page) {
			if (a.y === b.y) {
				return a.x - b.x;
			}
			return a.y - b.y;
		}
		return a.page - b.page;
	});
}
