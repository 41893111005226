import React, { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Component } from '../../data/rsTypes';
import { FlattenedComponentsRenderer } from '../ComponentsRenderer/FlattenedComponentsRenderer';
import { SignerComponentsRenderer } from '../ComponentsRenderer/SignerComponentsRenderer';
import { useAppStore } from '../store';
import { StyledOverlayerPage } from './OverLayerPage.styled';

interface Props {
	components: Component[];
	scale: number;
	pageNumber: number;
	signatureColor: string;
}

export type PageMetaDataType = {
	pageNumber: number;
	scale: number;
	pageHeight: number;
	pageWidth: number;
};

export const SignerOverlayerPage = (props: Props) => {
	const pageRef = useRef<HTMLDivElement>(null);
	const [pageBoundingClientRect, setPageBoundingClientRect] =
		React.useState<DOMRect>(null);
	const [selectedComponentId, setSelectedComponentId] = useAppStore(state => [
		state.selectedComponentId,
		state.setSelectedComponentId,
	]);
	const [signer, esignDocument, signers] = useAppStore(store => [
		store.signer,
		store.esignDocument,
		store.signers,
	]);

	// The setTimeout with a delay of 0 is used to defer the execution of setPageBoundingClientRect
	// until the browser has finished with the current call stack and rendering tasks.
	const updatePageBoundingRect = React.useCallback(() => {
		setTimeout(() => {
			const viewerContainer = document.querySelector('.viewer-container');
			const viewerContainerWrapper = document.querySelector('.viewer-container-wrapper');

			if (!viewerContainer || !viewerContainerWrapper) {
				setPageBoundingClientRect(pageRef.current?.getBoundingClientRect());
				return;
			}

			const transform = getComputedStyle(viewerContainer)?.transform;
			if (!transform || transform === 'none') {
				setPageBoundingClientRect(pageRef.current?.getBoundingClientRect());
				return;
			}

			const matrix = transform.match(/matrix(?:3d)?\((.+)\)/);
			if (!matrix) {
				setPageBoundingClientRect(pageRef.current?.getBoundingClientRect());
				return;
			}

			const values = matrix[1].split(', ').map(parseFloat);
			const scaleX = values[0];

			if (scaleX === 1) {
				setPageBoundingClientRect(pageRef.current?.getBoundingClientRect());
			} else {
				const pageBoundingRect = pageRef.current?.getBoundingClientRect();
				let viewerContainerWrapperBoundingRect =
					viewerContainerWrapper.getBoundingClientRect();
				viewerContainerWrapperBoundingRect.width = pageBoundingRect.width / scaleX;
				viewerContainerWrapperBoundingRect.height = pageBoundingRect.height / scaleX;
				setPageBoundingClientRect(viewerContainerWrapperBoundingRect);
			}
		}, 0);
	}, []);

	const debouncedUpdatePageBoundingRect = useDebouncedCallback(
		updatePageBoundingRect,
		300
	);

	React.useEffect(() => {
		const resizeObserver = new ResizeObserver(_ => {
			debouncedUpdatePageBoundingRect();
		});

		updatePageBoundingRect();

		if (pageRef.current) {
			resizeObserver.observe(pageRef.current);
		}

		// Since the ref value can change we need to remember it, before we cleanup.
		const pageRefCopy = pageRef.current;
		return () => {
			if (pageRefCopy) {
				resizeObserver.unobserve(pageRefCopy);
			}
		};
	}, [debouncedUpdatePageBoundingRect, updatePageBoundingRect]);

	const componentsToFill = props.components.filter(component => {
		return (
			component.assigned_to === signer.role_name &&
			!component.type.includes('Annotation') &&
			!component.type.includes('isMergeField')
		);
	});

	const flattenedComponents = props.components.filter(component => {
		const componentSigner = signers?.find(
			signer => signer.role_name === component.assigned_to
		);
		return (
			component.type.includes('Annotation') ||
			component.type.includes('isMergeField') ||
			(component.assigned_to !== signer.role_name &&
				esignDocument.signer_sequencing &&
				componentSigner?.status === 'signed')
		);
	});

	return (
		<StyledOverlayerPage
			ref={pageRef}
			id={`overlayer-page-${props.pageNumber}`}
			data-testid={`overlayer-page-${props.pageNumber}`}
			onClick={() => setSelectedComponentId(null)}
		>
			{pageBoundingClientRect && (
				<>
					<SignerComponentsRenderer
						components={componentsToFill}
						pageBoundingBox={pageBoundingClientRect}
						selectedComponentId={selectedComponentId}
						setSelectedComponentId={setSelectedComponentId}
						signatureColor={props.signatureColor}
					/>
					<FlattenedComponentsRenderer
						components={flattenedComponents}
						pageBoundingBox={pageBoundingClientRect}
						signatureColor={props.signatureColor}
					/>
				</>
			)}
		</StyledOverlayerPage>
	);
};
