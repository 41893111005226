import React, { useState } from 'react';
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight } from '@sharefiledev/icons';
import { Flex, Grid, theme } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { SignerPageTabIndexes } from '../../constants';
import { Component, DocumentSigner } from '../../data/rsTypes';
import { t } from '../../util';
import { isVisible, sortComponents } from '../ComponentsRenderer/util';
import { useAppStore } from '../store';
import {
	StyledCaretRightOutlined,
	StyledNavigationButton,
	StyledNavigationWrapper,
	StyledRoundNavigationButton,
} from './Navigation.styled';
const { useBreakpoint } = Grid;
const { useToken } = theme;

interface NavigationProps {
	components: Component[];
	signer: DocumentSigner;
	loaded: boolean;
	resize: boolean;
}

const MINIMUM_LEFT_OFFSET = 16; // in pixels
const MINIMUM_TOP_BOTTOM_OFFSET = 24;
const MIN_COMPONENT_HEIGHT = 15; // minimum component height to properly center the navigation triangle
const MAX_COMPONENT_HEIGHT = 30; // maximum component height to properly center the navigation triangle
const DOCUMENT_LEFT_OFFSET = 20; // document left offset
const MIN_DOCUMENT_LEFT_OFFSET = 4; // document left offset

export const Navigation: React.FC<NavigationProps> = props => {
	const signerComponents = sortComponents(
		props.components.filter(comps => comps.assigned_to === props.signer.role_name)
	);

	const [startedSigning, setStartedSigning] = React.useState(false);
	const [pageBoundingClientRect, setPageBoundingClientRect] =
		React.useState<DOMRect>(null);
	const [documentViewerRef, setDocumentViewerRef] = React.useState<Element>(null);
	const [leftRightOffset, setLeftRightOffset] = React.useState<[number, number]>([
		null,
		null,
	]);
	const [selectedComponentId, componentValues, setSelectedComponentId] = useAppStore(
		state => [
			state.selectedComponentId,
			state.componentValues,
			state.setSelectedComponentId,
		]
	);
	const [selectedComponentIndex, setSelectedComponentIndex] = React.useState(-1);

	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const triangleRef = React.useRef<HTMLSpanElement>(null);
	const screens = useBreakpoint();
	const { token } = useToken();

	const [elementIndex, setElementIndex] = useState(-1);
	const [isFieldsTabOpened, setIsFieldsTabOpened] = useState(false);
	const [isFieldsTabVisited, setIsFieldsTabVisited] = useState(false);

	React.useEffect(() => {
		if (screens.sm) {
			const getDocumentElement = (tabIndex: string) => {
				return document.getElementById(
					document.querySelectorAll("[tabindex='" + tabIndex + "']")[0].getAttribute('id')
				);
			};

			const isButtonDisabled = (tabIndex: string) => {
				return (
					document.querySelector("[tabindex='" + tabIndex + "']") as HTMLButtonElement
				).disabled;
			};

			const setIndexAndFocusOnElement = (index: number, isChild = false) => {
				setElementIndex(tabIndexes.indexOf(index.toString()));
				if (isChild) {
					(getDocumentElement(index.toString()).firstElementChild as HTMLElement).focus();
				} else {
					const element = getDocumentElement(index.toString());
					// Added timeout to focus on the element after the closing animation of the dropdown
					setTimeout(() => {
						element.focus();
					}, 1);
				}
			};

			const isAnyModalOpen = () => {
				return !!document.querySelector('.ant-modal-mask');
			};

			const firstField = document.querySelector('[id^="SignerComponent"]');

			if (firstField) {
				firstField.setAttribute('tabIndex', SignerPageTabIndexes.FirstField.toString());

				if (document.getElementsByClassName('ant-pagination')[0]) {
					document
						.querySelector('.ant-pagination-prev .ant-pagination-item-link')
						.setAttribute('id', 'previous-page');
					document
						.querySelector('.ant-pagination-simple-pager')
						.firstElementChild.setAttribute('id', 'page-selector');
					document
						.querySelector('.ant-pagination-next .ant-pagination-item-link')
						.setAttribute('id', 'next-page');

					document
						.querySelector('.ant-pagination-prev .ant-pagination-item-link')
						.setAttribute('tabIndex', SignerPageTabIndexes.ControlsStart.toString());
				}
			}

			const elements = document.querySelectorAll('[tabindex]');
			const tabIndexes = Array.from(elements)
				.filter(el => +el.getAttribute('tabindex') >= SignerPageTabIndexes.StartButton)
				.map(el => el.getAttribute('tabindex'))
				.sort();

			const getNextElementByNavigation = (
				isShiftKey: boolean,
				isFieldsTabVisited: boolean
			): number => {
				const navigationCase = `${isShiftKey}-${isFieldsTabVisited}`;

				switch (navigationCase) {
					case 'true-true':
						return SignerPageTabIndexes.FieldsTab;
					case 'true-false':
						return SignerPageTabIndexes.ControlsStart;
					case 'false-true':
						return SignerPageTabIndexes.StartButton;
					case 'false-false':
						return SignerPageTabIndexes.DetailsTab;
					default:
						return SignerPageTabIndexes.DetailsTab;
				}
			};

			const handleKeyDown = event => {
				if (
					!document
						.getElementsByClassName('terms-conditions-modal')[0]
						.checkVisibility() &&
					!isAnyModalOpen() &&
					!document.getElementById('signature-drawer')?.checkVisibility() &&
					!document.getElementById('help-and-faq-drawer')?.checkVisibility()
				) {
					if (
						(isFieldsTabOpened || isFieldsTabVisited) &&
						document.querySelector('.ant-tabs-tab-active').getAttribute('tabindex') !==
							SignerPageTabIndexes.DetailsTab.toString()
					) {
						if (event.key === 'ArrowDown') {
							event.preventDefault();
							const nextItemIndex =
								(selectedComponentIndex + 1) % signerComponents.length;
							setSelectedComponentId(signerComponents[nextItemIndex].id);
						}
						if (event.key === 'ArrowUp') {
							event.preventDefault();
							const nextItemIndex = selectedComponentIndex - 1;
							setSelectedComponentId(signerComponents[nextItemIndex].id);
						}
					}

					if (event.key === 'Tab') {
						event.preventDefault();
						let nextElementIndex = event.shiftKey
							? elementIndex - 1 >= 0
								? elementIndex - 1
								: tabIndexes.length - 1
							: elementIndex + 1 < tabIndexes.length
							? elementIndex + 1
							: 0;

						nextElementIndex = selectedComponentId ? elementIndex : nextElementIndex;

						if (
							+tabIndexes[nextElementIndex] === SignerPageTabIndexes.ControlsStart &&
							!event.shiftKey &&
							selectedComponentId
						) {
							const nextElement = event.shiftKey
								? nextElementIndex
								: nextElementIndex - 1;
							nextElementIndex = nextElement;
							setElementIndex(nextElement);
						}

						if (
							(isFieldsTabOpened || isFieldsTabVisited) &&
							document.querySelector('.ant-tabs-tab-active').getAttribute('tabindex') !==
								SignerPageTabIndexes.DetailsTab.toString()
						) {
							let nextElement = getNextElementByNavigation(
								event.shiftKey,
								isFieldsTabVisited
							).toString();
							if (
								(event.shiftKey && isFieldsTabOpened) ||
								(isFieldsTabVisited &&
									nextElement !== SignerPageTabIndexes.FieldsTab.toString())
							) {
								getDocumentElement(nextElement).focus();
							} else if (isFieldsTabVisited) {
								nextElement = SignerPageTabIndexes.DetailsTab.toString();
								(
									getDocumentElement(SignerPageTabIndexes.DetailsTab.toString())
										.firstElementChild as HTMLElement
								).focus();
							} else {
								(
									getDocumentElement(nextElement).firstElementChild as HTMLElement
								).focus();
							}
							setSelectedComponentId(null);
							setSelectedComponentIndex(-1);
							setIsFieldsTabOpened(false);
							setIsFieldsTabVisited(false);
							setElementIndex(tabIndexes.indexOf(nextElement));
							return;
						}

						// When we select the field element
						if (
							(+tabIndexes[nextElementIndex] >= SignerPageTabIndexes.FirstField &&
								+tabIndexes[nextElementIndex] < SignerPageTabIndexes.ControlsStart) ||
							selectedComponentId
						) {
							if (event.shiftKey) {
								const previousItemIndex = selectedComponentIndex - 1;
								if (+tabIndexes[elementIndex] === SignerPageTabIndexes.ControlsStart) {
									setSelectedComponentId(signerComponents[0].id);
									setIndexAndFocusOnElement(SignerPageTabIndexes.FirstField);
								} else {
									if (previousItemIndex < 0) {
										setSelectedComponentIndex(-1);
										setSelectedComponentId(null);
										setIndexAndFocusOnElement(SignerPageTabIndexes.SubmitButton);
									} else {
										setSelectedComponentIndex(previousItemIndex);
										setSelectedComponentId(signerComponents[previousItemIndex].id);
									}
								}
							} else {
								const nextItemIndex = selectedComponentIndex + 1;
								if (nextItemIndex > signerComponents.length - 1) {
									setSelectedComponentId(null);
									setSelectedComponentIndex(-1);
									setIndexAndFocusOnElement(SignerPageTabIndexes.ControlsStart);
								} else {
									if (+tabIndexes[elementIndex] < SignerPageTabIndexes.FirstField) {
										setSelectedComponentId(signerComponents[nextItemIndex].id);
										getDocumentElement(tabIndexes[elementIndex].toString()).blur();
									} else {
										setSelectedComponentId(signerComponents[nextItemIndex].id);
									}
								}
							}
						} else if (
							+tabIndexes[nextElementIndex] === SignerPageTabIndexes.ControlsStart
						) {
							if (event.shiftKey) {
								if (isButtonDisabled(tabIndexes[nextElementIndex])) {
									if (
										+tabIndexes[nextElementIndex] === SignerPageTabIndexes.ControlsStart
									) {
										setSelectedComponentId(signerComponents[0].id);
										getDocumentElement(tabIndexes[elementIndex].toString()).blur();
									} else {
										setIndexAndFocusOnElement(+tabIndexes[--nextElementIndex]);
									}
								} else {
									setIndexAndFocusOnElement(+tabIndexes[nextElementIndex]);
								}
							} else {
								if (isButtonDisabled(tabIndexes[nextElementIndex])) {
									setIndexAndFocusOnElement(+tabIndexes[++nextElementIndex]);
								} else {
									setIndexAndFocusOnElement(+tabIndexes[nextElementIndex]);
								}
							}
						} else if (
							+tabIndexes[elementIndex] === SignerPageTabIndexes.DetailsTab &&
							!event.shiftKey &&
							document.querySelector('.ant-tabs-tab-active').getAttribute('tabindex') !==
								SignerPageTabIndexes.DetailsTab.toString()
						) {
							setIsFieldsTabVisited(true);
							const nextItemIndex =
								(selectedComponentIndex + 1) % signerComponents.length;
							setSelectedComponentId(signerComponents[nextItemIndex].id);
						} else if (
							+tabIndexes[nextElementIndex] >= SignerPageTabIndexes.SidePanelStart
						) {
							if (+tabIndexes[nextElementIndex] === SignerPageTabIndexes.FieldsTab) {
								setSelectedComponentIndex(-1);
								setIsFieldsTabOpened(true);
							} else {
								setIsFieldsTabOpened(false);
							}

							setIndexAndFocusOnElement(+tabIndexes[nextElementIndex], true);
						} else {
							setIndexAndFocusOnElement(+tabIndexes[nextElementIndex]);
						}
					}
				}
			};

			document.addEventListener('keydown', handleKeyDown);
			return () => document.removeEventListener('keydown', handleKeyDown);
		}
	}, [
		componentValues,
		elementIndex,
		isFieldsTabVisited,
		isFieldsTabOpened,
		screens.sm,
		selectedComponentId,
		selectedComponentIndex,
		setSelectedComponentId,
		signerComponents,
	]);

	React.useEffect(() => {
		function updatePosition() {
			const component = document.querySelector(`#SignerComponent-${selectedComponentId}`);
			const ref = screens.sm ? wrapperRef : triangleRef;

			if (ref.current && component) {
				// center tracker element on the field center
				const offset = ref.current.clientHeight / 2;
				const componentDomRect = component.getBoundingClientRect();
				let top =
					componentDomRect.top -
					pageBoundingClientRect.top +
					componentDomRect.height / 2 -
					offset;

				top = Math.max(top, MINIMUM_TOP_BOTTOM_OFFSET);

				top = Math.min(
					top,
					pageBoundingClientRect.height -
						MINIMUM_TOP_BOTTOM_OFFSET -
						ref.current.clientHeight
				);

				if (screens.xs) {
					const documentViewer = document.querySelector('.viewer-container-wrapper');
					const documentViewerRef = documentViewer.getBoundingClientRect();
					const compCenter =
						componentDomRect.height < MIN_COMPONENT_HEIGHT
							? componentDomRect.height / 2 - MIN_COMPONENT_HEIGHT
							: componentDomRect.height > MAX_COMPONENT_HEIGHT
							? componentDomRect.height / 2
							: 0;

					const leftPosition =
						documentViewerRef.left >= DOCUMENT_LEFT_OFFSET
							? 0
							: documentViewerRef.left > 0
							? documentViewerRef.left / 2 - MIN_DOCUMENT_LEFT_OFFSET
							: Math.abs(documentViewerRef.left) + DOCUMENT_LEFT_OFFSET;

					const topPosition =
						compCenter +
						componentDomRect.top -
						documentViewerRef.top -
						componentDomRect.height / 2;

					ref.current.style.transform = `translate(${leftPosition}px, ${topPosition}px)`;
				} else {
					ref.current.style.transform = `translateY(${top}px)`;
				}
			}
		}

		function initialUpdatePosition() {
			const component = document.querySelector(`#SignerComponent-${selectedComponentId}`);

			if (isVisible(component, pageBoundingClientRect)) {
				setTimeout(() => {
					const ref = screens.sm ? wrapperRef : triangleRef;
					if (screens.xs) {
						triangleRef.current.style.visibility = 'visible';
					}

					ref.current.style.transition = 'transform 0.5s ease-out';
					updatePosition();

					documentViewerRef?.removeEventListener('scroll', initialUpdatePosition);

					// wait till animation finish and track scroll events
					setTimeout(() => {
						ref.current.style.transition = 'unset';
						documentViewerRef?.addEventListener('scroll', updatePosition);
					}, 500);
				}, 300);
			}
		}

		if (selectedComponentId) {
			// update active component index
			setStartedSigning(true);
			const componentIndex = signerComponents.findIndex(
				component => component.id === selectedComponentId
			);
			setSelectedComponentIndex(componentIndex);

			// wait till PDFViewer jumps to new page
			setTimeout(() => {
				if (
					isVisible(
						document.querySelector(`#SignerComponent-${selectedComponentId}`),
						pageBoundingClientRect
					)
				) {
					initialUpdatePosition();
				} else {
					// wait till the scroll animation end
					setTimeout(() => {
						updatePosition();
					}, 200);
					documentViewerRef?.addEventListener('scroll', initialUpdatePosition);
				}
			}, 50);
		} else {
			if (screens.xs) {
				if (triangleRef.current) {
					triangleRef.current.style.visibility = 'hidden';
				}
			}
		}
		return () => documentViewerRef?.removeEventListener('scroll', updatePosition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [screens.sm, selectedComponentId]);

	const onStartSigning = () => {
		setSelectedComponentId(signerComponents[0].id);
	};

	const onSelectNext = () => {
		const nextItemIndex = (selectedComponentIndex + 1) % signerComponents.length;
		setSelectedComponentId(signerComponents[nextItemIndex].id);
	};

	const onSelectPrevious = () => {
		const nextItemIndex = selectedComponentIndex - 1;
		setSelectedComponentId(signerComponents[nextItemIndex].id);
	};

	const updateLeftOffset = React.useCallback(() => {
		if (props.loaded) {
			const documentViewer = document.querySelector('.viewer-container-wrapper');
			const ref = screens.sm ? wrapperRef : triangleRef;
			if (documentViewer && ref.current) {
				const listBoundingClientRect = documentViewer.getBoundingClientRect();
				const isEnoughtSpace =
					listBoundingClientRect.left -
						ref.current.getBoundingClientRect().width +
						MINIMUM_LEFT_OFFSET >
					MINIMUM_LEFT_OFFSET;

				if (isEnoughtSpace) {
					setLeftRightOffset([null, listBoundingClientRect.right - MINIMUM_LEFT_OFFSET]);
				} else {
					setLeftRightOffset([MINIMUM_LEFT_OFFSET, null]);
				}
				setDocumentViewerRef(documentViewer);
				setPageBoundingClientRect(listBoundingClientRect);
			}
		}
	}, [props.loaded, screens.sm]);

	const debouncedUpdateLeftOffset = useDebouncedCallback(updateLeftOffset, 300);

	React.useEffect(() => {
		window.addEventListener('resize', debouncedUpdateLeftOffset);
		debouncedUpdateLeftOffset();
		return () => window.removeEventListener('resize', debouncedUpdateLeftOffset);
	}, [debouncedUpdateLeftOffset, updateLeftOffset]);

	const buttonText = !startedSigning
		? t('esign-pilet-ui:signerPage.content.navigation.startSigning')
		: screens.sm
		? t('esign-pilet-ui:signerPage.content.navigation.next')
		: t('esign-pilet-ui:signerPage.content.navigation.nextField');

	if (!signerComponents.length) {
		return <></>;
	}

	return (
		<>
			<StyledCaretRightOutlined
				token={token}
				ref={triangleRef}
				style={{ visibility: startedSigning && !screens.sm ? 'visible' : 'hidden' }}
			/>
			<StyledNavigationWrapper
				ref={wrapperRef}
				token={token}
				isNextButton={startedSigning}
				visible={pageBoundingClientRect !== null}
				leftRight={leftRightOffset}
			>
				<Flex>
					{startedSigning && selectedComponentIndex !== 0 && (
						<StyledRoundNavigationButton
							aria-label={t('esign-pilet-ui:signerPage.content.navigation.previousField')}
							size="middle"
							shape="circle"
							onClick={onSelectPrevious}
							data-testid="previous-button"
						>
							{screens.sm ? <ArrowUp size={10} /> : <ChevronLeft size={10} />}
						</StyledRoundNavigationButton>
					)}
					<StyledNavigationButton
						id="start-navigation-button"
						tabIndex={SignerPageTabIndexes.StartButton}
						size="middle"
						type="primary"
						shape="round"
						onClick={!startedSigning ? onStartSigning : onSelectNext}
						isNextButton={startedSigning}
						token={token}
					>
						{buttonText}
						{startedSigning ? (
							screens.sm ? (
								<ArrowDown size={10} />
							) : (
								<ChevronRight size={10} />
							)
						) : null}
					</StyledNavigationButton>
				</Flex>
			</StyledNavigationWrapper>
		</>
	);
};
