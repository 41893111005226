import React, { useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Button, theme, Typography } from 'antd';
import { t } from '../../../util';
import { StyledAdvisoryModal } from './KbaIdentityVerificationAdvisoryModal.styled';

const { useToken } = theme;

export const KbaIdentityVerificationAdvisoryModal = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { token } = useToken();

	const showModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	const advisoryTitleStyles: React.CSSProperties = {
		fontSize: sizes.base,
		color: colorPalette.green8,
		textDecoration: 'none',
	};

	return (
		<>
			<Button type="link" onClick={showModal} data-testid="advisory-modal-link">
				<Typography.Text style={advisoryTitleStyles}>
					{t('esign-pilet-ui:signerPage.modals.kba.identityVerificationAdvisory')}
				</Typography.Text>
			</Button>
			<StyledAdvisoryModal
				open={isModalVisible}
				token={token}
				footer={[
					<Button
						key="ok"
						type="primary"
						onClick={closeModal}
						data-testid="advisory-close-modal-button"
					>
						{t('esign-pilet-ui:signerPage.modals.kba.OK')}
					</Button>,
				]}
				closable={false}
				data-testid="advisory-modal"
			>
				<Typography.Text>
					{t(
						'esign-pilet-ui:signerPage.modals.kba.identityVerificationAdvisoryInformation'
					)}
				</Typography.Text>
			</StyledAdvisoryModal>
		</>
	);
};
