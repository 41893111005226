import React, { useRef } from 'react';
import { colorPalette } from '@sharefiledev/antd-config';
import { theme } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ComponentValue } from '../../../../data/rsTypes';
import { StyledCheckbox } from './CheckComponent.styled';

const { useToken } = theme;

interface Props {
	componentValue: ComponentValue;
	onChange: (value: string) => void;
	selected: boolean;
}

export const CheckComponent = (props: Props) => {
	const { token } = useToken();
	const ref = useRef(null);

	const [checkboxValue, setCheckboxValue] = React.useState<boolean>(
		props.componentValue.value === 'true'
	);

	const onCheckboxValueChange = (value: CheckboxChangeEvent) => {
		props.onChange(String(value.target.checked));
		setCheckboxValue(value.target.checked);
	};

	const onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			props.onChange(String(!checkboxValue));
			setCheckboxValue(prev => !prev);
		}
	};

	React.useEffect(() => {
		setCheckboxValue(props.componentValue.value === 'true');
	}, [props.componentValue.value]);

	React.useEffect(() => {
		if (props.selected) {
			ref.current?.focus();
		}
	}, [props.selected]);

	const checkboxColor = colorPalette.lavender6;

	return (
		<StyledCheckbox
			color={checkboxColor}
			token={token}
			selected={props.selected}
			checked={checkboxValue}
			onChange={onCheckboxValueChange}
			onKeyDown={onKeyDown}
			ref={ref}
			id={`CheckComponent-${props.componentValue.component_id}`}
		/>
	);
};
