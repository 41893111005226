import React, { memo, useRef } from 'react';
import { Tooltip } from 'antd';
import { Component, ComponentValue } from '../../../data/rsTypes';
import { t } from '../../../util';
import { defaultWidth } from '../../Components/componentUtils';
import { ComponentTypes } from '../../Components/types';
import { useAppStore } from '../../store';
import { MandatoryFieldIndicator } from '../MandatoryFieldIndicator';
import { isVisible } from '../util';
import { AttachmentComponent } from './Attachment/AttachmentComponent';
import { CheckComponent } from './Checkbox/CheckComponent';
import { CheckGroupComponent } from './Checkbox/CheckGroupComponent';
import { DateComponent } from './Date/DateComponent';
import { DropdownComponent } from './Dropdown/DropdownComponent';
import { InitialsComponent } from './Initials/InitialsComponent';
import { PaymentComponent } from './Payment/PaymentComponent';
import { SignatureComponent } from './Signature/SignatureComponent';
import { StyledComponentWrapper } from './SignerComponent.styled';
import { TextComponent } from './Text/TextComponent';

const tooltipStyle: React.CSSProperties = {
	borderRadius: '12px',
	background: '#3C4043',
	padding: '4px',
	fontSize: '14px',
	whiteSpace: 'pre',
};

const tooltipInnerStyle: React.CSSProperties = {
	fontSize: '14px',
	boxShadow: 'none',
	display: 'flex',
	alignItems: 'center',
	whiteSpace: 'normal',
};

const componentTypesWithHelpText: string[] = [
	ComponentTypes.PaymentComponent,
	ComponentTypes.AttachmentComponent,
];

interface Props {
	component: Component;
	components: Component[];
	componentValue: ComponentValue;
	updateComponentValue: (componentValue: ComponentValue) => void;
	pageBoundingBox: DOMRect;
	selected?: boolean;
	isAnnotation: boolean;
	setSelectedComponentId?: (id: string) => void;
	signatureColor: string;
}

export const SignerComponent = memo(function SignerComponent(props: Props) {
	const signatures = useAppStore(store => store.signatures);

	const [isSelected, setIsSelected] = React.useState(false);
	const [mouseOver, setMouseOver] = React.useState(false);
	const [componentVisibility, setComponentVisibility] = React.useState(false);
	const tooltipRef = useRef<any>(null);
	const zoomLevel = useRef(1);
	const [reposition, setReposition] = React.useState(false);

	const renderNode = () => {
		const updateComponentValue = (value: string) => {
			props.updateComponentValue({ ...props.componentValue, value });
		};
		const scaleValue = props.pageBoundingBox.width / defaultWidth;
		const scale = scaleValue >= 1 ? 1 : scaleValue;
		const filteredSignature = signatures?.find(
			signature => signature.id === props.component['signature_id']
		);

		switch (props.component.type) {
			case ComponentTypes.TextComponent:
				return (
					<TextComponent
						componentValue={props.componentValue}
						selected={isSelected}
						onChange={updateComponentValue}
						textAlign={props.component.text_align}
						multiline={props.component.multiline}
						defaultValue={props.component.value}
						pageBoundingBox={props.pageBoundingBox}
						scale={scale}
					/>
				);
			case ComponentTypes.AttachmentComponent:
				return (
					<AttachmentComponent
						component={props.component}
						componentValue={props.componentValue}
						required={props.component.is_required}
						onChange={updateComponentValue}
						selected={isSelected}
						pageBoundingBox={props.pageBoundingBox}
						scale={scale}
					/>
				);
			case ComponentTypes.CheckComponent:
				return (
					<CheckComponent
						componentValue={props.componentValue}
						onChange={updateComponentValue}
						selected={isSelected}
					/>
				);
			case ComponentTypes.CheckGroupComponent:
				return (
					<CheckGroupComponent
						componentValue={props.componentValue}
						onChange={updateComponentValue}
						components={props.components}
						component={props.component}
						scale={scale}
						pageBoundingBox={props.pageBoundingBox}
						selected={isSelected}
					/>
				);
			case ComponentTypes.SelectComponent:
				return (
					<DropdownComponent
						component={props.component}
						componentValue={props.componentValue}
						options={props.component.select_options}
						selected={isSelected}
						onChange={updateComponentValue}
						pageBoundingBox={props.pageBoundingBox}
						scale={scale}
						componentVisibility={componentVisibility}
					/>
				);
			case ComponentTypes.InitialsComponent:
				return (
					<InitialsComponent
						componentValue={props.componentValue}
						selected={isSelected}
						onChange={updateComponentValue}
						textAlign={props.component.text_align}
						scale={scale}
					/>
				);
			case ComponentTypes.DateComponent:
				return (
					<DateComponent
						componentValue={props.componentValue}
						selected={isSelected}
						onChange={updateComponentValue}
						textAlign={props.component.text_align}
						dateFormat={props.component.date_format}
						isDateSigned={props.component.is_date_signed}
						scale={scale}
						componentVisibility={componentVisibility}
						width={props.component.width * props.pageBoundingBox.width}
						height={props.component.height * props.pageBoundingBox.height}
					/>
				);
			case ComponentTypes.SignatureComponent:
				return (
					<SignatureComponent
						component={props.component}
						componentValue={props.componentValue}
						savedSignature={filteredSignature}
						signatureColor={props.signatureColor}
						selected={isSelected}
						required={props.component.is_required}
						pageBoundingBox={props.pageBoundingBox}
						onChange={updateComponentValue}
						scale={scale}
						componentVisibility={componentVisibility}
					/>
				);
			case ComponentTypes.PaymentComponent:
				return (
					<PaymentComponent
						componentValue={props.componentValue}
						component={props.component}
						selected={props.selected}
						pageBoundingBox={props.pageBoundingBox}
						onChange={updateComponentValue}
						scale={scale}
						componentVisibility={componentVisibility}
					/>
				);
			default:
				return <>{props.component.type}</>;
		}
	};

	const componentWrapperRef = useRef<HTMLDivElement>(null);

	const updateSelectionCallback = React.useCallback(() => {
		setIsSelected(true);
		document
			.querySelector('.viewer-container-wrapper')
			?.removeEventListener('scroll', updateSelectionCallback);
	}, []);

	const getViewAlignment = React.useCallback(() => {
		if (
			props.component.type === ComponentTypes.TextComponent ||
			props.component.type === ComponentTypes.InitialsComponent
		) {
			return 'start';
		} else {
			return 'center';
		}
	}, [props.component.type]);

	React.useEffect(() => {
		if (props.selected) {
			const documentViewer = document.querySelector('.signer-document-viewer');
			const viewerContainerWrapper = document.querySelector('.viewer-container-wrapper');

			// wait till PDFViewer jumps to new page
			const timeout = setTimeout(() => {
				if (
					isVisible(
						componentWrapperRef.current,
						viewerContainerWrapper?.getBoundingClientRect()
					)
				) {
					updateSelectionCallback();
				} else {
					componentWrapperRef.current.scrollIntoView({
						behavior: zoomLevel.current === 1 ? 'smooth' : 'instant',
						block: 'center',
						inline: getViewAlignment(),
					});

					updateSelectionCallback();
					// wait till the animation end
					viewerContainerWrapper?.addEventListener('scroll', updateSelectionCallback);
					documentViewer?.addEventListener('scroll', updateSelectionCallback);
				}
			}, 50);

			return () => {
				clearTimeout(timeout);
				documentViewer?.removeEventListener('scroll', updateSelectionCallback);
				viewerContainerWrapper?.removeEventListener('scroll', updateSelectionCallback);
			};
		} else {
			setIsSelected(false);
		}
	}, [zoomLevel, props.selected, updateSelectionCallback, getViewAlignment, reposition]);

	const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		props.setSelectedComponentId(props.component.id);
		setIsSelected(true);

		if (!componentVisibility) {
			setReposition(!reposition);
		}
	};

	const scale = Math.min(props.pageBoundingBox.width / defaultWidth, 1);
	const mandatoryFieldDisplayOutside =
		props.component.type === ComponentTypes.CheckComponent ||
		props.component.type === ComponentTypes.CheckGroupComponent;
	const tooltipTitle =
		props.isAnnotation || props.component.type === ComponentTypes.CheckGroupComponent
			? ''
			: `${props.component.name} (${
					props.component.is_date_signed
						? t('esign-pilet-ui:signerPage.content.fields.autofilled')
						: props.component.is_required
						? t('esign-pilet-ui:required')
						: t('esign-pilet-ui:optional')
			  })${
					props.component.type === ComponentTypes.DateComponent
						? `:\n${t('esign-pilet-ui:signerPage.content.fields.format')}: ` +
						  props.component.date_format
						: ''
			  }${
					componentTypesWithHelpText.includes(props.component.type) &&
					props.component.help_text
						? ':\n' +
						  props.component.help_text +
						  (props.component.charge_after_executed
								? '. ' +
								  t(
										'esign-pilet-ui:signerPage.content.fields.paymentField.authorizeTooltip',
										{ paymentAmount: props.component.payment_amount }
								  )
								: '')
						: ''
			  }`;

	const updateComponentVisibility = React.useCallback(
		(event = null) => {
			setComponentVisibility(
				(mouseOver || isSelected) &&
					!!tooltipTitle &&
					isVisible(
						componentWrapperRef.current,
						document.querySelector('.viewer-container-wrapper')?.getBoundingClientRect()
					)
			);

			if (tooltipRef.current) {
				tooltipRef.current.forceAlign();
			}

			if (event?.detail?.zoomFactor) {
				zoomLevel.current = event.detail.zoomFactor;
			}
		},
		[isSelected, mouseOver, tooltipTitle]
	);

	React.useEffect(() => {
		const documentViewer = document.querySelector('.signer-document-viewer');
		const viewerContainer = document.querySelector('.viewer-container-wrapper');

		documentViewer?.addEventListener('scroll', updateComponentVisibility);
		viewerContainer?.addEventListener('scroll', updateComponentVisibility);

		updateComponentVisibility();

		return () => {
			documentViewer?.removeEventListener('scroll', updateComponentVisibility);
			viewerContainer?.removeEventListener('scroll', updateComponentVisibility);
		};
	}, [updateComponentVisibility]);

	React.useEffect(() => {
		window?.addEventListener('zoom', updateComponentVisibility);
		updateComponentVisibility();

		return () => window?.removeEventListener('zoom', updateComponentVisibility);
	}, [updateComponentVisibility]);

	return (
		<Tooltip
			open={componentVisibility}
			trigger={null}
			placement="top"
			color={'#3C4043'}
			overlayStyle={tooltipStyle}
			overlayInnerStyle={tooltipInnerStyle}
			title={
				<div>
					{tooltipTitle.split('\n').map((line, index) => (
						<span key={index}>
							{line}
							<br />
						</span>
					))}
				</div>
			}
			data-testid={`SignerComponentTooltip-${props.component.id}`}
			ref={tooltipRef}
			zIndex={1100}
		>
			<StyledComponentWrapper
				className="signer-field"
				key={props.component.id}
				left={props.component.x * props.pageBoundingBox.width}
				top={props.component.y * props.pageBoundingBox.height}
				width={props.component.width * props.pageBoundingBox.width}
				height={props.component.height * props.pageBoundingBox.height}
				data-testid={`SignerComponent-${props.component.id}`}
				id={`SignerComponent-${props.component.id}`}
				transparent={props.component.transparent}
				onClick={onClick}
				ref={componentWrapperRef}
				onMouseEnter={event =>
					// check if the target is a child of the field component (for attachment and signature it wouldn't be true)
					setMouseOver(event.currentTarget.contains(event.target as Node))
				}
				onMouseOut={event =>
					// check if the related target is not part of the field component
					setMouseOver(componentWrapperRef.current.contains(event.relatedTarget as Node))
				}
				onBlur={() => setMouseOver(false)}
			>
				{renderNode()}
				{!props.isAnnotation && props.component.is_required && (
					<MandatoryFieldIndicator
						displayOutside={mandatoryFieldDisplayOutside}
						scale={scale}
					/>
				)}
			</StyledComponentWrapper>
		</Tooltip>
	);
});
