import React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import {
	ButtonPause,
	CheckCircle,
	DeclineDocument,
	DeleteCircle,
	WarningTriangle,
} from '@sharefiledev/icons';
import { Button, Col, Grid, notification, Row, Space, theme, Typography } from 'antd';
import { ESignatureClient } from '../../data/eSignatureClient';
import {
	SignerUnauthorizedStates,
	UnauthorizedContent,
	ViewerUnauthorizedStates,
} from '../../data/rsTypes';
import { t } from '../../util';
import { StyledModal } from './SuccessModal.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const modalBodyStyles: React.CSSProperties = {
	overflow: 'auto',
	scrollbarWidth: 'none',
	padding: `${sizes.XXS}`,
};

const headerStyles: React.CSSProperties = {
	fontWeight: 600,
	lineHeight: '28px',
	fontSize: '20px',
	textAlign: 'center',
};

const commonStyles: React.CSSProperties = {
	justifyContent: 'center',
	display: 'flex',
};

const textStyles: React.CSSProperties = {
	fontSize: sizes.base,
	textAlign: 'center',
	fontWeight: 400,
	lineHeight: sizes.MS,
};

const successMaskStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.green5} , ${colorPalette.lavender4}, ${colorPalette.pink3})`,
};

const errorMaskStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.orange4} , ${colorPalette.pink3}, ${colorPalette.lavender4})`,
};

const UnauthorizedContents: UnauthorizedContent[] = [
	{
		signerState: SignerUnauthorizedStates.DocumentIsExecuted,
		viewerState: null,
		title: 'esign-pilet-ui:unauthorizedModal.completed.title',
		description: 'esign-pilet-ui:unauthorizedModal.completed.description',
		boldDescription: '',
		icon: CheckCircle,
		isSuccess: true,
	},
	{
		signerState: SignerUnauthorizedStates.SignedBySigner,
		viewerState: null,
		title: 'esign-pilet-ui:unauthorizedModal.alreadySigned.title',
		description: 'esign-pilet-ui:unauthorizedModal.alreadySigned.description',
		boldDescription: '',
		icon: CheckCircle,
		isSuccess: true,
	},
	{
		signerState: SignerUnauthorizedStates.Declined,
		viewerState: ViewerUnauthorizedStates.Declined,
		title: 'esign-pilet-ui:unauthorizedModal.declined.title',
		description: 'esign-pilet-ui:unauthorizedModal.declined.description',
		boldDescription: '',
		icon: DeclineDocument,
		isSuccess: false,
	},
	{
		signerState: SignerUnauthorizedStates.OnRevision,
		viewerState: ViewerUnauthorizedStates.OnRevision,
		title: 'esign-pilet-ui:unauthorizedModal.onRevision.title',
		description: 'esign-pilet-ui:unauthorizedModal.onRevision.description',
		boldDescription: '',
		icon: ButtonPause,
		isSuccess: false,
	},
	{
		signerState: SignerUnauthorizedStates.Voided,
		viewerState: ViewerUnauthorizedStates.Voided,
		title: 'esign-pilet-ui:unauthorizedModal.voided.title',
		description: 'esign-pilet-ui:unauthorizedModal.voided.description',
		boldDescription: '',
		icon: DeleteCircle,
		isSuccess: false,
	},
	{
		signerState: SignerUnauthorizedStates.NotYouTurn,
		viewerState: null,
		title: 'esign-pilet-ui:unauthorizedModal.wrongTurn.title',
		description: 'esign-pilet-ui:unauthorizedModal.wrongTurn.description',
		boldDescription: '',
		icon: WarningTriangle,
		isSuccess: false,
	},
	{
		signerState: null,
		viewerState: ViewerUnauthorizedStates.NoAccess,
		title: 'esign-pilet-ui:unauthorizedModal.noAccess.title',
		description: 'esign-pilet-ui:unauthorizedModal.noAccess.description',
		boldDescription: '',
		icon: ButtonPause,
		isSuccess: false,
	},
	{
		signerState: SignerUnauthorizedStates.Expired,
		viewerState: ViewerUnauthorizedStates.Expired,
		title: 'esign-pilet-ui:unauthorizedModal.expired.title',
		description: 'esign-pilet-ui:unauthorizedModal.expired.description',
		boldDescription: '',
		icon: ButtonPause,
		isSuccess: false,
	},
	{
		signerState: null,
		viewerState: ViewerUnauthorizedStates.TokenExpired,
		title: 'esign-pilet-ui:unauthorizedModal.tokenExpired.title',
		description: 'esign-pilet-ui:unauthorizedModal.tokenExpired.description',
		boldDescription: '',
		icon: DeclineDocument,
		isSuccess: false,
	},
	{
		signerState: SignerUnauthorizedStates.Invalid,
		viewerState: ViewerUnauthorizedStates.Invalid,
		title: 'esign-pilet-ui:unauthorizedModal.invalid.title',
		description: 'esign-pilet-ui:unauthorizedModal.invalid.description',
		boldDescription: '',
		icon: DeleteCircle,
		isSuccess: false,
	},
	{
		signerState: SignerUnauthorizedStates.PendingIdentityAuthentication,
		viewerState: null,
		title: 'esign-pilet-ui:unauthorizedModal.pendingIdentityVerification.title',
		description:
			'esign-pilet-ui:unauthorizedModal.pendingIdentityVerification.description',
		boldDescription:
			'esign-pilet-ui:unauthorizedModal.pendingIdentityVerification.boldDescription',
		icon: DeleteCircle,
		isSuccess: false,
	},
];

export interface Props {
	senderEmail: string;
	unauthorizedState: SignerUnauthorizedStates | ViewerUnauthorizedStates;
	signerId?: string;
	redirectToken?: string;
}

export function UnauthorizedModal(props: Props) {
	const { token } = useToken();
	const screens = useBreakpoint();

	const content = UnauthorizedContents.find(
		content =>
			content.signerState === props.unauthorizedState ||
			content.viewerState === props.unauthorizedState
	);

	const Icon = content.icon;
	const iconColor = content.isSuccess ? colorPalette.green8 : colorPalette.red6;
	const maskStyle = content.isSuccess ? successMaskStyle : errorMaskStyle;
	const showSendNewLinkButton =
		content.isSuccess ||
		props.unauthorizedState === ViewerUnauthorizedStates.TokenExpired ||
		props.unauthorizedState === SignerUnauthorizedStates.PendingIdentityAuthentication;
	const sendNewLinkButtonText = showSendNewLinkButton
		? props.unauthorizedState === SignerUnauthorizedStates.PendingIdentityAuthentication
			? t('esign-pilet-ui:unauthorizedModal.resendVerificationLink')
			: t('esign-pilet-ui:unauthorizedModal.sendNewLink')
		: '';

	const onSendNewLink = async () => {
		if (
			props.unauthorizedState === SignerUnauthorizedStates.PendingIdentityAuthentication
		) {
			await ESignatureClient.sendIdentityVerification(props.signerId);
		} else {
			await ESignatureClient.sendNewViewLink(props.signerId, props.redirectToken);
		}

		notification.success({
			message: t('esign-pilet-ui:unauthorizedModal.notification.title'),
			description: t('esign-pilet-ui:unauthorizedModal.notification.description'),
		});
	};

	return (
		<StyledModal
			open={true}
			closeIcon={null}
			centered={screens.sm}
			styles={{ body: modalBodyStyles, mask: maskStyle }}
			width={576}
			footer={null}
			token={token}
			data-testid="unauthorized-modal"
		>
			<Space wrap direction="vertical" size={12} style={{ width: '100%' }}>
				<Row style={{ ...commonStyles, marginBottom: sizes.MD }}>
					<SharefileTextLogoIcon width={140} />
				</Row>

				<Row style={{ ...commonStyles, marginBottom: sizes.XXS }}>
					<Icon size={44} color={iconColor} />
				</Row>

				<Row style={commonStyles}>
					<Typography.Text style={headerStyles}>{t(content.title)}</Typography.Text>
				</Row>

				<Row style={commonStyles}>
					<Typography.Text style={textStyles}>
						{t(content.description, {
							boldDescription: (
								<Typography.Text strong style={textStyles}>
									{t(content.boldDescription)}
								</Typography.Text>
							),
						})}
					</Typography.Text>
				</Row>

				{(showSendNewLinkButton || props.senderEmail) && (
					<Row style={commonStyles} gutter={[8, 8]}>
						{!showSendNewLinkButton ? (
							props.senderEmail && (
								<Typography.Text style={{ ...textStyles, marginTop: sizes.XXS }}>
									<Typography.Text strong>
										{t('esign-pilet-ui:unauthorizedModal.sender')}:{' '}
									</Typography.Text>
									{props.senderEmail}
								</Typography.Text>
							)
						) : (
							<Col span={24}>
								<Button
									type="primary"
									style={{ width: '100%', marginTop: sizes.SM }}
									onClick={onSendNewLink}
									data-testid="newlink-button"
								>
									{sendNewLinkButtonText}
								</Button>
							</Col>
						)}
					</Row>
				)}
			</Space>
		</StyledModal>
	);
}
