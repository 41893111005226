import * as React from 'react';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { useQuery } from '@tanstack/react-query';
import { Alert, Button, Grid, Input, Row, Space, theme, Typography } from 'antd';
import { ESignatureClient } from '../../../data/eSignatureClient';
import { t } from '../../../util';
import { AuthenticationFailedModal } from '../AuthenticationFailedModal';
import { PasscodeModalStyled } from './PasscodeModal.styled';

const maskStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.green5} , ${colorPalette.lavender4}, ${colorPalette.pink3})`,
};

const elemntFullWidth: React.CSSProperties = {
	width: '100%',
};

const greetingsStyles: React.CSSProperties = {
	fontSize: 20,
	fontWeight: 600,
};

const inputLabel: React.CSSProperties = {
	paddingBottom: sizes.XS,
	fontSize: 14,
};

const alertStyles: React.CSSProperties = {
	...elemntFullWidth,
	marginBottom: sizes.MS,
	marginTop: -sizes.XS,
};

const modalStyles = {
	mask: maskStyle,
};

const { useToken } = theme;
const { useBreakpoint } = Grid;

export interface Props {
	// signerRequestData: SignerRequest;
	signerId: string;
	searchParams: URLSearchParams;
	setDocumentLock: (isDocumentLock) => void;
	unlockDocument: (answerDigest) => void;
}

export function PasscodeModal(props: Props) {
	const [isPasscodeModalOpen] = React.useState<boolean>(true);
	const { token } = useToken();
	const [value, setValue] = React.useState('');
	const [isAuthFailed, setAuthFailed] = React.useState(false);
	const [isPasscodeValid, setPasscodeValidationStatus] = React.useState(true);
	const [validationMessage, setValidationMessage] = React.useState('');
	const MaxAttempts = 3;
	const screens = useBreakpoint();

	const {
		isSuccess: passcodeInformationDataFetched,
		data: passcodeInformation,
		refetch,
	} = useQuery(
		[],
		() => ESignatureClient.getPasscodeInformation(props.signerId, props.searchParams),
		{
			refetchOnWindowFocus: false,
		}
	);

	React.useEffect(() => {
		if (
			passcodeInformationDataFetched &&
			passcodeInformation?.failed_passcode_attempts == 3
		) {
			setAuthFailed(true);
		}
	}, [passcodeInformationDataFetched, passcodeInformation, setAuthFailed]);

	const handleUnlockDocument = async () => {
		if (isPasscodeInputValid()) {
			props.searchParams.delete('passcode_answer');
			props.searchParams.append('passcode_answer', value);
			await ESignatureClient.checkPasscode(props.signerId, props.searchParams)
				.then(result => {
					if (result?.passcode_valid) {
						props.searchParams.delete('passcode_answer');
						props.unlockDocument(result.passcode_answer_digest);
					}

					setPasscodeValidationStatus(result?.passcode_valid);
				})
				.finally(() => refetch());
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = e.target;
		const reg = /^\d{0,5}$/;

		if (reg.test(inputValue)) {
			setValue(inputValue);
		}

		if (inputValue.length == 0) {
			setPasscodeValidationStatus(true);
			setValidationMessage('');
		}
	};

	const isPasscodeInputValid = () => {
		if (value.length == 0) {
			setPasscodeValidationStatus(false);
			setValidationMessage(
				t('esign-pilet-ui:signerPage.modals.passcode.inputValueRequiedMessage')
			);
			return false;
		}

		if (value.length > 0 && value.length < 5) {
			setPasscodeValidationStatus(false);
			setValidationMessage(
				t('esign-pilet-ui:signerPage.modals.passcode.inputValidationMessage')
			);
			return false;
		}

		setValidationMessage('');
		return true;
	};

	return (
		<>
			{isAuthFailed ? (
				<AuthenticationFailedModal
					data-testid="modal-passcode-auth-failed"
					isModalOpen={true}
					contentHeader={t(
						'esign-pilet-ui:signerPage.modals.authenticationFailed.contentHeader'
					)}
					contentMessage={t(
						'esign-pilet-ui:signerPage.modals.authenticationFailed.contentMessage',
						{ br: <br /> }
					)}
					senderEmail={null}
				/>
			) : (
				<PasscodeModalStyled
					open={isPasscodeModalOpen}
					title={<SharefileTextLogoIcon width={140} />}
					token={token}
					centered={true}
					width={screens.xs ? '100%' : 575}
					closeIcon={null}
					styles={modalStyles}
					footer={[
						<Button
							size="middle"
							shape="round"
							key="submit"
							type="primary"
							onClick={handleUnlockDocument}
							style={elemntFullWidth}
							data-testid="modal-button-unlock"
						>
							{t('esign-pilet-ui:signerPage.modals.passcode.unlockButton')}
						</Button>,
					]}
					data-testid="modal-passcode"
				>
					<Global
						styles={css`
							.ant-modal-root .ant-modal-wrap {
								overflow: unset !important;
							}
						`}
					/>
					<Space wrap direction="vertical" style={elemntFullWidth}>
						<Row style={{ marginTop: sizes.MD, marginBottom: sizes.XS }}>
							<Typography.Text
								style={{ paddingRight: sizes.XXS, ...greetingsStyles }}
								data-testid="modal-greetings"
							>
								{t('esign-pilet-ui:signerPage.modals.passcode.welcomeMessage')}
							</Typography.Text>
						</Row>
						<Row style={{ marginBottom: sizes.MD }}>
							{/* uncomment it once the signer info is available */}

							{/* {t('esign-pilet-ui:signerPage.modals.passcode.senderInformation', 
							{
								senderName: props.signerRequestData.documents[0].sender_name,
								senderEmail: props.signerRequestData.documents[0].sender_email,
								documentName: <strong>props.signerRequestData.documents[0].name</strong>
							})} */}
							{t('esign-pilet-ui:signerPage.modals.passcode.contentMessage')}
						</Row>
						<Row>
							{passcodeInformationDataFetched &&
							passcodeInformation?.failed_passcode_attempts > 0 ? (
								<Alert
									type="error"
									showIcon
									style={alertStyles}
									message={
										<label>
											{t(
												'esign-pilet-ui:signerPage.modals.passcode.invalidPasscodeMessage'
											)}{' '}
											<strong>
												{t(
													passcodeInformation.failed_passcode_attempts == 2
														? 'esign-pilet-ui:signerPage.modals.passcode.attemptsAmount'
														: 'esign-pilet-ui:signerPage.modals.passcode.attemptsAmountPlural',
													{
														attemptsAmount:
															MaxAttempts - passcodeInformation.failed_passcode_attempts,
													}
												)}
											</strong>
										</label>
									}
								/>
							) : null}
						</Row>
						<Row style={{ marginBottom: sizes.MD }}>
							<label style={inputLabel}>
								{t('esign-pilet-ui:signerPage.modals.passcode.inputLabel')}
							</label>
							<Input
								style={elemntFullWidth}
								placeholder={t(
									'esign-pilet-ui:signerPage.modals.passcode.inputPlaceholder'
								)}
								value={value}
								status={isPasscodeValid ? '' : 'error'}
								allowClear={!isPasscodeValid}
								onChange={handleChange}
								data-testid="modal-input-passcode"
								inputMode="numeric"
							></Input>
							{validationMessage ? (
								<Typography.Text style={{ fontSize: 14, color: colorPalette.red6 }}>
									{validationMessage}
								</Typography.Text>
							) : null}
						</Row>
					</Space>
				</PasscodeModalStyled>
			)}
		</>
	);
}
