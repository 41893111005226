import React from 'react';
import { colorPalette } from '@sharefiledev/antd-config';
import { typography } from '@sharefiledev/flow-web';
import { Flex, Typography } from 'antd';

const { Text, Title } = Typography;

const textStyles: React.CSSProperties = {
	fontSize: `${typography.sizes.sml}`,
	lineHeight: '22px',
	margin: 0,
	textDecoration: 'none',
};

type DetailTileProps = {
	title: string;
	primaryText: string;
	secondaryText?: string;
};

export const DetailTile: React.FC<DetailTileProps> = ({
	title,
	primaryText,
	secondaryText,
}) => {
	return (
		<>
			<Flex vertical gap={4}>
				<Title level={5} style={textStyles}>
					{title}
				</Title>
				{primaryText && <Text style={textStyles}>{primaryText}</Text>}
				{secondaryText && (
					<Text
						style={{ ...textStyles, color: colorPalette.neutral7 }}
						data-testid="secondary"
					>
						{secondaryText}
					</Text>
				)}
			</Flex>
		</>
	);
};
