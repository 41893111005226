import React, { forwardRef, RefObject, useRef } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Tooltip } from 'antd';
import { Component, ComponentValue, GroupRequirement } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { isVisible, updateCheckboxGroupBorderBox } from '../../util';
import { CheckComponent } from './CheckComponent';

interface Props {
	componentValue: ComponentValue;
	component: Component;
	components: Component[];
	onChange: (value: string) => void;
	scale: number;
	pageBoundingBox: DOMRect;
	selected: boolean;
}

const tooltipStyle: React.CSSProperties = {
	borderRadius: sizes.SM,
	background: colorPalette.neutral9,
	padding: sizes.XXS,
};

const borderStyles: React.CSSProperties = {
	display: 'none',
	position: 'absolute',
	boxSizing: 'border-box',
	zIndex: 30,
	borderRadius: sizes.XXS,
	pointerEvents: 'none',
};

export const CheckGroupComponent = (props: Props) => {
	const [isBorderVisible, setIsBorderVisible] = React.useState<boolean>(false);
	const borderBoxRef = useRef(null);
	const [showTooltip, setShowTooltip] = React.useState(false);

	const onCheckboxValueChange = (value: string) => {
		props.onChange(String(value));
	};

	const updateBoundingBorderBox = React.useCallback(() => {
		const checkboxGroupComponents = props.components.filter(
			component =>
				!!component.group_id &&
				!!props.component.group_id &&
				component.group_id === props.component.group_id
		);

		updateCheckboxGroupBorderBox(
			checkboxGroupComponents,
			borderBoxRef,
			props.component,
			props.pageBoundingBox,
			props.scale
		);
	}, [props.component, props.components, props.pageBoundingBox, props.scale]);

	const handleMouseOverEvent = React.useCallback(() => {
		if (!isBorderVisible) {
			updateBoundingBorderBox();
			setIsBorderVisible(true);
		}
	}, [isBorderVisible, updateBoundingBorderBox]);

	const handleMouseLeaveEvent = React.useCallback(() => {
		setIsBorderVisible(false);
	}, []);

	React.useEffect(() => {
		if (props.selected) {
			updateBoundingBorderBox();
		}
	}, [updateBoundingBorderBox, props.selected]);

	const updateTooltipVisibility = React.useCallback(() => {
		setShowTooltip(
			(isBorderVisible || props.selected) &&
				isVisible(
					borderBoxRef.current,
					document.querySelector('.viewer-container-wrapper')?.getBoundingClientRect()
				)
		);
	}, [isBorderVisible, props.selected]);

	React.useEffect(() => {
		const documentViewer = document.querySelector('.signer-document-viewer');
		const viewerContainer = document.querySelector('.viewer-container-wrapper');

		documentViewer?.addEventListener('scroll', updateTooltipVisibility);
		viewerContainer?.addEventListener('scroll', updateTooltipVisibility);

		updateTooltipVisibility();

		return () => {
			documentViewer?.removeEventListener('scroll', updateTooltipVisibility);
			viewerContainer?.removeEventListener('scroll', updateTooltipVisibility);
		};
	}, [updateTooltipVisibility]);

	return (
		<>
			<Tooltip
				placement="top"
				title={
					<>
						{props.component.name}
						{' ('}
						{(props.component.is_required
							? t('esign-pilet-ui:required')
							: t('esign-pilet-ui:optional')) + '):'}{' '}
						<br />{' '}
						{props.component.group_requirement === GroupRequirement.OnlyOne
							? t('esign-pilet-ui:selectOnlyOneLabel')
							: t('esign-pilet-ui:selectOneOrMoreLabel')}
					</>
				}
				open={showTooltip}
				color={colorPalette.neutral9}
				overlayStyle={tooltipStyle}
				overlayInnerStyle={{ boxShadow: 'none' }}
				zIndex={1100}
			>
				<BoundingBorder
					isVisible={isBorderVisible || props.selected}
					ref={borderBoxRef}
				/>
			</Tooltip>
			<div
				onMouseOver={handleMouseOverEvent}
				onMouseLeave={handleMouseLeaveEvent}
				data-testid={`CheckComponent-${props.componentValue.component_id}`}
				style={{ width: '100%', height: '100%' }}
			>
				<CheckComponent
					componentValue={props.componentValue}
					onChange={onCheckboxValueChange}
					selected={props.selected}
				/>
			</div>
		</>
	);
};

interface BoundingBorderProps {
	isVisible: boolean;
}

const BoundingBorder = forwardRef(
	({ isVisible }: BoundingBorderProps, ref: RefObject<HTMLDivElement>) => {
		return (
			<div
				style={{
					...borderStyles,
					display: isVisible ? 'block' : 'none',
					border: `1px dashed ${colorPalette.lavender6}`,
				}}
				data-testid="checbox-group-border-box"
				ref={ref}
			></div>
		);
	}
);
