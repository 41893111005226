import styled from '@emotion/styled';

export const PageSelectorBar = styled.div`
	display: flex;
	align-items: center;
	align-self: center;
	justify-content: center;
	flex: 1;
	height: 56px;
`;
