import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Close } from '@sharefiledev/icons';
import { Button, GlobalToken, List, Select } from 'antd';

export const StyledSelectWrapper = styled(Select)<{
	borderColor: string;
	hasValue: boolean;
	backgroundColor: string;
	token: GlobalToken;
	scale: number;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100%;
	border: none;

	.ant-select-selection-placeholder {
		border: none !important;
		box-shadow: none !important;
	}

	.ant-select-selector {
		border-radius: ${sizes.XXS} !important;
		border: 1px solid ${props => props.borderColor} !important;
		box-shadow: none !important;
		background-color: ${props => props.backgroundColor} !important;
		padding-left: ${sizes.XXS} !important;
	}

	.ant-select-selection-item {
		font-size: ${props => 16 * props.scale}px;
	}

	div > span {
		color: ${colorPalette.neutral13} !important;
	}

	div:hover,
	div:focus,
	div:active,
	div:focus-within {
		border: 2px solid ${props => props.borderColor} !important;
		box-shadow: none !important;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
`;

export const StyledClearButton = styled(Button)<{
	padding: string;
	token: GlobalToken;
}>`
	display: flex;
	align-items: center;
	height: fit-content;
	width: 100%;
	white-space: break-spaces;
	text-align: left;
	padding: ${props => props.padding};
	border-radius: 4px;
	min-height: ${sizes.XL};

	span:not(.ant-btn-icon) {
		overflow: hidden !important;
		text-overflow: ellipsis !important;
	}

	@media (max-width: ${props => props.token.screenLGMax}px) {
		font-size: ${sizes.SM};
		padding: 0px;
		min-height: ${sizes.SM} !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS};
		padding: 0px;
		min-height: ${sizes.SM} !important;
	}
`;

export const SyledCloseIcon = styled(Close)<{
	token: GlobalToken;
}>`
	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS} !important;
	}
`;

export const StyledDrawerButton = styled(Button)<{
	token: GlobalToken;
	hasValue: boolean;
	borderColor: string;
	backgroundColor: string;
}>`
	display: flex;
	align-items: left;
	width: 100%;
	height: 100%;
	padding: 0px;

	border-radius: ${sizes.XXS} !important;
	border: 1px solid ${props => props.borderColor} !important;
	box-shadow: none !important;
	background-color: ${props => props.backgroundColor} !important;
`;

export const StyledListItem = styled(List.Item)<{
	isSelected: boolean;
}>`
	background-color: ${({ isSelected }) => (isSelected ? '#E5EBFF' : 'transparent')};
	display: flex;
	justify-content: space-between;
	padding: 4px 8px !important;
	cursor: pointer;
	border-radius: 4px;
	gap: 8px;
	min-height: 40px;
	border-bottom: none !important;
`;
