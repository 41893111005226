import styled from '@emotion/styled';

export const StyledTextArea = styled.textarea<{ fontSize: number; scale: number }>`
	border: none;
	background: transparent;
	outline: none;
	overflow: hidden;
	padding: 0px;
	position: absolute;
	top: 0;
	left: 0;
	flex-grow: 1;
	resize: none;
	overflow-x: auto;
	height: auto;
	width: auto;
	line-height: 1.25;
	font-size: ${props => props.fontSize * props.scale}px;
	font-family: Courier, sans-serif;
`;
