import styled from '@emotion/styled';
import { sizes } from '@sharefiledev/antd-config';
import { GlobalToken, Modal } from 'antd';

export const StyledSwitchExperienceModal = styled(Modal)<{
	token: GlobalToken;
}>`
	.ant-modal-title {
		@media (max-width: ${props => props.token.screenXS}px) {
			margin-right: ${sizes.MS};
		}
	}

	.ant-modal-footer {
		justify-content: right !important;
	}

	.ant-modal-content {
		@media (max-width: ${props => props.token.screenXS}px) {
			margin: ${sizes.base};
			width: fit-content !important;
		}
	}

	.ant-modal-body {
		padding-top: ${sizes.base};
		padding-bottom: ${sizes.base};
	}
`;
