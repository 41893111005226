import styled from '@emotion/styled';

export const StyledOverlayerPage = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	margin-bottom: 10px;
	transform: translateZ(0);
	z-index: 10;
`;
