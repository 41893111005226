import React from 'react';
import { Component, ComponentValue, GroupRequirement } from '../../data/rsTypes';
import { ComponentTypes } from '../Components/types';
import { useAppStore } from '../store';
import { SignerComponent } from './SignerComponents';

interface Props {
	components: Component[];
	pageBoundingBox: DOMRect;
	selectedComponentId?: string;
	setSelectedComponentId?: (id: string) => void;
	signatureColor: string;
}

export const SignerComponentsRenderer = (props: Props) => {
	const [signer, componentValues, setComponentValue] = useAppStore(store => [
		store.signer,
		store.componentValues,
		store.setComponentValue,
	]);

	const components = props.components.map(component => {
		const onSetComponentValue = (value: ComponentValue) => {
			setComponentValue(value);

			if (
				component.type === ComponentTypes.CheckGroupComponent &&
				component.group_requirement === GroupRequirement.OnlyOne
			) {
				const checkboxGroupComponents = props.components.filter(
					comp =>
						!!comp.group_id &&
						comp.group_id === component.group_id &&
						comp.id !== component.id
				);
				checkboxGroupComponents.forEach(checkboxComponent => {
					const checkboxValue = componentValues.get(checkboxComponent.id);
					setComponentValue({ ...checkboxValue, value: 'false' });
				});
			}
		};

		const isAnnotation = component.type.includes('Annotation');
		if (!isAnnotation && component.assigned_to !== signer.role_name) {
			return <></>;
		}
		const componentValue = componentValues.get(component.id);

		return (
			<SignerComponent
				key={component.id}
				component={component}
				components={props.components}
				componentValue={componentValue}
				updateComponentValue={onSetComponentValue}
				pageBoundingBox={props.pageBoundingBox}
				selected={props.selectedComponentId === component.id}
				setSelectedComponentId={props.setSelectedComponentId}
				isAnnotation={isAnnotation}
				signatureColor={props.signatureColor}
			/>
		);
	});

	return <>{components}</>;
};
