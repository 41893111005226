import * as React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Download } from '@sharefiledev/icons';
import { Button, Flex, Grid, Spin, theme, Typography } from 'antd';
import { pollWithConstantBackOff } from '../../Common/poller';
import { useAppStore } from '../../Common/store';
import { ESignatureClient } from '../../data/eSignatureClient';
import { DownloadType } from '../../data/rsTypes';
import { t } from '../../util';
import { downloadFileWithName } from '../../util/file';
import { StyledModal } from './DownloadModal.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

export interface Props {
	documentId: string;
	isModalOpen: boolean;
	redirectToken: string;
	setIsModalOpen: (isModalOpen: boolean) => void;
}

const modalBodyStyles: React.CSSProperties = {
	marginTop: sizes.base,
	marginBottom: sizes.MS,
	marginRight: sizes.MS,
};

const descriptionTextStyles: React.CSSProperties = {
	fontSize: sizes.SM,
	color: colorPalette.neutral6,
};

export function DownloadModal(props: Props) {
	const { token } = useToken();
	const screens = useBreakpoint();
	const [isDocumentProcessed, setIsDocumentProcessed] = React.useState<boolean>(false);
	const [document, baseFiles, setEsignDocument] = useAppStore(state => [
		state.esignDocument,
		state.baseFiles,
		state.setEsignDocument,
	]);

	const downloadOptions = React.useMemo(
		() => [
			{
				title: t('esign-pilet-ui:previewPage.downloadModal.completedDocumentTitle'),
				description: t(
					'esign-pilet-ui:previewPage.downloadModal.completedDocumentDescription'
				),
				type: DownloadType.Completed,
			},
			{
				title: t('esign-pilet-ui:previewPage.downloadModal.signatureCertificateTitle'),
				description: t(
					'esign-pilet-ui:previewPage.downloadModal.signatureCertificateDescription'
				),
				type: DownloadType.Certificate,
			},
			{
				title: t('esign-pilet-ui:previewPage.downloadModal.combinedTitle'),
				description: t('esign-pilet-ui:previewPage.downloadModal.combinedDescription'),
				type: DownloadType.Combined,
			},
			{
				title: t('esign-pilet-ui:previewPage.downloadModal.originalTitle'),
				description: t('esign-pilet-ui:previewPage.downloadModal.originalDescription'),
				type: DownloadType.Original,
			},
		],
		[]
	);

	const handleDownload = (downloadType: string) => {
		let downloadUrl = '';

		switch (downloadType) {
			case DownloadType.Original:
				downloadUrl = baseFiles.downloaded_file_url;
				downloadFileWithName(downloadUrl, document?.name);
				break;
			case DownloadType.Completed:
				downloadUrl = document.signed_pdf_url;
				window.open(downloadUrl, '_blank');
				break;
			case DownloadType.Certificate:
				downloadUrl = document.document_certificate_url;
				window.open(downloadUrl, '_blank');
				break;
			case DownloadType.Combined:
				downloadUrl = document.merged_document_certificate_url;
				window.open(downloadUrl, '_blank');
				break;
		}
	};

	React.useEffect(() => {
		let isPollingDocumentCancelled = false;
		const getSignedPdfUrl = async () => {
			await pollWithConstantBackOff({
				pollFn: () => ESignatureClient.getDocument(props.documentId, props.redirectToken),
				maxAttempts: 30,
				validateFn: result => {
					const url = result?.document?.merged_document_certificate_url;
					if (!!url) {
						setIsDocumentProcessed(true);
						setEsignDocument(result.document);
					}
					return !!url || isPollingDocumentCancelled;
				},
				delayInMilliSeconds: 1000,
			});
		};
		if (!isDocumentProcessed) {
			getSignedPdfUrl();
		}
		return () => {
			isPollingDocumentCancelled = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isDownloadOptionEnabled = (type: DownloadType) => {
		if (type === DownloadType.Original) {
			return true;
		} else {
			return isDocumentProcessed;
		}
	};

	return (
		<StyledModal
			open={props.isModalOpen}
			onCancel={() => props.setIsModalOpen(false)}
			title={t('esign-pilet-ui:previewPage.downloadModal.title')}
			centered={screens.sm}
			width={540}
			styles={{ body: modalBodyStyles }}
			footer={[
				<Button
					size="small"
					shape="round"
					key="submit"
					type="primary"
					data-testid="modal-button-done"
					style={{ height: sizes.MD }}
					onClick={() => props.setIsModalOpen(false)}
				>
					{t('esign-pilet-ui:previewPage.downloadModal.done')}
				</Button>,
			]}
			data-testid="download-modal"
			token={token}
		>
			<Flex vertical gap={24}>
				{downloadOptions.map(option => (
					<Flex justify="space-between" align="center" gap={16} key={option.type}>
						<Flex vertical gap={4}>
							<Typography.Text>{option.title}</Typography.Text>
							<Typography.Text style={descriptionTextStyles}>
								{option.description}
							</Typography.Text>
						</Flex>
						<Flex justify="center" style={{ width: sizes.XL }}>
							{isDownloadOptionEnabled(option.type) ? (
								<Button
									id="keyboard-shortcut-button"
									data-testid={`download-icon-${option.type}`}
									size="middle"
									type="text"
									icon={<Download color={colorPalette.lavender6} size={24} />}
									onClick={() => handleDownload(option.type)}
								></Button>
							) : (
								<Spin size="small" spinning data-testid="loading-spinner-antd" />
							)}
						</Flex>
					</Flex>
				))}
			</Flex>
		</StyledModal>
	);
}
