import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
	.ant-collapse-content {
		background: ${colorPalette.neutral1} !important;
	}

	.ant-collapse-content-box {
		padding: ${sizes.LG} !important;
	}

	.ant-collapse-header-text {
		font-size: ${sizes.base};
	}

	.ant-collapse-header:focus {
		border: 4px solid ${colorPalette.lavender6} !important;
	}
`;
