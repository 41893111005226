import React from 'react';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { DeleteCircle } from '@sharefiledev/icons';
import { Row, Space, theme, Typography } from 'antd';
import { PasscodeModalStyled } from './Passcode/PasscodeModal.styled';

const maskAuthFailedStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.orange4} , ${colorPalette.pink3}, ${colorPalette.lavender4})`,
};

const modalStyles = {
	mask: maskAuthFailedStyle,
};

const { useToken } = theme;

export interface Props {
	isModalOpen: boolean;
	contentHeader: string;
	contentMessage: string;
	senderEmail: string;
}

export function AuthenticationFailedModal(props: Props) {
	const { token } = useToken();

	return (
		<PasscodeModalStyled
			open={props.isModalOpen}
			title={<SharefileTextLogoIcon width={140} />}
			token={token}
			centered={true}
			width={575}
			closeIcon={null}
			styles={modalStyles}
			footer={[]}
		>
			<Global
				styles={css`
					.ant-modal-root .ant-modal-wrap {
						overflow: unset !important;
					}
				`}
			/>
			<Space wrap direction="vertical" align="center">
				<Row style={{ marginTop: sizes.MS, marginBottom: 18 }}>
					<DeleteCircle color={colorPalette.red6} size={36} />
				</Row>
				<Row style={{ marginBottom: sizes.SM }}>
					<Typography.Text style={{ fontSize: 20 }}>
						<strong>{props.contentHeader}</strong>
					</Typography.Text>
				</Row>
				<Row style={{ marginBottom: sizes.base, textAlign: 'center' }}>
					<Typography.Text style={{ fontSize: sizes.base }}>
						{props.contentMessage}
					</Typography.Text>
				</Row>
				<Row>
					{/* uncomment it once the signer info is available */}
					{/* <strong>Sender:</strong> {props.senderEmail} */}
				</Row>
			</Space>
		</PasscodeModalStyled>
	);
}
