import React, { useEffect, useRef, useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { Alert, Grid, Row, Space, Steps, theme, Typography } from 'antd';
import { useSearchParams } from '../../../Common/Hooks/useSearchParams';
import { KBAStates } from '../../../constants';
import { handleError, handleErrorWithServerMessage } from '../../../data/errorHandler';
import { ESignatureClient } from '../../../data/eSignatureClient';
import { KbaIdentityPayload, KbaSession } from '../../../data/rsTypes';
import { SignerPageRoutePattern } from '../../../routes';
import { t } from '../../../util';
import { deleteCookie, KBA_KEY, setCookie } from '../../../util/cookieUtils';
import { AuthenticationFailedModal } from '../AuthenticationFailedModal';
import { PasscodeModalStyled } from '../Passcode/PasscodeModal.styled';
import { KbaIdentification } from './KbaIdentification';
import { KbaIdentityVerificationAdvisoryModal } from './KbaIdentityVerificationAdvisoryModal';
import { KbaVerification } from './KbaVerification';

const { useBreakpoint } = Grid;
const { useToken } = theme;

const maskStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.green5} , ${colorPalette.lavender4}, ${colorPalette.pink3})`,
};

const elemntFullWidth: React.CSSProperties = {
	width: '100%',
};

const greetingsStyles: React.CSSProperties = {
	fontSize: 20,
	fontWeight: 600,
};

const alertStyles: React.CSSProperties = {
	...elemntFullWidth,
	marginBottom: sizes.MS,
	marginTop: -sizes.XS,
};

const modalStyles = {
	mask: maskStyle,
};

const advisoryModalStyles: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	marginTop: sizes.SM,
};

const MaxAttempts = 3;

type Props = {
	kbaLocked: boolean;
	signerData: any;
	identityToken: string;
	kbaSession: KbaSession;
	unlockDocument: () => void;
};

export const KbaModal = (props: Props) => {
	const { token } = useToken();
	const screens = useBreakpoint();
	const ref = useRef<HTMLDivElement>(null);
	const [kbaStep, setKbaStep] = useState({
		action: props.kbaSession?.action,
		attempts: props.kbaSession?.attempts || 0,
	});
	const [authFailed, setAuthFailed] = React.useState(
		props.kbaLocked || props.kbaSession?.locked
	);
	const [kbaIdentityPayload, setKbaIdentityPayload] =
		React.useState<KbaIdentityPayload>();
	const [kbaIdentityData, setKbaIdentityData] = React.useState<KbaSession>();
	const [kbaSession, setKbaSession] = React.useState<KbaSession>(props.kbaSession);
	const searchParams = useSearchParams(location.search); // This will have identity_token, access_token and kba search params
	const signerId = SignerPageRoutePattern.match(location.pathname)?.signerId;

	const stepSProgress: React.CSSProperties = {
		marginBottom: sizes.MD,
		width: screens.xs ? '100%' : '60%',
	};

	const fetchKbaIdentity = async () => {
		try {
			deleteCookie(KBA_KEY);
			const kbaSessionResult = await ESignatureClient.generateKbaSession(
				signerId,
				searchParams
			);
			setCookie(KBA_KEY, kbaSessionResult.session, new Date(kbaSessionResult.expiration));
			setKbaSession(kbaSessionResult);
			return commonKbaIdentityfn(kbaIdentityPayload, kbaSessionResult.session);
		} catch (error) {
			handleErrorWithServerMessage(error);
		}
	};

	const checkKbaIdentity = (payload: KbaIdentityPayload) => {
		setKbaIdentityPayload(payload);
		return commonKbaIdentityfn(payload, kbaSession.session);
	};

	const commonKbaIdentityfn = async (payload: KbaIdentityPayload, session) => {
		try {
			let result = await ESignatureClient.checkKbaIdentity(session, payload);
			if (result?.success) {
				setKbaIdentityData(result);
			} else {
				if (result?.attempts === 3) {
					setAuthFailed(true);
				}
				setKbaStep({ action: result?.action, attempts: result?.attempts });
			}
			return result;
		} catch (e) {
			const { status } = JSON.parse(e.message);

			if (status === 403) {
				setAuthFailed(true);
			} else {
				handleError();
			}
			return {} as KbaSession;
		}
	};

	useEffect(() => {
		if (ref.current && (kbaStep.attempts > 0 || kbaStep.action === KBAStates.Verify)) {
			ref.current.scrollIntoView();
			ref.current.scrollTop = 0;
		}
	}, [kbaStep]);

	if (authFailed) {
		return (
			<AuthenticationFailedModal
				data-testid="modal-kba-auth-failed"
				isModalOpen={true}
				contentHeader={t(
					'esign-pilet-ui:signerPage.modals.authenticationFailed.contentHeader'
				)}
				contentMessage={t(
					'esign-pilet-ui:signerPage.modals.authenticationFailed.contentMessage',
					{ br: <br /> }
				)}
				senderEmail={props.signerData.signer.sender_email}
			/>
		);
	}

	return (
		<PasscodeModalStyled
			open={true}
			token={token}
			centered={true}
			width={screens.xs ? '100%' : 720}
			closeIcon={null}
			styles={modalStyles}
			footer={null}
			data-testid="modal-kba"
			style={{
				padding: screens.xs ? `${sizes.XL} ${sizes.XXS} ${sizes.XXS}` : sizes.XL,
				height: screens.xs ? window.innerHeight : 'auto',
			}}
		>
			<Space
				wrap
				direction="vertical"
				style={{
					display: 'block',
					width: '100%',
					overflowY: 'scroll',
					overflowX: 'hidden',
					scrollbarWidth: 'none',
					WebkitOverflowScrolling: 'touch',
					height: screens.xs ? 'calc(100vh - 110px)' : '100%',
				}}
				ref={ref}
			>
				<Row justify="center" style={{ marginBottom: sizes.MD }}>
					<SharefileTextLogoIcon width={140} />
				</Row>
				<Row style={{ marginTop: sizes.base, marginBottom: sizes.XS }}>
					<Typography.Text
						style={{ paddingRight: sizes.XXS, ...greetingsStyles }}
						data-testid="modal-greetings"
					>
						{t('esign-pilet-ui:signerPage.modals.kba.welcomeMessage')}
					</Typography.Text>
				</Row>
				<Row style={{ marginBottom: sizes.MD }}>
					<Typography.Text style={{ fontSize: sizes.base }}>
						{t('esign-pilet-ui:signerPage.modals.kba.senderInformation', {
							senderEmail: props.signerData.signer.sender_email,
						})}
					</Typography.Text>
				</Row>
				<Row>
					{kbaStep.attempts > 0 ? (
						<Alert
							type="error"
							showIcon
							style={alertStyles}
							message={
								<Typography.Text>
									{t('esign-pilet-ui:signerPage.modals.kba.verificationFailed')}{' '}
									<strong>
										{t(
											kbaStep.attempts == 2
												? 'esign-pilet-ui:signerPage.modals.passcode.attemptsAmount'
												: 'esign-pilet-ui:signerPage.modals.passcode.attemptsAmountPlural',
											{
												attemptsAmount: MaxAttempts - kbaStep.attempts,
											}
										)}
									</strong>
								</Typography.Text>
							}
						/>
					) : null}
				</Row>
				<Row style={stepSProgress}>
					<Steps
						size="small"
						current={kbaStep.action === KBAStates.Identify ? 0 : 1}
						direction="horizontal"
						responsive={false}
						items={[
							{
								title: t('esign-pilet-ui:signerPage.modals.kba.personalInfo'),
							},
							{
								title: t('esign-pilet-ui:signerPage.modals.kba.securityQuestions'),
							},
						]}
					/>
				</Row>
				{kbaStep.action === KBAStates.Identify && (
					<KbaIdentification
						signerId={props.signerData.signer.id}
						signerName={props.signerData.signer.name}
						identityToken={props.identityToken}
						setKbaStep={setKbaStep}
						checkKbaIdentity={checkKbaIdentity}
					/>
				)}
				{kbaStep.action === KBAStates.Verify && (
					<KbaVerification
						signerId={props.signerData.signer.id}
						currentKbaSession={kbaSession.session}
						unlockDocument={props.unlockDocument}
						setKbaStep={setKbaStep}
						setAuthFailed={setAuthFailed}
						kbaIdentityData={kbaIdentityData}
						fetchKbaIdentity={fetchKbaIdentity}
					/>
				)}
				<Row style={advisoryModalStyles}>
					<KbaIdentityVerificationAdvisoryModal />
				</Row>
			</Space>
		</PasscodeModalStyled>
	);
};
