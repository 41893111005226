import React, { useEffect, useRef, useState } from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { css, Global } from '@emotion/react';
import { sizes } from '@sharefiledev/antd-config';
import { Button, infoMessageModal, notifyError } from '@sharefiledev/flow-web';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ButtonProps, Spin } from 'antd';
import { useShareFileUserContext } from '../../Contexts/ShareFileUserContext';
import { ESignatureClient } from '../../data/eSignatureClient';
import { DashboardSignButtonTypes } from '../../data/rsTypes';
import { FeatureFlag } from '../../FeatureFlags';
import { SignerPage } from '../../SignerPage';
import { t } from '../../util';

export interface DashboardSignButtonProps extends ButtonProps {
	signerId: string;
	buttonType: string;
	style?: React.CSSProperties;
}

export const DashboardSignButton: React.FC<DashboardSignButtonProps> = props => {
	const [isSignerPageDrawerOpen, setIsSignerPageDrawerOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const searchParams = useRef(new URLSearchParams());
	const hasTriggeredAuth = useRef(false);
	const { sfUser } = useShareFileUserContext();

	const {
		isError: sessionAuthenticateErrorFetched,
		isSuccess: sessionAuthenticateDataFetched,
		data: sessionAuthenticateData,
		refetch: refetchSessionAuthenticate,
	} = useQuery(
		['sessionAuthenticate', props.signerId],
		() => {
			return ESignatureClient.sessionAuthenticate(props.signerId);
		},
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry(_failureCount, error) {
				return !error;
			},
		}
	);

	const sendLinkMutation = useMutation(
		(signerId: string) => {
			return ESignatureClient.remindSigner(signerId);
		},
		{
			onSuccess: () => {
				infoMessageModal({
					title: t('sendSigningLink.sentLinkInfoBanner'),
					message: (
						<>
							<p>
								{t('sendSigningLink.signingLinkInfoMessage1', {
									email: <strong>{sfUser.Email}</strong>,
								})}
							</p>
							<p>{t('sendSigningLink.signingLinkInfoMessage2')}</p>
						</>
					),
					confirmButtonText: t('sendSigningLink.gotIt'),
				});
			},
			onError: () => {
				notifyError(t('genericError'));
			},
		}
	);

	const startSignFromDashboard = () => {
		if (searchParams.current.has('access_token')) {
			setIsSignerPageDrawerOpen(true);
			return;
		}

		if (sessionAuthenticateData) {
			searchParams.current.set('access_token', sessionAuthenticateData.access_token);
			setIsSignerPageDrawerOpen(true);
			return;
		}

		hasTriggeredAuth.current = true;
		setIsLoading(true);
		refetchSessionAuthenticate();
	};

	const handleSigning = () => {
		if (getFeatureFlagValue(FeatureFlag.EnableSignFromDashboard)) {
			startSignFromDashboard();
		} else {
			sendLinkMutation.mutate(props.signerId);
		}
	};

	useEffect(() => {
		if (
			hasTriggeredAuth.current &&
			(sessionAuthenticateDataFetched || sessionAuthenticateErrorFetched)
		) {
			hasTriggeredAuth.current = false;
			setIsLoading(false);

			if (sessionAuthenticateErrorFetched) {
				notifyError(t('genericError'));
				return;
			}

			searchParams.current.set('access_token', sessionAuthenticateData.access_token);
			setIsSignerPageDrawerOpen(true);
		}
	}, [
		sessionAuthenticateData,
		sessionAuthenticateErrorFetched,
		sessionAuthenticateDataFetched,
	]);

	return (
		<>
			<Global
				styles={css`
					.dashboard-sign-btn-secondary > div {
						line-height: ${sizes.XS} !important;
					}
				`}
			/>
			{isSignerPageDrawerOpen ? (
				<SignerPage
					isSignedInUserFlow={true}
					signedInUserId={props.signerId}
					signedInUserSearchParams={searchParams.current}
					isSignerPageDrawerOpen={isSignerPageDrawerOpen}
					setIsSignerPageDrawerOpen={setIsSignerPageDrawerOpen}
				/>
			) : isLoading ? (
				<Spin size="large" data-testid="page-spinner" style={{ width: sizes.XL }} />
			) : (
				<>
					{props.buttonType === DashboardSignButtonTypes.BabyPrimary ? (
						<Button.BabyPrimary
							onClick={handleSigning}
							style={props.style}
							data-testid={`dashboard-sign-button-baby-primary`}
						>
							{t('signButtonLabel')}
						</Button.BabyPrimary>
					) : (
						<Button.Secondary
							onClick={handleSigning}
							style={props.style}
							className="dashboard-sign-btn-secondary"
							data-testid={`dashboard-sign-button-secondary`}
						>
							{t('signButtonLabel')}
						</Button.Secondary>
					)}
				</>
			)}
		</>
	);
};
