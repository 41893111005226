import React from 'react';
import { ConfigProvider, Drawer, Layout } from 'antd';

export const SignerPageLayout = ({ children, signerPageDrawerOpen = true }) => {
	return (
		<ConfigProvider
			theme={{
				components: {
					Layout: {
						/* here is your component tokens */
						headerHeight: 64,
						headerBg: '#fff',
					},
				},
			}}
		>
			<Drawer
				closeIcon={false}
				width={'100vw'}
				open={signerPageDrawerOpen}
				placement="right"
				push={false}
				styles={{ body: { padding: 0 }, content: { height: 'unset' } }}
				data-testid="signer-page-drawer"
			>
				<Layout>{children}</Layout>
			</Drawer>
		</ConfigProvider>
	);
};
