import React, { useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { ChevronDown } from '@sharefiledev/icons';
import { Alert, Button, Col, ConfigProvider, Row, Space, theme, Typography } from 'antd';
import { EidasCertificateType } from '../../data/rsTypes';
import { t } from '../../util';
import { StyledSelectWrapper } from './DigiCertCredentialsModal.styled';
import { StyledModal } from './SuccessModal.styled';

const { useToken } = theme;

const commonStyles: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
};

const textStyles: React.CSSProperties = {
	lineHeight: sizes.MS,
	fontWeight: 400,
	fontSize: sizes.base,
};

interface Props {
	providerId: string;
	identityToken: string;
	isModalOpen: boolean;
	credentialOptions: Array<{ label: string; value: string }>;
	certificateType: EidasCertificateType;
	authorizeAndSign: (credentialId: string) => void;
	onClose: () => void;
}

export function DigiCertCredentialsModal(props: Props) {
	const { token } = useToken();
	const [selectedCertificate, setSelectedCertificate] = useState<string>('');
	const [isCertButtonDisabled, setIsCertButtonDisabled] = useState(true);
	const [open, setOpen] = useState(false);

	React.useEffect(() => {
		setOpen(props.isModalOpen);
	}, [props.isModalOpen]);

	const selectSertificate = (value: any) => {
		setSelectedCertificate(value);
		setIsCertButtonDisabled(false);
	};

	const authorizeAndSign = () => {
		props.authorizeAndSign(selectedCertificate);
	};

	return (
		<>
			<StyledModal
				open={open}
				centered
				title={t('esign-pilet-ui:signerPage.modals.digiCert.credentials.title')}
				styles={{ body: { scrollbarWidth: 'none', overflow: 'auto' } }}
				width={576}
				footer={null}
				token={token}
				onCancel={props.onClose}
				destroyOnClose={true}
				data-testid="credentials-modal"
			>
				<Space wrap direction="vertical" size={12} style={{ width: '100%' }}>
					<Row style={{ ...commonStyles, marginTop: sizes.SM, marginBottom: sizes.SM }}>
						<Alert
							description={t(
								'esign-pilet-ui:signerPage.modals.digiCert.credentials.infoMessage',
								{
									mobileAppName: 'Go>Sign',
								}
							)}
							type="info"
							showIcon
						/>
					</Row>

					<Row style={{ marginBottom: sizes.SM }}>
						<Typography.Text style={textStyles}>
							<p>
								{t(
									'esign-pilet-ui:signerPage.modals.digiCert.credentials.certificate.sender'
								)}
								&nbsp;
								<b>
									{t(
										'esign-pilet-ui:signerPage.modals.digiCert.credentials.certificate.signature',
										{
											certificateType: props.certificateType,
										}
									)}
								</b>
								&nbsp;
								{t(
									'esign-pilet-ui:signerPage.modals.digiCert.credentials.certificate.select'
								)}
								&nbsp;
								<b>
									{t(
										'esign-pilet-ui:signerPage.modals.digiCert.credentials.certificate.certificate',
										{
											certificateType: props.certificateType,
										}
									)}
								</b>
							</p>
						</Typography.Text>
					</Row>

					<Row style={{ marginBottom: sizes.SM }}>
						<ConfigProvider
							renderEmpty={() =>
								props.credentialOptions.length > 0
									? t(
											'esign-pilet-ui:signerPage.modals.digiCert.credentials.certificate.emptySearch'
									  )
									: t(
											'esign-pilet-ui:signerPage.modals.digiCert.credentials.certificate.noCredentials'
									  )
							}
						>
							<StyledSelectWrapper
								showSearch
								suffixIcon={<ChevronDown size={16} color={colorPalette.neutral13} />}
								placeholder={t(
									'esign-pilet-ui:signerPage.modals.digiCert.credentials.placeholder'
								)}
								onChange={selectSertificate}
								options={props.credentialOptions}
							/>
						</ConfigProvider>
					</Row>

					<Row gutter={[8, 8]}>
						<Col span={24}>
							<Button
								size="small"
								disabled={isCertButtonDisabled}
								type="primary"
								onClick={authorizeAndSign}
								data-testid="authorize-cert-button"
							>
								{t('esign-pilet-ui:signerPage.modals.digiCert.credentials.title')}
							</Button>
						</Col>
					</Row>
				</Space>
			</StyledModal>
		</>
	);
}
