import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Button, GlobalToken, Modal } from 'antd';

export const TermsModalStyled = styled(Modal)<{
	token: GlobalToken;
}>`
	@media (max-width: ${props => props.token.screenXS}px) {
		max-width: 100% !important;
		min-height: 100% !important;
		margin: 0 !important;
		top: 0 !important;
		padding: 0 !important;
	}

	.ant-modal-content {
		@media (max-width: ${props => props.token.screenXS}px) {
			min-height: 100vh;
			margin-bottom: ${sizes.MD};
			border-radius: 0;
		}
	}

	.ant-modal-footer {
		@media (max-width: ${props => props.token.screenXS}px) {
			width: 100%;
			text-align: center;
			position: fixed;
			bottom: 0;
			padding: ${sizes.XS} ${sizes.base};
			margin-left: -${sizes.base} !important;
			margin-bottom: 0 !important;
			border-top: 1px solid ${colorPalette.neutral2};
			background-color: ${colorPalette.neutral1};
		}
	}

	.ant-btn-primary.ant-btn-lg {
		@media (max-width: ${props => props.token.screenXS}px) {
			height: ${sizes.XL};
		}
	}
`;

export const LinkStyled = styled(Button)`
	border: 2px solid transparent;

	&:hover,
	&:focus {
		border: 2px solid ${colorPalette.lavender4} !important;
		border-radius: 0;
		box-shadow: 0px 0px 0px 2px #5641ea33;
	}
`;
