import React, { useMemo } from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { notifyError } from '@sharefiledev/flow-web';
import { useQuery } from '@tanstack/react-query';
import { Grid, Space, Spin } from 'antd';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { ComponentTypes } from '../Common/Components/types';
import { getEidasAuthorizationFlag } from '../Common/ComponentsRenderer/util';
import { getSignerToken, isUploadSignatureFeatureEnabled } from '../Common/helper';
import { useSearchParams } from '../Common/Hooks/useSearchParams';
import { redirectToRS } from '../Common/rsRedirection';
import { useAppStore } from '../Common/store';
import { handleErrorWithServerMessage } from '../data/errorHandler';
import { ESignatureClient } from '../data/eSignatureClient';
import {
	ComponentValue,
	RSTokenHeaderTypes,
	SignatureResponse,
	SignerStatus,
	SignerSubmissionResponse,
	SignerUnauthorizedError,
	SignerUnauthorizedStates,
} from '../data/rsTypes';
import { SignerPageRoutePattern } from '../routes';
import { t } from '../util';
import { deleteCookie, getCookie, KBA_KEY, setCookie } from '../util/cookieUtils';
import { transformSnakeCaseKeysToCamelCase } from '../util/objectUtil';
import { DigiCertSuccessModal } from './Modals/DigiCertSuccessModal';
import { KbaModal } from './Modals/KbaModal/KbaModal';
import { PasscodeModal } from './Modals/Passcode/PasscodeModal';
import { SuccessModal } from './Modals/SuccessModal';
import { TermsAndConditionsModal } from './Modals/TermsAndConditionsModal';
import { UnauthorizedModal } from './Modals/UnauthorizedModal';
import { PageContent } from './PageContent/PageContent';
import { PageHeader } from './PageHeader/PageHeader';
import { SignerPageLayout } from './SignerPageLayout';
import { SubmitResponseButton } from './SubmitResponseButton/SubmitResponseButton';

const DEFAULT_FONT_SIZE = 14;
const pageLoaderStyles: React.CSSProperties = {
	height: 'calc(100vh - 80px)',
	width: '100vw',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};
const { useBreakpoint } = Grid;

interface SignerPageProps {
	isSignedInUserFlow?: boolean;
	signedInUserId?: string | null;
	signedInUserSearchParams?: URLSearchParams | null;
	isSignerPageDrawerOpen?: boolean;
	setIsSignerPageDrawerOpen?: (isOpen: boolean) => void;
}

export const SignerPage: React.FC<SignerPageProps> = ({
	isSignedInUserFlow = false,
	signedInUserId = null,
	signedInUserSearchParams = null,
	isSignerPageDrawerOpen = true,
	setIsSignerPageDrawerOpen,
}) => {
	const [submitResponse, setSubmitResponse] =
		React.useState<SignerSubmissionResponse>(null);
	const screens = useBreakpoint();
	const location = useLocation();
	const history = useHistory();

	const signerId = isSignedInUserFlow
		? signedInUserId
		: SignerPageRoutePattern.match(location.pathname).signerId;
	const defaultSearchParams = useSearchParams(location.search);
	const searchParams = isSignedInUserFlow
		? signedInUserSearchParams
		: defaultSearchParams; // This will have identity_token, access_token and kba search params
	const signerToken = useMemo(() => getSignerToken(searchParams), [searchParams]);
	const linkHasAccessToken = signerToken.tokenType === RSTokenHeaderTypes.AccessToken;
	const isKbaProtected =
		!!searchParams.get('kba') && !linkHasAccessToken && !isSignedInUserFlow;

	const [isDocumentLocked, setIsDocumentLocked] = React.useState(false);
	const [isKbaLocked, setIsKbaLocked] = React.useState(isKbaProtected);
	const [unauthorizedState, setUnauthorizedState] =
		React.useState<SignerUnauthorizedStates>(null);
	const [senderEmail, setSenderEmail] = React.useState<string>(null);

	const parts = window.location.host.split('.');
	const subdomain = parts.length >= 3 ? parts[0] : '';

	const unlockDocument = answerDigest => {
		searchParams.append('passcode_answer_digest', answerDigest);
		if (!isSignedInUserFlow) {
			history.push({ search: searchParams.toString() });
		}
		setIsDocumentLocked(false);
		refetchSigner();
	};

	const {
		error: signerError,
		isError: errorSignerFetched,
		isLoading: fetchingSigner,
		isSuccess: signerRequestDataFetched,
		isRefetching: refetchingSigner,
		data: signerRequestData,
		refetch: refetchSigner,
	} = useQuery(
		['signer', signerId],
		() => {
			if (!isDocumentLocked) {
				searchParams.delete('passcode_answer_digest');
				history.push({ search: isSignedInUserFlow ? '' : searchParams.toString() });
			}

			return ESignatureClient.getSigner(signerId, searchParams, getCookie(KBA_KEY));
		},
		{
			refetchOnWindowFocus: false,
			retry(_failureCount, error) {
				return !error;
			},
		}
	);

	const baseFile = signerRequestData?.base_files?.[0];
	const account = signerRequestData?.accounts?.[0];

	// TODO: remove the code when the real eIDAS sender configuration will be ready
	const [isEIDASEnabled, setIsEIDASEnabled] = React.useState(false);

	const fetchKbaSession = async (signerId: string, searchParams: any) => {
		const kbaSessionId = getCookie(KBA_KEY);

		if (kbaSessionId) {
			try {
				const response = await ESignatureClient.getKbaSession(
					signerId,
					kbaSessionId,
					searchParams
				);
				// If the response is verify and there are no questions, then delete the cookie. This is to handle refresh when in verify state
				if (response?.action === 'verify' && !response?.questions?.length) {
					deleteCookie(KBA_KEY);
				}
				return response;
			} catch (error) {
				if (error.response.status === 403) {
					setIsKbaLocked(true);
				} else {
					handleErrorWithServerMessage(error);
				}
			}
		} else {
			return ESignatureClient.generateKbaSession(signerId, searchParams);
		}
	};
	const {
		data: kbaSession,
		isSuccess: kbaSessionFetched,
		error: kbaSessionFetchError,
	} = useQuery(
		['kbaSession', signerId, getCookie(KBA_KEY)],
		() => fetchKbaSession(signerId, searchParams),
		{
			refetchOnWindowFocus: false,
			enabled: isKbaLocked,
			retry(_failureCount, error) {
				return !error;
			},
		}
	);

	React.useEffect(() => {
		if (kbaSession?.isSender) {
			redirectToRS(false);
		}
	}, [kbaSession?.isSender]);

	// This will store the component values of components assigned_to the signer and other data
	const [
		setComponentValues,
		setSigner,
		setSignatures,
		setDocumentAttachments,
		setEsignDocument,
		setPersonalSettings,
		setBaseFiles,
		setSignerPayments,
		setSigners,
		setSearchParams,
		setIsSignedInUserFlow,
	] = useAppStore(state => [
		state.setComponentValues,
		state.setSigner,
		state.setSignatures,
		state.setDocumentAttachments,
		state.setEsignDocument,
		state.setPersonalSettings,
		state.setBaseFiles,
		state.setSignerPayments,
		state.setSigners,
		state.setSearchParams,
		state.setIsSignedInUserFlow,
	]);

	React.useEffect(() => {
		const handlePopState = () => {
			if (isSignedInUserFlow) {
				setIsSignerPageDrawerOpen(false);
				history.push('/signaturerequests/dashboard');
			}
		};

		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [history, isSignedInUserFlow, setIsSignerPageDrawerOpen]);

	React.useEffect(() => {
		if (errorSignerFetched) {
			if (signerError instanceof AxiosError) {
				if (signerError?.response?.data?.includes('Unauthorized. Requires passcode.')) {
					setIsDocumentLocked(true);
					return;
				}

				// 404 - the signer doesn't have access anymore or typed some random string instead of signerId
				// 401 - identity token is missing or wrong, but the document does not have special status (still pending to sign)
				if (signerError.response.status === 404 || signerError.response.status === 401) {
					setUnauthorizedState(SignerUnauthorizedStates.Invalid);
					return;
				}

				if (
					signerError?.response?.status === 308 &&
					signerError?.response?.data[0]?.includes('This document requires kba')
				) {
					if (isSignedInUserFlow) {
						searchParams.append('kba', 'true');
						redirectToRS(false, searchParams, `/signers/${signerId}/sign`);
					}
					return;
				}

				try {
					const errorResponse: SignerUnauthorizedError = JSON.parse(
						signerError?.response?.data[0]
					);

					const regex = new RegExp(Object.values(SignerUnauthorizedStates).join('|'));
					const match = errorResponse.message.match(regex);

					if (match) {
						setUnauthorizedState(match[0] as SignerUnauthorizedStates);
						setSenderEmail(errorResponse.sender_email);
					}
				} catch {
					return;
				}
			}
		}

		// TODO: remove the code when the real eIDAS sender configuration will be ready
		if (getEidasAuthorizationFlag()) {
			setIsEIDASEnabled(true);
		}

		if (signerRequestDataFetched && signerRequestData.documents) {
			const esignDocument = signerRequestData.documents[0];
			const documentComponents = esignDocument.components || [];
			const documentComponentValues = signerRequestData.component_values || [];
			const signer = signerRequestData.signer;
			let componentValues: ComponentValue[] = [];
			setSigner(signer);
			setSigners(signerRequestData.signers);
			setSearchParams(searchParams);
			setIsSignedInUserFlow(isSignedInUserFlow);
			setEsignDocument(esignDocument);
			setBaseFiles(signerRequestData.base_files[0]);
			setIsDocumentLocked(false);
			setPersonalSettings({
				allowTypedSignature: esignDocument.sender_allow_typed_signature,
				allowDrawnSignature: esignDocument.sender_allow_drawn_signature,
				allowUploadedSignature:
					esignDocument.sender_allow_uploaded_signature &&
					isUploadSignatureFeatureEnabled(signerRequestData.accounts[0]['created_at']),
			});
			setSignerPayments(signerRequestData.signer_payments);
			if (signerRequestData.attachments) {
				setDocumentAttachments(signerRequestData.attachments);
			}

			const fetchAllSignatures = async (
				signaturePromises: Promise<SignatureResponse>[]
			) => {
				try {
					const response = await Promise.all(signaturePromises);
					const signatures = response.map((res: any) =>
						transformSnakeCaseKeysToCamelCase(res.signature)
					);
					setSignatures(signatures);
				} catch (error) {
					notifyError(t('esign-pilet-ui:signatureRequestErrorMessage'));
				}
			};

			const annotatedSignaturePromises: Promise<SignatureResponse>[] = [];
			const otherSignerSignaturesPromises: Promise<SignatureResponse>[] = [];

			// TODO: Check if the nested loops can be optimized
			documentComponents.forEach(component => {
				const componentValue = documentComponentValues.find(
					com_value => com_value.component_id === component.id
				);

				if (Boolean(component['signature_id'])) {
					annotatedSignaturePromises.push(
						ESignatureClient.getSignature(
							component['signature_id'],
							esignDocument.id,
							signerToken,
							signer.id
						)
					);
				}

				// TODO: Check if we need to check signer status here
				if (
					component.type === ComponentTypes.SignatureComponent &&
					esignDocument.signer_sequencing &&
					component.assigned_to !== signer.role_name &&
					componentValue &&
					componentValue.value
				) {
					otherSignerSignaturesPromises.push(
						ESignatureClient.getSignature(
							componentValue.value,
							esignDocument.id,
							signerToken,
							signer.id
						)
					);
				}

				// If the component value is not present in the component_values array, then add it with value as null
				if (!componentValue) {
					componentValues.push({
						component_id: component.id,
						document_id: signerRequestData.documents[0].id,
						id: `cv_${component.id}`,
						value: component.group_id ? 'false' : null, // fix for checkbox group initial value
						font_size: Number(component.font_size) || DEFAULT_FONT_SIZE,
					});
				} else {
					componentValues.push(componentValue);
				}
			});
			fetchAllSignatures([
				...annotatedSignaturePromises,
				...otherSignerSignaturesPromises,
			]);

			setComponentValues(componentValues);
		}
	}, [
		errorSignerFetched,
		signerError,
		setComponentValues,
		setSigner,
		setSigners,
		setSignatures,
		setDocumentAttachments,
		setEsignDocument,
		signerRequestData,
		signerRequestDataFetched,
		signerToken,
		setPersonalSettings,
		setBaseFiles,
		setSignerPayments,
		refetchSigner,
		searchParams,
		setSearchParams,
		isSignedInUserFlow,
		setIsSignedInUserFlow,
		signerId,
	]);

	React.useEffect(() => {
		if (kbaSessionFetchError) {
			if (kbaSessionFetchError instanceof AxiosError) {
				if (kbaSessionFetchError?.response.status === 403) {
					setIsKbaLocked(true);
				}
			}
		}

		if (kbaSessionFetched) {
			if (kbaSession.session && kbaSession.action !== 'index') {
				setCookie(KBA_KEY, kbaSession.session, new Date(kbaSession.expiration));
			} else {
				setIsKbaLocked(false);
			}
		}
	}, [kbaSession, kbaSessionFetchError, kbaSessionFetched]);

	const unlockKbaDocument = () => {
		setIsKbaLocked(false);
		refetchSigner();
	};

	const handleSubmitResponse = (submitResponse: SignerSubmissionResponse) => {
		if (submitResponse.signer.status === SignerStatus.PendingIdentityAuthentication) {
			refetchSigner();
		}
		setSubmitResponse(submitResponse);
	};

	if (fetchingSigner || refetchingSigner) {
		if (isSignedInUserFlow) {
			return <Spin size="large" data-testid="page-spinner" style={{ width: sizes.XL }} />;
		} else {
			return (
				<Space style={pageLoaderStyles}>
					<Spin size="large" data-testid="page-spinner" />
				</Space>
			);
		}
	}

	// TODO: Change true to signerRequestDataFetched condition when getSigner endpoint is updated
	if (!isDocumentLocked && isEIDASEnabled) {
		return (
			<SignerPageLayout>
				<DigiCertSuccessModal
					providerId="d6f787fd-b13a-46f3-b837-1c924a819fc4" // TODO: Replace with the real value from the sender settings
					subdomain={subdomain}
					documentId={signerRequestData ? signerRequestData.documents[0].id : null}
				/>
			</SignerPageLayout>
		);
	}

	if (
		!kbaSession?.isSender &&
		isKbaLocked &&
		signerRequestDataFetched &&
		(kbaSessionFetched || kbaSessionFetchError)
	) {
		return (
			<SignerPageLayout>
				<KbaModal
					kbaLocked={!!kbaSessionFetchError}
					identityToken={signerToken.token}
					kbaSession={kbaSession}
					signerData={signerRequestData}
					unlockDocument={unlockKbaDocument}
				/>
			</SignerPageLayout>
		);
	}

	if (errorSignerFetched && unauthorizedState) {
		return (
			<UnauthorizedModal
				unauthorizedState={unauthorizedState}
				senderEmail={senderEmail}
				signerId={signerId}
			/>
		);
	}

	if (submitResponse) {
		return (
			<SignerPageLayout>
				<SuccessModal
					signerEmail={signerRequestData.signer.email}
					documentState={submitResponse.document.document_state}
					documentId={submitResponse.document.id}
					token={submitResponse.signer.redirect_token}
				/>
			</SignerPageLayout>
		);
	}

	if (errorSignerFetched && isDocumentLocked) {
		return (
			<SignerPageLayout>
				<PasscodeModal
					signerId={signerId}
					searchParams={searchParams}
					setDocumentLock={setIsDocumentLocked}
					unlockDocument={unlockDocument}
				/>
			</SignerPageLayout>
		);
	}

	if (
		signerRequestDataFetched &&
		!isDocumentLocked &&
		!isKbaLocked &&
		baseFile &&
		account
	) {
		if (isKbaProtected) {
			redirectToRS(true);
		} else {
			return (
				<SignerPageLayout signerPageDrawerOpen={isSignerPageDrawerOpen}>
					<PageHeader
						baseFiles={baseFile}
						account={account}
						setSubmitResponse={handleSubmitResponse}
						setIsSignerPageDrawerOpen={setIsSignerPageDrawerOpen}
					/>
					<PageContent baseFiles={baseFile} />
					<TermsAndConditionsModal />
					{!screens.sm && (
						<SubmitResponseButton
							isMobile={true}
							setSubmitResponse={handleSubmitResponse}
						/>
					)}
				</SignerPageLayout>
			);
		}
	}

	return null;
};
