import styled from '@emotion/styled';
import { breakpoints, fromThemeColors, ThemeColor } from '@sharefiledev/flow-web';
import { GlobalToken } from 'antd';
import { Page } from 'react-pdf';

const defaultWidth = 1100;

export const PDFViewerContainer = styled.div<{ token: GlobalToken }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	width: 100%;
	align-items: center;
	position: relative;
	padding: 0 120px 0;
	@media (max-width: ${props => props.token.screenXS}px) {
		padding: 0 20px 0;
	}
	.viewer-container > .react-pdf__Document > div {
		overflow-x: hidden !important;
		/* Hides horizontal scrollbar */
		overflow-y: auto !important;
		/* Ensures vertical scrollbar is visible */
		-ms-overflow-style: auto !important;
		/* IE 10+ */
		scrollbar-width: none !important;
	}

	.viewer-container > .react-pdf__Document > div::-webkit-scrollbar:horizontal {
		display: none !important;
		/* Hides horizontal scrollbar in Webkit browsers */
	}
`;

export const DocumentContainer = styled.div<{}>`
	width: 100%;
	height: 100%;
	max-width: ${defaultWidth}px;
	transform-origin: top left;
	box-sizing: border-box;
	overflow: hidden;
`;

export const StyledPage = styled(Page)<{ selected: boolean; width: number }>(
	({ selected }) => `
	
	border-radius: 2px;
	margin-bottom: 10px;
	width: ${props => props.width}px;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;

	canvas {
		border: ${selected ? '2px solid #0045DB' : '1px solid #d9d9d9'};
		filter:  ${selected ? ' drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.24))' : 'none'};
		
	}
	`
);

export const DocumentNavbar = styled.div`
	display: flex;
	grid-area: 3 / 1 / 4 / 2;
	justify-content: space-between;
	background-color: ${fromThemeColors(ThemeColor.secondaryContainerBackground)};
	padding: 0 40px;

	${breakpoints.onMobile} {
		padding: 0 20px;
	}
	position: sticky;
	width: 100%;
	flex-direction: column;
	z-index: 999;
`;
