import React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { SharefileTextLogoIcon } from '@sharefiledev/flow-web';
import { DeclineDocument } from '@sharefiledev/icons';
import { Grid, Row, Space, theme, Typography } from 'antd';
import { t } from '../../../../util';
import { StyledModal } from './DeclineSuccessModal.styled';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const modalBodyStyles: React.CSSProperties = {
	overflow: 'auto',
	scrollbarWidth: 'none',
};

const headerStyles: React.CSSProperties = {
	fontWeight: 600,
	lineHeight: '28px',
	fontSize: '20px',
	textAlign: 'center',
};

const commonStyles: React.CSSProperties = {
	justifyContent: 'center',
	display: 'flex',
};

const textStyles: React.CSSProperties = {
	fontSize: sizes.base,
	textAlign: 'center',
	fontWeight: 400,
};

const maskStyle: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.orange4} , ${colorPalette.pink3}, ${colorPalette.lavender4})`,
};

export interface Props {
	isModalOpen: boolean;
	senderEmail: string;
}

export function DeclineSuccessModal(props: Props) {
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const { token } = useToken();
	const screens = useBreakpoint();

	React.useEffect(() => {
		setIsModalOpen(props.isModalOpen);
	}, [props.isModalOpen]);

	return (
		<StyledModal
			open={isModalOpen}
			closeIcon={null}
			centered={screens.sm}
			styles={{ body: modalBodyStyles, mask: maskStyle }}
			width={650}
			footer={null}
			data-testid="DeclineSuccessModal"
			token={token}
		>
			<Space wrap direction="vertical" size={12} style={{ width: '100%' }}>
				<Row style={{ ...commonStyles, marginTop: sizes.MD, marginBottom: sizes.SM }}>
					<SharefileTextLogoIcon width={140} />
				</Row>

				<Row style={{ ...commonStyles, marginTop: sizes.XS, marginBottom: sizes.XS }}>
					<DeclineDocument size={40} color={colorPalette.red6} />
				</Row>

				<Row style={{ ...commonStyles, marginTop: sizes.XXS }}>
					<Typography.Text style={headerStyles}>
						{t('esign-pilet-ui:signerPage.declineDocument.header')}
					</Typography.Text>
				</Row>

				<Row style={{ ...commonStyles, marginBottom: sizes.XS }}>
					<Typography.Text style={textStyles}>
						{t('esign-pilet-ui:signerPage.declineDocument.message')}
					</Typography.Text>
				</Row>

				<Row style={{ ...commonStyles, marginTop: sizes.XS, marginBottom: sizes.MD }}>
					<Typography.Text style={{ ...textStyles, fontWeight: 600 }}>
						{t('esign-pilet-ui:signerPage.declineDocument.sender')}:&nbsp;
						<Typography.Text style={textStyles}>{props.senderEmail}</Typography.Text>
					</Typography.Text>
				</Row>
			</Space>
		</StyledModal>
	);
}
