import React from 'react';
import { Layout } from 'antd';
import { BaseFiles } from '../../data/rsTypes';
import { DocumentViewContainer } from './DocumentViewContainer';
import { ProgressBarWrapper } from './ProgressBarWrapper';
import { SidePanel } from './SidePanel';

const { Content } = Layout;

const documentContentStyles: React.CSSProperties = {
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridTemplateRows: 'auto 1fr auto',
	gridColumnGap: '0px',
	gridRowGap: '0px',
	height: `calc(100vh - 64px)`, // 64px is the height of header
	width: '100%',
	boxSizing: 'border-box',
	overflow: 'hidden',
};

type SignerPageContentProps = {
	baseFiles: BaseFiles;
};

export const PageContent: React.FC<SignerPageContentProps> = props => {
	return (
		<Layout>
			<Content>
				<ProgressBarWrapper />
				<div style={documentContentStyles}>
					<DocumentViewContainer baseFiles={props.baseFiles} />
				</div>
			</Content>
			<SidePanel />
		</Layout>
	);
};
