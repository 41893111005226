import React, { useCallback } from 'react';
import { Button, MinusIcon, PlusIcon } from '@sharefiledev/flow-web';
import { SignerPageTabIndexes } from '../../../constants';
import { t } from '../../../util';
import {
	StyledIconButton,
	StyledText,
	ZoomControlsContainer,
} from './ZoomControls.styled';

export interface Props {
	zoomFactor: number;
	onZoomIn(): void;
	onZoomOut(): void;
}

export const ZoomControls = (props: Props) => {
	const [zoomFactor, setZoomFactor] = React.useState(1);

	const updateZoomFactor = useCallback(event => {
		if (event?.detail?.zoomFactor) {
			setZoomFactor(event.detail.zoomFactor);
		}
	}, []);

	React.useEffect(() => {
		window?.addEventListener('zoom', updateZoomFactor);

		return () => window?.removeEventListener('zoom', updateZoomFactor);
	}, [updateZoomFactor]);

	React.useEffect(() => {
		setZoomFactor(props.zoomFactor);
	}, [props.zoomFactor]);

	return (
		<ZoomControlsContainer>
			<StyledIconButton>
				<Button.IconOnly
					id="zoom-out-button"
					tabIndex={SignerPageTabIndexes.ZoomOut}
					icon={MinusIcon}
					size={12}
					data-testid="doc-zoom-out"
					title={t('zoomOut')}
					onClick={props.onZoomOut}
				/>
			</StyledIconButton>
			<StyledText>{`${Math.round(zoomFactor * 100)}%`}</StyledText>
			<StyledIconButton>
				<Button.IconOnly
					id="zoom-in-button"
					tabIndex={SignerPageTabIndexes.ZoomIn}
					icon={PlusIcon}
					size={12}
					data-testid="doc-zoom-in"
					title={t('zoomIn')}
					onClick={props.onZoomIn}
				/>
			</StyledIconButton>
		</ZoomControlsContainer>
	);
};
