import React from 'react';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Row,
	Select,
	theme,
	Typography,
} from 'antd';
import { countries } from 'countries-list';
import { DateFormatTypes } from '../../../constants';
import { ESignatureClient } from '../../../data/eSignatureClient';
import { KbaIdentityPayload, KbaSession } from '../../../data/rsTypes';
import { t } from '../../../util';

const { Text } = Typography;

type SelectOption = {
	value: string;
	label: string;
};

const DEFAULT_COUNTRY = 'US';

type Props = {
	signerId: string;
	signerName: string;
	identityToken: string;
	setKbaStep: ({ action, attempts }) => void;
	checkKbaIdentity: (payload: KbaIdentityPayload) => Promise<KbaSession>;
};

export const KbaIdentification = (props: Props) => {
	const [form] = Form.useForm();
	const { token } = theme.useToken();
	const [statesList, setStatesList] = React.useState<SelectOption[]>([]);

	const handleCountrySelect = React.useCallback((value: string) => {
		ESignatureClient.getStatesOptions(value).then(states => {
			setStatesList(
				Object.entries(states.states).map(
					([value, label]) =>
						({
							value,
							label,
						} as SelectOption)
				)
			);
		});
	}, []);

	React.useEffect(() => {
		handleCountrySelect(DEFAULT_COUNTRY);
	}, [handleCountrySelect]);

	const filterOptions = (input: string, option?: SelectOption) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleIdentityCheck = async value => {
		const [firstName = '', lastName = ''] = props.signerName?.trim().split(' ') || [];

		let payload: KbaIdentityPayload = {
			identityToken: props.identityToken,
			signerId: props.signerId,
			firstName: firstName,
			lastName: lastName,
			address: value.streetAddress,
			city: value.city,
			state: value.state,
			zip: value.zipcode,
			country: 'USA',
			ssnLast4: value.ssn,
			dobMonth: String(value['date-picker']?.$M + 1),
			dobYear: String(value['date-picker']?.$y),
			dobDay: String(value['date-picker']?.$D),
		};

		let result = await props.checkKbaIdentity(payload);

		if (result?.success) {
			props.setKbaStep({ action: result?.action, attempts: result?.attempts });
		}
	};

	return (
		<Form
			form={form}
			layout="vertical"
			name="kbaIdentification"
			onFinish={handleIdentityCheck}
			style={{ maxWidth: 720 }}
			scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
		>
			<Form.Item label={t('esign-pilet-ui:signerPage.modals.kba.signerName')}>
				<Text style={{ fontSize: token.fontSizeLG }}>{props.signerName}</Text>
			</Form.Item>
			<Row gutter={24}>
				<Col xs={24} sm={12}>
					<Form.Item
						name="date-picker"
						label={t('esign-pilet-ui:signerPage.modals.kba.birthday')}
						rules={[{ required: true }]}
					>
						<DatePicker
							format={DateFormatTypes.MMDDYYYY}
							placeholder={t('esign-pilet-ui:signerPage.modals.kba.selectDate', {
								dateFormat: DateFormatTypes.MMDDYYYY,
							})}
							style={{ width: '100%', height: '41px' }}
							data-testid="modal-kba-birthday"
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="ssn"
						label={t('esign-pilet-ui:signerPage.modals.kba.ssnLabel')}
						rules={[
							{ required: true },
							{
								pattern: /^\d*$/,
								message: t('esign-pilet-ui:signerPage.modals.kba.invalidSSN'),
							},
						]}
					>
						<Input
							data-testid="modal-kba-ssn"
							placeholder={t('esign-pilet-ui:signerPage.modals.kba.ssnLabel')}
							maxLength={4}
							inputMode="numeric"
						></Input>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Form.Item
						name="streetAddress"
						label={t('esign-pilet-ui:signerPage.modals.kba.streetAddress')}
						help={t('esign-pilet-ui:signerPage.modals.kba.streetAddressHint')}
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input
							data-testid="modal-kba-streetAddress"
							placeholder={t('esign-pilet-ui:signerPage.modals.kba.streetAddress')}
						></Input>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col xs={24} sm={12}>
					<Form.Item
						name="zipcode"
						label={t('esign-pilet-ui:signerPage.modals.kba.zipcodeLabel')}
						rules={[
							{ required: true },
							{
								pattern: /^\d*$/,
								message: t('esign-pilet-ui:signerPage.modals.kba.invalidZipcode'),
							},
						]}
					>
						<Input
							data-testid="modal-kba-zipcode"
							placeholder={t('esign-pilet-ui:signerPage.modals.kba.zipcodeLabel')}
							maxLength={5}
							inputMode="numeric"
						></Input>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="city"
						label={t('esign-pilet-ui:signerPage.modals.kba.cityLabel')}
						rules={[{ required: true }]}
					>
						<Input
							data-testid="modal-kba-city"
							placeholder={t('esign-pilet-ui:signerPage.modals.kba.cityLabel')}
						></Input>
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Form.Item
						name="state"
						label={t('esign-pilet-ui:signerPage.modals.kba.stateLabel')}
						rules={[{ required: true }]}
					>
						<Select
							showSearch
							data-testid="modal-kba-state"
							options={statesList}
							filterOption={filterOptions}
							placeholder={t('esign-pilet-ui:signerPage.modals.kba.statePlaceholder')}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Form.Item label={t('esign-pilet-ui:signerPage.modals.kba.countyLabel')}>
						<Text style={{ fontSize: token.fontSizeLG }}>{countries.US.name}</Text>
					</Form.Item>
				</Col>
			</Row>

			<Button
				key="submit"
				type="primary"
				data-testid="modal-button-next"
				htmlType="submit"
			>
				{t('esign-pilet-ui:signerPage.modals.kba.btnNextStep')}
			</Button>
		</Form>
	);
};
