import React, { useEffect, useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Signature } from '@sharefiledev/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Button,
	Col,
	notification,
	Row,
	Space,
	Spin,
	Steps,
	theme,
	Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { getBase64EncodedPdf } from '../../Common/ComponentsRenderer/util';
import { useSearchParams } from '../../Common/Hooks/useSearchParams';
import { ESignatureClient } from '../../data/eSignatureClient';
import { EidasCertificateType } from '../../data/rsTypes';
import { SignerPageRoutePattern } from '../../routes';
import { t } from '../../util';
import { DigiCertCredentialsModal } from './DigiCertCredentialsModal';
import { StyledModal } from './SuccessModal.styled';

const { useToken } = theme;

const mask: React.CSSProperties = {
	backgroundImage: `linear-gradient(to top, ${colorPalette.green5}, ${colorPalette.lavender4}, ${colorPalette.pink3})`,
};

const loaderStyles: React.CSSProperties = {
	display: 'flex',
	alignItems: 'center',
	width: '100vw',
	justifyContent: 'center',
	height: '100vh',
};

const headerTextStyles: React.CSSProperties = {
	fontWeight: 600,
	fontSize: '20px',
	textAlign: 'center',
	lineHeight: '28px',
};

const commonStyles: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
};

const commonTextStyles: React.CSSProperties = {
	fontWeight: 400,
	fontSize: sizes.base,
	lineHeight: sizes.MS,
	textAlign: 'center',
};

enum ErrorType {
	BadRequest,
	Unauthorized,
	GatewayTimeout,
}

interface Props {
	providerId: string;
	subdomain: string;
	documentId: string;
}

const showErrorNotification = (type: ErrorType) => {
	notification.error({
		message:
			type === ErrorType.BadRequest
				? t('esign-pilet-ui:signerPage.modals.digiCert.failedRequest.badRequest')
				: type === ErrorType.Unauthorized
				? t('esign-pilet-ui:signerPage.modals.digiCert.failedRequest.unauthorized')
				: t('esign-pilet-ui:signerPage.modals.digiCert.failedRequest.timeout'),
		description:
			type === ErrorType.Unauthorized
				? t('esign-pilet-ui:signerPage.modals.digiCert.failedRequest.unauthorizedMessage')
				: t('esign-pilet-ui:signerPage.modals.digiCert.failedRequest.message'),
		placement: 'topRight',
	});
};

export function DigiCertSuccessModal(props: Props) {
	const { token } = useToken();
	const history = useHistory();
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [credentialOptions, setCredentialOptions] = useState<
		Array<{ label: string; value: string }>
	>([]);
	const [isCredsModalOpened, setIsCredsModalOpened] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [documentHash, setDocumentHash] = useState<string>('');

	const searchParams = useSearchParams(location.search);
	const identityToken = searchParams.get('identity_token') ?? '';
	const eidasAuthorized = searchParams.get('eidas_authorized');

	const {
		error: documentError,
		isError: isError,
		isLoading: fetchingDocument,
		isSuccess: isSuccess,
		data: documentData,
	} = useQuery(
		['getDocument', props.documentId],
		() => ESignatureClient.getDocument(props.documentId),
		{
			staleTime: 30 * 1000,
			refetchOnWindowFocus: false,
			enabled: isAuthorized,
		}
	);

	useEffect(() => {
		if (isAuthorized) {
			function getDocumentHash() {
				if (isSuccess && documentData) {
					getBase64EncodedPdf(documentData.document.signed_pdf_url)
						.then(hash => setDocumentHash(hash))
						.catch(() => {
							showErrorNotification(ErrorType.BadRequest);
						});
				} else if (isError) {
					if (documentError instanceof AxiosError) {
						if (
							documentError.response?.status === 401 ||
							documentError.response?.status === 404
						) {
							setDocumentHash('VGVzdCB0ZXh0IHRvIHNpZ24u'); // TODO: Remove that once the getDocument endpoint is updated

							showErrorNotification(ErrorType.BadRequest);
						}
					}
				}
			}

			getDocumentHash();
		}

		if (Boolean(eidasAuthorized)) {
			setIsAuthorized(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentData, documentError, isError, isSuccess]);

	const redirectToAuthStep = () => {
		history.replace({
			search: `?identity_token=${identityToken}`,
		});
		setIsCredsModalOpened(false);
		setIsAuthorized(false);
	};

	const authorize = async () => {
		const signerId = SignerPageRoutePattern.match(location.pathname).signerId;
		await ESignatureClient.authorizeWithDigiCert(
			props.providerId,
			signerId,
			identityToken,
			props.subdomain
		)
			.then(url => {
				window.open(url, '_self');
			})
			.catch(error => {
				if (error === 401) {
					redirectToAuthStep();
					showErrorNotification(ErrorType.Unauthorized);
				} else {
					showErrorNotification(ErrorType.BadRequest);
				}
			});
	};

	const authorizeCredential = (certificateId: string): Promise<string> => {
		return ESignatureClient.authorizeCredential(
			props.providerId,
			identityToken,
			certificateId,
			documentHash
		)
			.then(sad => {
				return sad;
			})
			.catch(error => {
				if (error === 504) {
					showErrorNotification(ErrorType.GatewayTimeout);
				} else {
					showErrorNotification(ErrorType.BadRequest);
				}

				return Promise.reject();
			});
	};

	const getSignature = (certificateId: string, sad: string): Promise<string> => {
		return ESignatureClient.getEidasSignature(
			props.providerId,
			identityToken,
			certificateId,
			documentHash,
			sad
		)
			.then(signature => {
				return signature;
			})
			.catch(error => {
				if (error === 504) {
					showErrorNotification(ErrorType.GatewayTimeout);
				} else if (error === 401) {
					redirectToAuthStep();
					showErrorNotification(ErrorType.Unauthorized);
				} else {
					showErrorNotification(ErrorType.BadRequest);
				}

				return Promise.reject();
			});
	};

	const authorizeAndSign = (certificateId: string) => {
		setShowSpinner(true);
		setIsCredsModalOpened(false);

		authorizeCredential(certificateId)
			.then(sad => {
				getSignature(certificateId, sad)
					.then(() => {
						setShowSpinner(false);
					})
					.catch(() => {
						setShowSpinner(false);
					});
			})
			.catch(() => {
				setShowSpinner(false);
			});
	};

	const openCredentialsModal = () => {
		ESignatureClient.getDigiCertCredentials(props.providerId, identityToken)
			.then(creds => {
				setCredentialOptions(
					creds.map(item => ({
						label: item,
						value: item,
					}))
				);

				setIsCredsModalOpened(true);
			})
			.catch(error => {
				if (error === 401) {
					redirectToAuthStep();
					showErrorNotification(ErrorType.Unauthorized);
				} else {
					showErrorNotification(ErrorType.BadRequest);
				}
			});
	};

	if (isAuthorized && fetchingDocument) {
		return (
			<Space style={loaderStyles}>
				<Spin size="large" data-testid="page-spinner" />
			</Space>
		);
	}

	return (
		<>
			<StyledModal
				open={true}
				closeIcon={null}
				centered
				styles={{ body: { scrollbarWidth: 'none', overflow: 'auto' }, mask: mask }}
				width={576}
				footer={null}
				token={token}
			>
				<Space wrap direction="vertical" size={12} style={{ width: '100%' }}>
					<Row style={{ ...commonStyles, marginBottom: sizes.MD }}>
						<Signature color={colorPalette.lavender6} size={40} />
					</Row>

					<Row style={commonStyles}>
						<Typography.Text style={headerTextStyles}>
							{t('esign-pilet-ui:signerPage.modals.digiCert.title')}
						</Typography.Text>
					</Row>

					<Row style={{ ...commonStyles, marginBottom: sizes.SM }}>
						<Typography.Text style={commonTextStyles}>
							{t('esign-pilet-ui:signerPage.modals.digiCert.message')}
						</Typography.Text>
					</Row>

					{showSpinner ? (
						<>
							<Row style={{ ...commonStyles, marginTop: sizes.XL }}>
								<Spin />
							</Row>

							<Row style={{ marginBottom: sizes.XL }}>
								<Typography.Text style={commonTextStyles}>
									<p>
										{t('esign-pilet-ui:signerPage.modals.digiCert.2FAMessage.open')}&nbsp;
										<b>
											{t(
												'esign-pilet-ui:signerPage.modals.digiCert.2FAMessage.mobileApp',
												{
													mobileAppName: 'Go>Sign',
												}
											)}
										</b>
										&nbsp;
										{t('esign-pilet-ui:signerPage.modals.digiCert.2FAMessage.message')}
									</p>
								</Typography.Text>
							</Row>
						</>
					) : (
						<>
							<Row style={{ marginBottom: sizes.SM }}>
								<Typography.Text style={{ ...commonTextStyles, fontWeight: 600 }}>
									{t('esign-pilet-ui:signerPage.modals.digiCert.steps.title')}
								</Typography.Text>
							</Row>

							<Steps
								direction="vertical"
								current={isAuthorized ? 1 : 0}
								size="small"
								items={[
									{
										title: t(
											'esign-pilet-ui:signerPage.modals.digiCert.steps.firstStep.title'
										),
										description: t(
											'esign-pilet-ui:signerPage.modals.digiCert.steps.firstStep.description'
										),
									},
									{
										title: t(
											'esign-pilet-ui:signerPage.modals.digiCert.steps.secondStep.title'
										),
										description: t(
											'esign-pilet-ui:signerPage.modals.digiCert.steps.secondStep.description'
										),
									},
								]}
							/>

							<Row style={commonStyles} gutter={[8, 8]}>
								<Col span={24}>
									<Button
										type="primary"
										style={{ width: '100%' }}
										onClick={isAuthorized ? openCredentialsModal : authorize}
										data-testid="authorize-button"
									>
										{!isAuthorized
											? t('esign-pilet-ui:signerPage.modals.digiCert.signIn')
											: t(
													'esign-pilet-ui:signerPage.modals.digiCert.completeAuthorization'
											  )}
									</Button>
								</Col>
							</Row>
						</>
					)}
				</Space>
			</StyledModal>

			<DigiCertCredentialsModal
				providerId={props.providerId}
				identityToken={identityToken}
				credentialOptions={credentialOptions}
				isModalOpen={isCredsModalOpened}
				certificateType={EidasCertificateType.Aes} // TODO: Replace with the real settings from Sender
				authorizeAndSign={authorizeAndSign}
				onClose={() => setIsCredsModalOpened(false)}
			/>
		</>
	);
}
