import React from 'react';
import { Component } from '../../data/rsTypes';
import { useAppStore } from '../store';
import { FlattenedComponent } from './SignerComponents/FlattenedComponents/FlattenedComponent';

export interface FlattenComponentsProps {
	components: Component[];
	pageBoundingBox: DOMRect;
	signatureColor: string;
}

export function FlattenedComponentsRenderer(props: FlattenComponentsProps) {
	const [componentValues] = useAppStore(store => [store.componentValues]);

	const components = props.components.map(component => {
		const isAnnotation = component.type.includes('Annotation');
		return (
			(componentValues.get(component.id).value || isAnnotation) && (
				<FlattenedComponent
					key={component.id}
					component={component}
					componentValue={componentValues.get(component.id)}
					pageBoundingBox={props.pageBoundingBox}
					signatureColor={props.signatureColor}
				/>
			)
		);
	});

	return <>{components}</>;
}
