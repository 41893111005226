import React from 'react';
import { Component, ComponentValue } from '../../../../data/rsTypes';
import {
	defaultCheckBoxAnnotationIconSize,
	defaultComponentDimensions,
	defaultWidth,
} from '../../../Components/componentUtils';
import { ComponentTypes } from '../../../Components/types';
import { useAppStore } from '../../../store';
import { SignerCheckboxAnnotationComponent } from '../Annotations/SignerCheckboxAnnotationComponent';
import SignerDateAnnotationComponent from '../Annotations/SignerDateAnnotationComponent';
import SignerSignatureAnnotation from '../Annotations/SignerSignatureAnnotation/SignerSignatureAnnotationComponent';
import SignerTextAnnotationComponent from '../Annotations/SignerTextAnnotationComponent';
import { StyledComponentContentWrapper } from './FlattenedComponent.styled';

interface FlattenedComponentProps {
	component: Component;
	componentValue: ComponentValue;
	pageBoundingBox: DOMRect;
	signatureColor: string;
}

function _FlattenedComponent(props: FlattenedComponentProps) {
	const signatures = useAppStore(store => store.signatures);

	const renderFlattenedComponent = () => {
		const scaleValue = props.pageBoundingBox.width / defaultWidth;
		const scale = scaleValue >= 1 ? 1 : scaleValue;
		const isAnnotation = props.component.type.includes('Annotation');
		const signatureId = isAnnotation
			? props.component.signature_id
			: props.componentValue.value;
		const filteredSignature = signatures?.find(signature => signature.id === signatureId);

		switch (props.component.type) {
			case ComponentTypes.TextComponent:
			case ComponentTypes.TextAnnotationComponent:
			case ComponentTypes.SelectComponent:
			case ComponentTypes.InitialsComponent:
				return (
					<SignerTextAnnotationComponent
						value={isAnnotation ? props.component.value : props.componentValue.value}
						fontSize={props.component.font_size}
						scale={scale}
					/>
				);
			case ComponentTypes.DateComponent:
				return (
					<SignerDateAnnotationComponent
						value={props.componentValue.value}
						fontSize={props.component.font_size}
						scale={scale}
						dateFormat={props.component.date_format}
					/>
				);
			case ComponentTypes.CheckComponent:
			case ComponentTypes.CheckGroupComponent:
			case ComponentTypes.CheckmarkAnnotationComponent:
				let checkboxSize =
					(props.component.width *
						props.pageBoundingBox.width *
						defaultCheckBoxAnnotationIconSize) /
					defaultComponentDimensions.CheckmarkAnnotationComponent.width;
				return (
					(isAnnotation || props.componentValue.value === 'true') && (
						<SignerCheckboxAnnotationComponent size={checkboxSize} />
					)
				);

			case ComponentTypes.SignatureComponent:
			case ComponentTypes.SignatureAnnotationComponent:
				return (
					<SignerSignatureAnnotation
						savedSignature={filteredSignature}
						signatureColor={props.signatureColor}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<StyledComponentContentWrapper
			key={props.component.id}
			id={`FlattenedComponent-${props.component.id}`}
			data-testid={`FlattenedComponent-${props.component.id}`}
			left={props.component.x * props.pageBoundingBox.width}
			top={props.component.y * props.pageBoundingBox.height}
			width={props.component.width * props.pageBoundingBox.width}
			height={props.component.height * props.pageBoundingBox.height}
			transparent={props.component.transparent}
		>
			{renderFlattenedComponent()}
		</StyledComponentContentWrapper>
	);
}

export const FlattenedComponent = React.memo(_FlattenedComponent);
