import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { useMutation } from '@tanstack/react-query';
import { Button, Grid, notification, Space, theme } from 'antd';
import {
	formatComponentValues,
	groupComponents,
	isComponentValueValid,
} from '../../Common/ComponentsRenderer/util';
import { useAppStore } from '../../Common/store';
import { SignerPageTabIndexes } from '../../constants';
import { ESignatureClient } from '../../data/eSignatureClient';
import {
	SignerResponseStatus,
	SignerSubmissionRequest,
	SignerSubmissionResponse,
} from '../../data/rsTypes';
import { t } from '../../util';
import { StyledMobileWrapper, StyledSubmitModal } from './SubmitResponseButton.styled';

const { useBreakpoint } = Grid;
const { useToken } = theme;

interface Props {
	isMobile?: boolean;
	setSubmitResponse: (submitResponse: SignerSubmissionResponse) => void;
}

export const SubmitResponseButton = (props: Props) => {
	const [componentValues, signer, esignDocument, searchParams] = useAppStore(state => [
		state.componentValues,
		state.signer,
		state.esignDocument,
		state.searchParams,
	]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [unfilledOptionalFieldsCount, setUnfilledOptionalFieldsCount] =
		useState<number>(0);
	const [hasUnfilledRequiredFields, setHasUnfilledRequiredFields] =
		useState<boolean>(false);
	const screens = useBreakpoint();
	const { token } = useToken();

	React.useEffect(() => {
		let unfilledRequiredComponents = 0;
		let unfilledOptionalComponents = 0;
		const groupedComponents = groupComponents(esignDocument.components);
		groupedComponents.forEach(component => {
			if (component.assigned_to === signer.role_name) {
				const isValid = isComponentValueValid(componentValues, component);
				if (component.is_required && !isValid) {
					unfilledRequiredComponents++;
				} else if (!component.is_required && !isValid) {
					unfilledOptionalComponents++;
				}
			}
		});

		setHasUnfilledRequiredFields(unfilledRequiredComponents > 0);
		setUnfilledOptionalFieldsCount(unfilledOptionalComponents);
	}, [componentValues, esignDocument.components, signer.role_name]);

	const signerSubmitMutation = useMutation({
		mutationFn: () => {
			const payload: SignerSubmissionRequest = {
				signer: {
					name: signer.name,
					email: signer.email,
					response: {
						status: SignerResponseStatus.Submitted,
						component_values: formatComponentValues(
							esignDocument.components,
							signer,
							Array.from(componentValues.values())
						),
					},
				},
			};

			return ESignatureClient.submitSignerResponse(signer.id, searchParams, payload);
		},
		onSuccess: response => {
			notification.success({
				message: 'Success!',
				description: 'Successfully submitted your response',
			});
			props.setSubmitResponse(response);
		},
		onError: () => {
			notification.error({
				message: 'Error!',
				description: 'Failed to submit your response',
			});
		},
	});

	const handleSubmit = () => {
		signerSubmitMutation.mutateAsync();
		setIsModalOpen(false);
	};

	const onSubmit = () => {
		if (hasUnfilledRequiredFields) {
			notification.error({
				message: t(
					'esign-pilet-ui:signerPage.submitDocument.submitWithUnfilledRequiredFields'
				),
			});
			return;
		}
		setIsModalOpen(true);
	};

	return (
		<>
			{!props.isMobile ? (
				<Button
					id="submit-document-button"
					tabIndex={SignerPageTabIndexes.SubmitButton}
					style={{ marginLeft: 0 }}
					type="primary"
					onClick={onSubmit}
					data-testid="submit-button"
				>
					{t('esign-pilet-ui:signerPage.submitDocument.submit')}
				</Button>
			) : (
				!hasUnfilledRequiredFields && (
					<StyledMobileWrapper>
						<Button
							id="submit-document-button"
							tabIndex={SignerPageTabIndexes.SubmitButton}
							type="primary"
							onClick={onSubmit}
							style={{ width: '100%' }}
							data-testid="mobile-submit-button"
						>
							{t('esign-pilet-ui:signerPage.submitDocument.header')}
						</Button>
					</StyledMobileWrapper>
				)
			)}

			<StyledSubmitModal
				title={
					<Space>
						<ExclamationCircleOutlined
							style={{
								fontSize: sizes.MS,
								color: colorPalette.orange7,
							}}
						/>
						<Space>{t('esign-pilet-ui:signerPage.submitDocument.header')}</Space>
					</Space>
				}
				open={isModalOpen}
				onOk={handleSubmit}
				onCancel={() => setIsModalOpen(false)}
				width={400}
				centered={screens.sm}
				token={token}
				closable={false}
				footer={() => (
					<Space direction={'horizontal'} style={{ width: '100%', marginLeft: sizes.MD }}>
						<Button onClick={handleSubmit} shape="round" type="primary" size="small">
							{t('esign-pilet-ui:signerPage.submitDocument.submit')}
						</Button>
						<Button onClick={() => setIsModalOpen(false)} shape="round" size="small">
							Cancel
						</Button>
					</Space>
				)}
			>
				<Space
					direction={'vertical'}
					size="large"
					style={{ marginLeft: sizes.MD, marginBottom: sizes.SM }}
				>
					{unfilledOptionalFieldsCount > 0 ? (
						<>
							{t(
								'esign-pilet-ui:signerPage.submitDocument.submitWithUnfilledOptionalFields',
								{
									count: unfilledOptionalFieldsCount,
								}
							)}
							{t('esign-pilet-ui:signerPage.submitDocument.submitWarning')}
						</>
					) : (
						<>
							{t('esign-pilet-ui:signerPage.submitDocument.submitConfirm')}
							{t('esign-pilet-ui:signerPage.submitDocument.submitWarning')}
						</>
					)}
				</Space>
			</StyledSubmitModal>
		</>
	);
};
