import styled from '@emotion/styled';
import { sizes } from '@sharefiledev/antd-config';
import { TextEditingTyping } from '@sharefiledev/icons';
import { GlobalToken, Input } from 'antd';

export const StyledTextComponentWrapper = styled.div<{
	textAlign: string;
	borderColor: string;
	backgroundColor: string;
	multiline: boolean;
	isReadOnly?: boolean;
	isSelected?: boolean;
	token: GlobalToken;
}>`
	/* styles for multiline component */
	white-space: ${props => (props.multiline ? 'pre-line' : 'nowrap')};
	height: ${props => (props.multiline ? 'fit-content' : '100%')};
	word-break: ${props => (props.multiline ? 'break-word' : 'unset')};

	@media (max-width: ${props => props.token.screenXS}px) {
		align-content: baseline;
	}

	display: flex;
	align-items: center;
	align-self: baseline;
	width: 100%;
	min-height: 100%;
	border-radius: ${sizes.XXS};
	border-color: ${props => props.borderColor} !important;
	background-color: ${props => props.backgroundColor};

	&:hover {
		cursor: text;
		border: ${props =>
			!props.isReadOnly || (props.isReadOnly && props.isSelected)
				? `2px solid ${props.borderColor}`
				: 'unset'} !important;
	}

	text-align: ${props => props.textAlign};
	justify-content: ${props => props.textAlign};
`;

export const StyledPlaceholder = styled.div<{
	scale: number;
}>`
	max-width: calc(100% - ${sizes.MS});
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: normal;
	font-size: ${props => props.scale * 18}px;
`;

export const StyledTextEditingTyping = styled(TextEditingTyping)<{
	scale: number;
}>`
	font-size: ${props => props.scale * 24}px !important;
`;

export const StyledInputWrapper = styled(Input)<{
	textAlign: string;
	color: string;
	backgroundColor: string;
	borderColor: string;
	token: GlobalToken;
	scale: number;
}>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 100%;
	border-radius: ${sizes.XXS};
	border: 1px solid ${props => props.borderColor};
	background-color: ${props => props.backgroundColor};
	font-size: ${props => 14 * props.scale}px;
	color: ${props => props.color};
	text-align: ${props => props.textAlign};
	font-family: Courier, sans-serif;

	@media (max-width: ${props => props.token.screenLGMax}px) {
		padding: 0px ${props => (props.textAlign === 'right' ? sizes.XS : sizes.XXS)} 0px
			${sizes.XXS} !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		padding: 0px ${sizes.XXS} 0px ${sizes.XXS} !important;
	}

	&:hover,
	&:focus,
	&:active {
		border-color: ${props => props.borderColor} !important;
		box-shadow: none;
	}
`;

const { TextArea } = Input;
export const StyledTextAreaWrapper = styled(TextArea)<{
	textAlign: string;
	color: string;
	backgroundColor: string;
	borderColor: string;
	token: GlobalToken;
	scale: number;
}>`
	font-size: ${props => 14 * props.scale}px;

	align-self: baseline;
	line-height: 1.2 !important;
	resize: none;
	display: flex;
	width: 100%;
	min-height: 100% !important;
	border-radius: ${sizes.XXS};
	border: 1px solid ${props => props.borderColor};
	font-size: ${sizes.base};
	background-color: ${props => props.backgroundColor};
	overflow: hidden;
	color: ${props => props.color};

	&:hover,
	&:focus,
	&:active {
		border-color: ${props => props.borderColor} !important;
		box-shadow: none;
	}

	text-align: ${props => props.textAlign};
`;
