import React, { useRef, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { ChevronDown, DropdownMenu } from '@sharefiledev/icons';
import {
	Button,
	Divider,
	Drawer,
	Flex,
	Grid,
	List,
	Select,
	theme,
	Typography,
} from 'antd';
import { Component, ComponentValue } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { isComponentValid } from '../../util';
import {
	StyledClearButton,
	StyledDrawerButton,
	StyledListItem,
	StyledSelectWrapper,
	SyledCloseIcon,
	Wrapper,
} from './DropdownComponent.styled';

const { useBreakpoint } = Grid;
const { useToken } = theme;
const opacitySuffix = '1A';

interface Props {
	component: Component;
	componentValue: ComponentValue;
	options: Array<string>;
	onChange: (value: string) => void;
	selected: boolean;
	pageBoundingBox: DOMRect;
	scale: number;
	componentVisibility: boolean;
}

export const DropdownComponent = (props: Props) => {
	const screens = useBreakpoint();
	const { token } = useToken();
	const selectRef = useRef(null);
	const value = props.componentValue.value;
	const scale = props.scale;
	const isValid = isComponentValid(value, ComponentTypes.SelectComponent);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const dropIcon = <ChevronDown color={colorPalette.neutral13} size={24 * scale} />;
	const closeIcon = (
		<SyledCloseIcon
			token={token}
			size={14 * scale}
			color={value ? colorPalette.neutral13 : colorPalette.neutral5}
		/>
	);

	const borderColor = isValid ? colorPalette.green8 : colorPalette.lavender6;
	const backgroundColor = borderColor + opacitySuffix;
	const componemtWidthPx = props.component.width * props.pageBoundingBox.width;

	const onChange = (value: string) => {
		if (value === `${props.componentValue.component_id}-clear-option`) {
			props.onChange(null);
		} else {
			props.onChange(value);
		}
		selectRef.current?.focus();
	};

	React.useEffect(() => {
		if (props.selected) {
			selectRef.current?.focus();
		}
	}, [props.selected]);

	React.useEffect(() => {
		if (!props.componentVisibility) {
			selectRef.current?.blur();
		}
	}, [props.componentVisibility]);

	const options = (props.options || []).map(item => (
		<Select.Option
			value={item}
			className={`DropdownComponent-${props.componentValue.component_id}`}
		>
			<span>{item}</span>
		</Select.Option>
	));

	const dropDownMenuIcon = !value && (
		<DropdownMenu color={colorPalette.neutral13} size={24 * scale} />
	);

	const calculateHeight = props.options?.length > 5 ? '100%' : '50%';

	const showDrawer = () => {
		setDrawerVisible(true);
	};
	const hideDrawer = () => {
		setDrawerVisible(false);
	};
	const handleOptionSelect = (option: string) => {
		onChange(option);
		setDrawerVisible(false);
	};
	return (
		<Wrapper>
			<Global
				styles={css`
					.DropdownComponent-${props.componentValue.component_id} {
						.ant-select-item-option-content {
							white-space: break-spaces;
							word-break: break-all;
						}
					}

					.DropdownComponent-${props.componentValue.component_id}-clear-option,
						.DropdownComponent-${props.componentValue.component_id}-divider {
						padding: unset !important;
						min-height: unset !important;
					}

					@media (max-width: ${token.screenLGMax}px) {
						.DropdownComponent-${props.componentValue.component_id} {
							min-height: ${sizes.SM} !important;
							padding: 0px ${sizes.SM} !important;

							.ant-select-item-option-content {
								font-size: ${sizes.SM} !important;
							}
						}
					}

					@media (max-width: ${token.screenXS}px) {
						.DropdownComponent-${props.componentValue.component_id} {
							min-height: ${sizes.SM} !important;
							padding: 0px ${sizes.SM} !important;

							.ant-select-item-option-content {
								font-size: ${sizes.XS} !important;
							}
						}

						.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
						.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
							padding-inline-end: 0;
						}
					}

					.ant-drawer-close:focus {
						background-color: transparent !important;
					}
				`}
			/>

			<>
				{screens.md ? (
					<>
						<StyledSelectWrapper
							showAction={['focus', 'click']}
							borderColor={borderColor}
							backgroundColor={backgroundColor}
							onChange={onChange}
							token={token}
							suffixIcon={dropIcon}
							value={value}
							hasValue={!!value}
							scale={props.scale}
							ref={selectRef}
							autoFocus={props.selected}
							placeholder={
								<Flex gap={8}>
									{dropDownMenuIcon}
									{componemtWidthPx > 45 && (
										<Typography.Text
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												fontSize: props.scale * 18,
											}}
										>
											{t(
												'esign-pilet-ui:signerPage.content.fields.dropdown.selectOption'
											)}
										</Typography.Text>
									)}
								</Flex>
							}
							data-testid={`DropdownComponent-${props.componentValue.component_id}-${
								!!value ? 'selected' : 'unselected'
							}`}
						>
							{options}
							<Select.Option
								disabled
								value={`${props.componentValue.component_id}-divider`}
								className={`DropdownComponent-${props.componentValue.component_id}-divider`}
							>
								<Divider style={{ margin: `${sizes.XS} 0px` }} />
							</Select.Option>
							<Select.Option
								value={`${props.componentValue.component_id}-clear-option`}
								disabled={!value}
								className={`DropdownComponent-${props.componentValue.component_id}-clear-option`}
							>
								<StyledClearButton
									type="text"
									icon={closeIcon}
									token={token}
									padding={`5px ${sizes.SM} 5px ${sizes.SM}`}
									disabled={!value}
									data-testid={`SelectClearButton-${props.componentValue.component_id}-${
										!!value ? 'selected' : 'unselected'
									}`}
								>
									{t('esign-pilet-ui:signerPage.content.fields.dropdown.clearSelection')}
								</StyledClearButton>
							</Select.Option>
						</StyledSelectWrapper>
					</>
				) : (
					<>
						<StyledDrawerButton
							borderColor={borderColor}
							backgroundColor={backgroundColor}
							token={token}
							hasValue={!!value}
							onClick={showDrawer}
							data-testid={'DropdownComponent-drawer-btn'}
						>
							{dropDownMenuIcon}
							<Typography.Text
								style={{
									width: '100%',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									fontSize: `${16 * scale}px`,
								}}
							>
								{value ??
									t('esign-pilet-ui:signerPage.content.fields.dropdown.selectOption')}
							</Typography.Text>
							{dropIcon}
						</StyledDrawerButton>
						<Drawer
							title={t('esign-pilet-ui:signerPage.content.fields.dropdown.selectOption')}
							placement="bottom"
							open={drawerVisible}
							onClose={hideDrawer}
							height={calculateHeight}
							data-testid={'DropdownComponent-drawer'}
							footer={
								value && (
									<Button
										onClick={() =>
											onChange(`${props.componentValue.component_id}-clear-option`)
										}
										data-testid="DropdownComponent-drawer-clear-btn"
									>
										{t(
											'esign-pilet-ui:signerPage.content.fields.dropdown.clearSelection'
										)}
									</Button>
								)
							}
						>
							<List
								dataSource={props.options}
								renderItem={item => (
									<StyledListItem
										isSelected={item === value}
										onClick={() => handleOptionSelect(item)}
									>
										<Typography.Text>{item}</Typography.Text>
										{item === value && <CheckOutlined style={{ color: '#0C0D0D' }} />}
									</StyledListItem>
								)}
							></List>
						</Drawer>
					</>
				)}
			</>
		</Wrapper>
	);
};
