import React from 'react';
import { colorPalette } from '@sharefiledev/antd-config';
import { Flex, theme } from 'antd';
import { ComponentValue } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { isComponentValid } from '../../util';
import {
	StyledInputWrapper,
	StyledPlaceholder,
	StyledTextAreaWrapper,
	StyledTextComponentWrapper,
	StyledTextEditingTyping,
} from './TextComponent.styled';

const opacitySuffix = '1A';
const { useToken } = theme;

const componentStyles: React.CSSProperties = {
	border: '1px solid',
};

const validComponentStyles: React.CSSProperties = {
	border: '1px solid',
};

interface Props {
	componentValue: ComponentValue;
	textAlign: string;
	multiline?: boolean;
	onChange: (value: string) => void;
	selected: boolean;
	pageBoundingBox: DOMRect;
	defaultValue?: string;
	scale: number;
}

export const TextComponent = (props: Props) => {
	const defaultValue = props.defaultValue;
	const value = props.componentValue.value ?? defaultValue;
	const isValid = isComponentValid(value, ComponentTypes.TextComponent);

	const { token } = useToken();

	const borderColor = isValid ? colorPalette.green8 : colorPalette.lavender6;
	const backgroundColor = borderColor + opacitySuffix;

	if (!props.selected) {
		return (
			<StyledTextComponentWrapper
				style={isValid ? validComponentStyles : componentStyles}
				textAlign={props.textAlign}
				borderColor={borderColor}
				backgroundColor={backgroundColor}
				multiline={props.multiline}
				token={token}
				id={`TextComponent-${props.componentValue.component_id}-unselected`}
				data-testid={`TextComponent-${props.componentValue.component_id}-unselected`}
			>
				{value ? (
					<span
						id="readonly-text"
						style={{
							fontSize: `${14 * props.scale}px`,
							paddingLeft: `${10 * props.scale}px`,
							fontFamily: 'Courier, sans-serif',
						}}
					>
						{value}
					</span>
				) : (
					<Flex
						align="start"
						gap={8}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: props.textAlign,
							width: '100%',
						}}
					>
						<StyledTextEditingTyping scale={props.scale} />
						<StyledPlaceholder scale={props.scale}>
							{t('esign-pilet-ui:signerPage.content.fields.enterText')}
						</StyledPlaceholder>
					</Flex>
				)}
			</StyledTextComponentWrapper>
		);
	}

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		props.onChange(e.target.value);
	};

	return props.multiline ? (
		<StyledTextAreaWrapper
			textAlign={props.textAlign}
			borderColor={borderColor}
			backgroundColor={backgroundColor}
			color={colorPalette.neutral13}
			token={token}
			autoFocus
			onChange={onChange}
			value={value}
			autoSize
			data-testid={`TextComponent-${props.componentValue.component_id}-selected`}
			scale={props.scale}
		/>
	) : (
		<StyledInputWrapper
			textAlign={props.textAlign}
			borderColor={borderColor}
			backgroundColor={backgroundColor}
			color={colorPalette.neutral13}
			token={token}
			autoFocus
			onChange={onChange}
			value={value}
			data-testid={`TextComponent-${props.componentValue.component_id}-selected`}
			scale={props.scale}
		/>
	);
};
