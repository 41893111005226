import React from 'react';

export function useImageLoaded(src: string): boolean {
	const [loaded, setLoaded] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (!src) {
			return;
		}
		const img = new Image();
		img.src = src;
		img.onload = function () {
			setLoaded(true);
		};
	}, [src]);
	return loaded;
}
