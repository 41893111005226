import React from 'react';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { useAppStore } from '../../../Common/store';
import { t } from '../../../util';
import { DetailTile } from './DetailTile';

export const DocumentDetails: React.FC = () => {
	const [signer, esignDocument] = useAppStore(store => [
		store.signer,
		store.esignDocument,
	]);

	const humanizedDueDate = React.useMemo(() => {
		return dayjs(esignDocument.document_expires_at).format('MMMM D, YYYY');
	}, [esignDocument.document_expires_at]);

	return (
		<Flex vertical gap={24}>
			<DetailTile
				title={t('esign-pilet-ui:signerPage.content.documentDetails.documentInfoTitle')}
				primaryText={esignDocument.name}
				secondaryText={`(ID: ${esignDocument.id})`}
			/>
			<DetailTile
				title={t('esign-pilet-ui:signerPage.content.documentDetails.senderInfoTitle')}
				primaryText={esignDocument.sender_name}
				secondaryText={esignDocument.sender_email}
			/>
			{signer.message && (
				<DetailTile
					title={t(
						'esign-pilet-ui:signerPage.content.documentDetails.senderMessageTitle'
					)}
					primaryText={signer.message}
				/>
			)}
			<DetailTile
				title={t('esign-pilet-ui:signerPage.content.documentDetails.dueDateTitle')}
				primaryText={humanizedDueDate}
			/>
		</Flex>
	);
};
