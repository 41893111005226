import { create } from 'zustand';
import { createComponentValueSlice } from './createComponentValueStore';
import { createDocumentAttachmentsSlice } from './createDocumentAttachmentsStore';
import { createEsignDocumentSlice } from './createEsignDocumentStore';
import { createPersonalSettingsSlice } from './createPersonalSettingsStore';
import { createSignaturesSlice } from './createSignaturesStore';
import { createSignerPaymentsSlice } from './createSignerPaymentsStore';
import { createSignersSlice } from './createSignersStore';
import { createSignerSlice } from './createSignerStore';
import { AppStore } from './types';

export const storeResetFns = new Set<() => void>();

export const useAppStore = create<AppStore & { resetStore: () => void }>((...args) => ({
	...createComponentValueSlice(...args),
	...createSignerSlice(...args),
	...createSignaturesSlice(...args),
	...createDocumentAttachmentsSlice(...args),
	...createEsignDocumentSlice(...args),
	...createPersonalSettingsSlice(...args),
	...createSignerPaymentsSlice(...args),
	...createSignersSlice(...args),
	resetStore: () => {
		storeResetFns.forEach(fn => fn());
	},
}));
