import { CaretRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Button, GlobalToken } from 'antd';

const INITIAL_OFFSET = 88,
	MOBILE_TRACKER_HEIGHT = 50,
	NAVIGATION_HEIGHT = 40; // in pixels

export const StyledNavigationWrapper = styled.div<{
	isNextButton: boolean;
	leftRight: [number, number];
	visible: boolean;
	token: GlobalToken;
}>`
	position: absolute;
	width: fit-content;
	z-index: 12;
	left: ${props => `${props.leftRight[0]}px`};
	right: ${props => `${props.leftRight[1]}px`};
	transition: transform 0.5s ease-out;
	visibility: ${props => (props.visible ? 'visible' : 'hidden')};
	transform: translateY(${INITIAL_OFFSET}px);

	@media (max-width: ${props => props.token.screenXS}px) {
		background: ${props => (!props.isNextButton ? colorPalette.neutral1 : 'transparent')};
		position: fixed;
		height: unset;
		bottom: 0;
		top: unset !important;
		left: unset;
		transform: unset !important;
		padding: ${props =>
			props.isNextButton ? `${sizes.base} ${sizes.MD}` : `${sizes.XS} ${sizes.base}`};
		right: ${props => (props.isNextButton ? 0 : 'unset')};
		width: ${props => (props.isNextButton ? 'unset' : '100%')};
	}
`;

export const StyledNavigationButton = styled(Button)<{
	isNextButton: boolean;
	token: GlobalToken;
}>`
	background: ${colorPalette.lavender6};
	font-size: ${sizes.SM};
	width: '100%';
	height: ${NAVIGATION_HEIGHT}px;
	padding: 10px ${sizes.base};

	.anticon {
		vertical-align: middle;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: 14px;
		width: ${props => (props.isNextButton ? 'unset' : '100%')};
		padding: unset;
	}
`;

export const StyledRoundNavigationButton = styled(Button)`
	min-width: ${NAVIGATION_HEIGHT}px !important;
	height: ${NAVIGATION_HEIGHT}px;
	background: ${colorPalette.neutral1};
	padding: 0;

	.anticon {
		vertical-align: middle;
	}
`;

export const StyledCaretRightOutlined = styled(CaretRightOutlined)<{
	token: GlobalToken;
}>`
	font-size: ${MOBILE_TRACKER_HEIGHT}px;
	position: absolute;
	left: -15px;
	transform: translateY(${INITIAL_OFFSET}px);
	color: ${colorPalette.lavender6};
	z-index: 1000;
`;
