import React from 'react';
import { Layout } from 'antd';
import { SidePanelTabs } from './SidePanelTabs';

const { Sider } = Layout;
const HEADER_HEIGHT = 64;

const sidePanelStyles: React.CSSProperties = {
	padding: '8px 24px',
	height: `calc(100vh - ${HEADER_HEIGHT}px)`,
	overflow: 'auto',
};

export const SidePanel: React.FC = () => {
	return (
		<Sider
			width={340}
			collapsedWidth={0}
			trigger={null}
			breakpoint="lg"
			theme="light"
			style={{ borderLeft: '1px solid #D7D9DB' } as React.CSSProperties}
		>
			<div style={sidePanelStyles}>
				<SidePanelTabs />
			</div>
		</Sider>
	);
};
