import React from 'react';
import { Button, Form, Radio, theme } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from '../../../Common/Hooks/useSearchParams';
import { handleError } from '../../../data/errorHandler';
import { ESignatureClient } from '../../../data/eSignatureClient';
import { KbaAnswer, KbaAnswersPayload, KbaSession } from '../../../data/rsTypes';
import { t } from '../../../util';

type Props = {
	signerId: string;
	currentKbaSession: string;
	unlockDocument: () => void;
	setKbaStep: ({ action, attempts }) => void;
	setAuthFailed: (isLocked: boolean) => void;
	kbaIdentityData: KbaSession;
	fetchKbaIdentity: () => Promise<KbaSession>;
};

export const KbaVerification = (props: Props) => {
	const [form] = Form.useForm();
	const { token } = theme.useToken();
	const { questions = [], externalId } = props.kbaIdentityData ?? {};

	const location = useLocation();
	const searchParams = useSearchParams(location.search);

	const handleVerify = async values => {
		const mappedAnswers: KbaAnswer[] = Object.entries(values).map(([key, value]) => ({
			type: key,
			answer: value as string,
		}));
		let payload = {
			answers: mappedAnswers,
			externalId,
			identityToken: searchParams.get('identity_token'),
			signerId: props.signerId,
		} as KbaAnswersPayload;

		try {
			const result = await ESignatureClient.verifyKbaAnswers(
				props.currentKbaSession,
				payload
			);

			if (result?.success) {
				props.unlockDocument();
			} else {
				props.setKbaStep({ action: result?.action, attempts: result?.attempts });
				if (result?.attempts == 3) {
					props.setAuthFailed(true);
				}
				// setAnswers({});
				await props.fetchKbaIdentity();
			}
		} catch (e) {
			const { status } = JSON.parse(e.message);
			if (status === 403) {
				props.setAuthFailed(true);
			} else {
				handleError();
			}
		}
	};

	if (!questions.length) {
		return <></>;
	}

	return (
		<Form
			form={form}
			layout="vertical"
			name="kbaVerification"
			onFinish={handleVerify}
			style={{ maxWidth: 720 }}
			scrollToFirstError={{ behavior: 'smooth', block: 'end', focus: true } as any}
		>
			{questions?.map(question => (
				<Form.Item
					name={question.type}
					label={question.prompt}
					rules={[
						{
							required: true,
							message: t('esign-pilet-ui:signerPage.modals.kba.requiredQuestion'),
						},
					]}
					key={question.type}
				>
					<Radio.Group style={{ display: 'flex', flexDirection: 'column' }}>
						{question.answer.map((answer, answerId) => (
							<Radio
								key={answerId}
								value={answer}
								style={{ marginBottom: token.marginSM }}
							>
								{answer}
							</Radio>
						))}
					</Radio.Group>
				</Form.Item>
			))}
			<Button
				key="submit"
				type="primary"
				data-testid="modal-button-next"
				htmlType="submit"
			>
				{t('esign-pilet-ui:signerPage.modals.kba.btnCompleteVerification')}
			</Button>
		</Form>
	);
};
