import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { ChevronDown, MenuDotVertical } from '@sharefiledev/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Divider, Drawer, Dropdown, Flex, Grid, notification } from 'antd';
import { useAppStore } from '../../../Common/store';
import { SignerPageTabIndexes } from '../../../constants';
import { ESignatureClient } from '../../../data/eSignatureClient';
import {
	Account,
	BaseFiles,
	DeclineDocumentRequest,
	SignerResponseStatus,
	SignerSubmissionRequest,
} from '../../../data/rsTypes';
import { t } from '../../../util';
import { downloadFileWithName } from '../../../util/file';
import { DocumentDetails } from '../../PageContent/DocumentDetails/DocumentDetails';
import { DeclineDocumentModal } from './DeclineDocument/DeclineDocumentModal';
import { DeclineSuccessModal } from './DeclineDocument/DeclineSuccessModal';
import { StyledMoreActionsButton } from './DocumentActions.styled';
import { DocumentActionsDrawerContent } from './DocumentActionsDrawerContent';
import { generateDocumentActionsDropdownContent } from './DocumentActionsDropdownContent';
import SwitchExperienceModal from './SwitchExperience/SwitchExperienceModal';

const { useBreakpoint } = Grid;

const dividerStyles: React.CSSProperties = {
	width: 'calc(100% + 40px)',
	position: 'relative',
	right: '20px',
	marginTop: sizes.XS,
};

export interface DocumentActionsDrawerProps {
	baseFiles: BaseFiles;
	account: Account;
}

export const DocumentActionsDrawer: React.FC<DocumentActionsDrawerProps> = props => {
	const screens = useBreakpoint();
	const componentValues = useAppStore(state => state.componentValues);
	const [isMoreActionsDrawerVisible, setIsMoreActionsDrawerVisible] =
		React.useState(false);
	const [isDeclineModalOpen, setDeclineModalOpen] = React.useState(false);
	const [isDeclineSuccessModalOpen, setDeclineSuccessModalOpen] = React.useState(false);
	const [reason, setReason] = React.useState<string>(null);
	const [signer, esignDocument, searchParams] = useAppStore(store => [
		store.signer,
		store.esignDocument,
		store.searchParams,
	]);
	const [isSwitchExperienceModalOpen, setIsSwitchExperienceModalOpen] =
		React.useState(false);

	const showDrawer = () => {
		setIsMoreActionsDrawerVisible(true);
	};

	const onClose = () => {
		setIsMoreActionsDrawerVisible(false);
	};

	const onDeclineClick = () => {
		setIsMoreActionsDrawerVisible(false);
		setDeclineModalOpen(true);
	};

	const onModalClose = () => {
		setDeclineModalOpen(false);
	};

	const onReasonChange = (value: string) => {
		setReason(value);
	};

	const handeDownloadPdf = () => {
		const downloadUrl = props.baseFiles.downloaded_file_url;
		downloadFileWithName(downloadUrl, esignDocument?.name);
	};

	const onSwitchExperience = () => {
		setIsMoreActionsDrawerVisible(false);
		setIsSwitchExperienceModalOpen(true);
	};

	const onSwitchExperienceModalClose = () => {
		setIsSwitchExperienceModalOpen(false);
	};

	const signerSavesProgressMutation = useMutation({
		mutationFn: () => {
			const payload: SignerSubmissionRequest = {
				signer: {
					name: signer.name,
					email: signer.email,
					response: {
						status: SignerResponseStatus.Draft,
						component_values: Array.from(componentValues.values()),
					},
				},
			};

			return ESignatureClient.submitSignerResponse(signer.id, searchParams, payload);
		},
		onSuccess: () => {
			notification.success({
				message: t('esign-pilet-ui:signerPage.header.documentActions.saveSuccess'),
				placement: 'topRight',
			});
		},
		onError: () => {
			notification.error({
				message: t('esign-pilet-ui:signerPage.header.documentActions.saveFailed'),
				placement: 'topRight',
			});
		},
	});

	const signerDeclinesDocumentMutation = useMutation({
		mutationFn: () => {
			const payload: DeclineDocumentRequest = {
				identity_token: searchParams.get('identity_token'),
				access_token: searchParams.get('access_token'),
				reason_for_decline: reason,
			};

			return ESignatureClient.declineDocument(signer.id, payload);
		},
		onSuccess: () => {
			setDeclineSuccessModalOpen(true);
		},
		onError: () => {
			notification.error({
				message: t('esign-pilet-ui:signerPage.header.documentActions.declineFailed'),
				placement: 'topRight',
			});
		},
	});

	const items = generateDocumentActionsDropdownContent({
		onSave: () => {
			return signerSavesProgressMutation.mutateAsync();
		},
		onDownload: handeDownloadPdf,
		onDecline: onDeclineClick,
		enableDownload: props.account.enable_signer_download,
		onSwitchExperience: onSwitchExperience,
	});

	return (
		<>
			{screens.lg ? (
				<Dropdown
					placement="bottom"
					trigger={['click']}
					menu={{ items }}
					arrow={false}
					autoFocus={true}
				>
					<StyledMoreActionsButton
						id="more-actions-button"
						tabIndex={SignerPageTabIndexes.MoreActionsButton}
						data-testid="more-actions-button-lg"
						size="middle"
						shape="round"
					>
						<Flex gap={'small'} align="center">
							{t('esign-pilet-ui:signerPage.header.moreActions')}
							<ChevronDown size={14} />
						</Flex>
					</StyledMoreActionsButton>
				</Dropdown>
			) : (
				<>
					<Button
						id="more-actions-button"
						tabIndex={SignerPageTabIndexes.MoreActionsButton}
						type="text"
						onClick={showDrawer}
						icon={<MenuDotVertical size={16} />}
						style={{ marginLeft: 0 }}
						size="small"
						data-testid="more-actions-button-sm"
					></Button>

					<Drawer
						title={t(
							'esign-pilet-ui:signerPage.header.documentActions.actionsAndDetails'
						)}
						styles={{
							body: {
								padding: `${sizes.XS} ${sizes.MS} ${sizes.MS} ${sizes.MS}`,
								overflowX: 'hidden',
							},
						}}
						onClose={onClose}
						open={isMoreActionsDrawerVisible}
						width={screens.xs ? '100vw' : '400px'}
						data-testid="details-action-drawer"
					>
						<DocumentActionsDrawerContent
							onSave={() => {
								return signerSavesProgressMutation.mutateAsync();
							}}
							onDownload={handeDownloadPdf}
							onDecline={onDeclineClick}
							enableDownload={props.account.enable_signer_download}
							onSwitchExperience={onSwitchExperience}
						/>
						{!screens.lg && (
							<>
								<Divider style={dividerStyles} />
								<DocumentDetails />
							</>
						)}
					</Drawer>
				</>
			)}

			<DeclineDocumentModal
				isModalOpen={isDeclineModalOpen}
				reason={reason}
				onDecline={signerDeclinesDocumentMutation.mutateAsync}
				onClose={onModalClose}
				onReasonChange={onReasonChange}
			/>

			<DeclineSuccessModal
				isModalOpen={isDeclineSuccessModalOpen}
				senderEmail={esignDocument.sender_email}
			/>

			<SwitchExperienceModal
				isModalOpen={isSwitchExperienceModalOpen}
				onClose={onSwitchExperienceModalClose}
			/>
		</>
	);
};
