export const SignatureSave = {
	NOT_STARTED: 'NOT_STARTED',
	STARTED: 'STARTED',
	COMPLETED: 'COMPLETED',
};

export const SignatureType = {
	DRAWN: 'drawn',
	TYPED: 'typed',
	UPLOADED: 'uploaded',
};

export const canvasDimesions = {
	width: 800,
	height: 200,
};
