import { RightSignatureEntity } from '@citrite/sf-api';
import { getCookie, KBA_KEY } from '../util/cookieUtils';

const getLandingLink = () => {
	return RightSignatureEntity.getUserLandingLink().execute();
};

const openURLInNewTab = (urlData, queryParams = {}) => {
	const url = new URL(urlData.Link);
	Object.entries(queryParams).forEach(([key, value]) => {
		if (value) {
			url.searchParams.append(key, value.toString());
		}
	});
	window.open(url.toString(), '_blank');
};

export const redirectToRsLandingPage = async () => {
	return getLandingLink().then(urlData => {
		openURLInNewTab(urlData);
	});
};

export const redirectToRsUploadPage = async documentTransaction => {
	return getLandingLink().then(urlData => {
		const queryParams = {
			action: 'get_transaction',
			document_transaction_id: documentTransaction.documentTransactionId,
		};
		openURLInNewTab(urlData, queryParams);
	});
};

export const redirectToRSsettings = () => {
	getLandingLink().then(urlData => {
		openURLInNewTab(urlData, { action: 'settings' });
	});
};

export const getRSRedirectionHost = () => {
	let currentUrlHostname = window.location.hostname;

	let [, domainName, toplevelDomainName] = currentUrlHostname.split('.');
	let hostName = '';

	// Domain and Sub-domain values
	const rsDomainName = 'rightsignature';
	const rsProdSubDomainName = 'secure';
	const rsStagingSubDomainName = 'secure-rs4-staging';
	const rsStaging2SubDomainName = 'secure-rs4-staging2';

	if (/sharefiletest/gi.test(domainName)) {
		if (!/eu/gi.test(toplevelDomainName)) {
			hostName = `${rsStagingSubDomainName}.${rsDomainName}.${toplevelDomainName}`;
		}
	} else if (/sharefilestaging/gi.test(domainName)) {
		if (!/eu/gi.test(toplevelDomainName)) {
			hostName = `${rsStaging2SubDomainName}.${rsDomainName}.${toplevelDomainName}`;
		} else {
			hostName = `${rsStagingSubDomainName}.${rsDomainName}.${toplevelDomainName}`;
		}
	} else if (/sharefile/gi.test(domainName)) {
		hostName = `${rsProdSubDomainName}.${rsDomainName}.${toplevelDomainName}`;
	}

	return hostName;
};

export function addSearchParamsToUrl(url: URL, searchParams: URLSearchParams) {
	for (const [key, value] of searchParams.entries()) {
		url.searchParams.append(key, value);
	}
}

export function redirectToRS(
	passSessionId: boolean,
	searchParams: URLSearchParams | null = null,
	customPathName: string | null = null
) {
	const redirectHost = getRSRedirectionHost();
	let redirectUrl: URL;

	if (customPathName) {
		// Use provided pathname for redirection
		redirectUrl = new URL(`https://${redirectHost}`);
		redirectUrl.pathname = customPathName;
	} else {
		// Default: redirect from current location
		redirectUrl = new URL(window.location.href);
		redirectUrl.host = redirectHost;

		if (redirectUrl.pathname.includes('/signaturerequests')) {
			redirectUrl.pathname = redirectUrl.pathname.replace('/signaturerequests', '');
		}
	}

	// Append search params if any
	if (searchParams) {
		addSearchParamsToUrl(redirectUrl, searchParams);
	}

	const kbaSessionId = getCookie(KBA_KEY);
	if (passSessionId && kbaSessionId) {
		redirectUrl.searchParams.append('session_id', kbaSessionId);
	}

	window.location.href = redirectUrl.toString();
}
