import React, { useEffect, useRef, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import {
	Audio,
	Close,
	Excel,
	Images,
	InboxContent,
	Paperclip,
	Pdf,
	Powerpoint,
	RecycleBin,
	Text,
	Unknown,
	Videos,
	Word,
	ZipFile,
} from '@sharefiledev/icons';
import {
	Alert,
	Button,
	Col,
	Grid,
	Progress,
	Row,
	Space,
	theme,
	Typography,
	Upload,
} from 'antd';
import { ESignatureClient } from '../../../../data/eSignatureClient';
import { Component, ComponentValue } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { useAppStore } from '../../../store';
import { isComponentValid } from '../../util';
import {
	StyledAttachmentModal,
	StyledOpenModalButton,
} from './AttachmentComponent.styled';

interface Props {
	component: Component;
	componentValue: ComponentValue;
	onChange: (value: string) => void;
	required: boolean;
	selected: boolean;
	pageBoundingBox: DOMRect;
	scale: number;
}

const { Dragger } = Upload;
const { useToken } = theme;

const buttonMobileWidth: React.CSSProperties = {
	width: '100%',
};

const buttonDeskWidth: React.CSSProperties = {
	width: 'unset',
};

const uploadButtonInit: React.CSSProperties = {
	display: 'flex',
	width: '100%',
};

const uploadButtonSuccess: React.CSSProperties = {
	display: 'flex',
	width: '100%',
	backgroundColor: colorPalette.green3,
	borderColor: colorPalette.green8,
};

const { useBreakpoint } = Grid;

let controller = new AbortController();

const mainModalFocusTargets = [
	'.attachmentModal .ant-upload-btn', // dragger
	'.attachmentModal .cancel-upload-button', // stop upload button
	'.attachmentModal .delete-file-button', // delete file button
	'.attachmentModal .upload-different-button', // upload different file button
	'.attachmentModal .done-button', // done button
	'.attachmentModal .ant-modal-close', // modal close icon
];

const confirmationFocusTargets = [
	'.confirmation-modal .cancel-button', // cancel button
	'.confirmation-modal .destroy-button', // delete/upload another file button
];

export const AttachmentComponent = (props: Props) => {
	const [signer, documentAttachments, searchParams] = useAppStore(store => [
		store.signer,
		store.documentAttachments,
		store.searchParams,
	]);
	const { token } = useToken();
	const [open, setOpen] = useState(false);
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [fileUploadVisible, setFileUploadVisible] = useState(true);
	const [focusTarget, setFocusTarget] = useState<number>(-1);
	const [progress, setProgress] = useState<number>(0);
	const screens = useBreakpoint();
	const [deleteModalOpen, setDeleteModalOpen] = useState([false, false]);
	const draggerRef = useRef(null);
	const openModalButtonRef = useRef(null);
	const isValid = isComponentValid(
		props.componentValue.value,
		ComponentTypes.AttachmentComponent
	);
	const [errorAlertShow, setErrorAlertShow] = useState(false);

	const componemtWidthPx = props.component.width * props.pageBoundingBox.width;

	const toggleModal = (idx: number, target: boolean) => {
		setDeleteModalOpen(p => {
			p[idx] = target;
			return [...p];
		});
	};

	const handleCloseDeleteModals = () => {
		setDeleteModalOpen([false, false]);
	};

	const handleOk = () => {
		setOpen(false);
	};

	const removeUploadedFile = (isUploadingAnotherFile = false) => {
		handleCloseDeleteModals();
		if (isUploadingAnotherFile) {
			setTimeout(() => {
				draggerRef.current?.upload.uploader.fileInput.click();
			}, 0);
		} else {
			props.onChange(null);
			setUploadedFile(null);
			setFileUploadVisible(true);
		}
	};

	const cancelUpload = () => {
		controller.abort();
		controller = new AbortController();
	};

	const hadleFileUpload = ({ file }) => {
		setFileUploadVisible(false);
		setUploadedFile(file);

		ESignatureClient.uploadComponentAttachment(
			signer.id,
			props.component.id,
			searchParams,
			file,
			setProgress,
			controller
		)
			.then((uploadResponse: any) => {
				props.onChange(uploadResponse.attachment.id);
			})
			.catch(() => {
				setErrorAlertShow(true);
				setUploadedFile(null);
				setFileUploadVisible(true);
			});
	};

	const DynamicIcon: React.FunctionComponent<any> = dynamicIconProps => {
		const { fileType: string, ...rest } = dynamicIconProps;

		switch (dynamicIconProps.fileType) {
			case 'application/pdf':
				return <Pdf {...rest} />;
			case 'text/plain':
				return <Text {...rest} />;
			case 'application/msword':
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return <Word {...rest} />;
			case 'application/vnd.ms-excel':
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return <Excel {...rest} />;
			case 'image/apng':
			case 'image/bmp':
			case 'image/gif':
			case 'image/vnd.microsoft.icon':
			case 'image/jpeg':
			case 'image/png':
			case 'image/svg+xml':
			case 'image/tiff':
			case 'image/webp':
				return <Images {...rest} />;
			case 'video/x-msvideo':
			case 'video/mp4':
			case 'video/mpeg':
			case 'video/ogg':
			case 'video/webm':
			case 'video/3gpp':
			case 'video/3gpp2':
				return <Videos {...rest} />;
			case 'audio/aac':
			case 'application/x-cdf':
			case 'audio/midi':
			case 'audio/x-midi':
			case 'audio/mpeg':
			case 'audio/ogg':
			case 'audio/opus':
			case 'audio/wav':
			case 'audio/webm':
			case 'audio/3gpp':
			case 'audio/3gpp2':
				return <Audio {...rest} />;
			case 'application/zip':
			case 'application/x-7z-compressed':
			case 'application/gzip':
			case 'application/epub+zip':
			case 'application/x-bzip':
			case 'application/x-bzip2':
				return <ZipFile {...rest} />;
			case 'application/vnd.ms-powerpoint':
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return <Powerpoint {...rest} />;
			default:
				return <Unknown {...rest} />;
		}
	};

	useEffect(() => {
		const attachment = documentAttachments?.find(
			attachment => attachment.component_id === props.component.id
		);
		// TBD need api to get attachment details to store
		if (attachment) {
			setUploadedFile({
				name: attachment.filename,
			} as File);
		}
	}, [documentAttachments, props.component.id, setUploadedFile]);

	useEffect(() => {
		if (props.selected) {
			openModalButtonRef.current?.focus();
		} else {
			openModalButtonRef.current?.blur();
		}
	}, [props.selected]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const findNextTarget = (
				previousTarget: number,
				targets: string[],
				reverse: boolean
			): number => {
				if (previousTarget < 0) {
					const index = reverse ? targets.length - 1 : 0;
					const nextTarget = document.querySelector(targets[index]);
					return nextTarget && (nextTarget as HTMLElement).checkVisibility()
						? index
						: // in case of different dialog states some objects might not be visible (delete button for zero state, or dragger for non-zero state)
						  // so we have to move to the next object
						  findNextTarget(0, targets, reverse);
				} else {
					let newIndex = reverse
						? previousTarget - 1
						: (previousTarget + 1) % targets.length;
					if (newIndex < 0) {
						newIndex = reverse ? targets.length - 1 : 0;
					}
					const nextTarget = document.querySelector(targets[newIndex]);
					return nextTarget && (nextTarget as HTMLElement).checkVisibility()
						? newIndex
						: // in case of different dialog states some objects might not be visible (delete button for zero state, or dragger for non-zero state)
						  // so we have to move to the next object
						  findNextTarget(newIndex, targets, reverse);
				}
			};

			if (open) {
				if (event.key === 'Tab') {
					const targets =
						deleteModalOpen[0] || deleteModalOpen[1]
							? confirmationFocusTargets
							: mainModalFocusTargets;

					event.preventDefault();
					const nextTarget = findNextTarget(focusTarget, targets, event.shiftKey);

					setFocusTarget(nextTarget);
					(document.querySelector(targets[nextTarget]) as HTMLElement).focus();
				}
			}
		};

		const handleOnClick = () => {
			// reset focus target on click somewhere
			if (open) {
				setFocusTarget(-1);
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('click', handleOnClick);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('click', handleOnClick);
		};
	}, [open, focusTarget, deleteModalOpen]);

	useEffect(() => {
		setFocusTarget(-1);
	}, [open, deleteModalOpen]);

	return (
		<>
			<Global
				styles={css`
					.ant-upload-drag:hover {
						border-color: ${colorPalette.lavender7} !important;
					}
				`}
			/>
			<StyledOpenModalButton
				ref={openModalButtonRef}
				token={token}
				valid={+isValid}
				onClick={() => setOpen(true)}
				icon={<Paperclip size={props.scale * 24} />}
				style={uploadedFile ? uploadButtonSuccess : uploadButtonInit}
				data-testid="open-modal-button"
			>
				{componemtWidthPx < 45 || (
					<Typography.Text
						style={{
							maxWidth: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							fontSize: (uploadedFile ? 14 : 18) * props.scale,
						}}
					>
						{uploadedFile
							? uploadedFile.name
							: t('esign-pilet-ui:signerPage.content.fields.attachment.buttonText')}
					</Typography.Text>
				)}
			</StyledOpenModalButton>
			<StyledAttachmentModal
				title={t('esign-pilet-ui:signerPage.content.fields.attachment.modalTitle')}
				open={open}
				onOk={handleOk}
				onCancel={handleOk}
				destroyOnClose={true}
				wrapClassName="attachmentModal"
				width={687}
				centered={screens.sm}
				token={token}
				footer={() => (
					<Space
						direction={screens.sm ? 'horizontal' : 'vertical'}
						style={{ width: '100%' }}
					>
						<Button
							onClick={handleOk}
							style={screens.sm ? buttonDeskWidth : buttonMobileWidth}
							shape="round"
							size="small"
							type="primary"
							data-testid="upload-modal-ok-button"
							className="done-button"
						>
							{t(
								'esign-pilet-ui:signerPage.content.fields.attachment.modalButtonContinue'
							)}
						</Button>
						{uploadedFile && (
							<Button
								onClick={() => toggleModal(1, true)}
								style={screens.sm ? buttonDeskWidth : buttonMobileWidth}
								shape="round"
								size="small"
								className="upload-different-button"
								data-testid="upload-modal-upload-different-button"
							>
								{t(
									'esign-pilet-ui:signerPage.content.fields.attachment.uploadDifferentFileButton'
								)}
							</Button>
						)}
					</Space>
				)}
				data-testid="upload-modal"
			>
				<Space direction="vertical" style={{ width: '100%' }}>
					{errorAlertShow && (
						<Alert
							style={{ marginTop: sizes.XXS, fontSize: sizes.SM }}
							message={t(
								'esign-pilet-ui:signerPage.content.fields.attachment.fileUploadingError'
							)}
							type="error"
							showIcon
							closable
						/>
					)}
					{props.component.help_text && (
						<Space style={{ padding: `${sizes.XS} 0` }}>
							{props.component.help_text}
						</Space>
					)}
					<Dragger
						customRequest={hadleFileUpload}
						listType="picture"
						multiple={false}
						maxCount={1}
						showUploadList={false}
						ref={draggerRef}
						data-testid="modal-dragger"
						style={{
							marginBottom: sizes.SM,
							display: fileUploadVisible && !uploadedFile ? 'block' : 'none',
						}}
					>
						<p className="ant-upload-drag-icon">
							<InboxContent size={40} color={colorPalette.lavender6} />
						</p>
						<p className="ant-upload-text">
							{t('esign-pilet-ui:signerPage.content.fields.attachment.uploadText')}
						</p>
						<p className="ant-upload-hint">
							{t('esign-pilet-ui:signerPage.content.fields.attachment.uploadHint')}
						</p>
					</Dragger>
					{uploadedFile && (
						<Space
							direction="vertical"
							size="small"
							style={{
								width: `calc(100% - ${sizes.XL})`,
								backgroundColor: colorPalette.neutral2,
								borderRadius: sizes.XS,
								padding: sizes.MS,
								marginBottom: sizes.SM,
							}}
						>
							<Row align="middle">
								<Col
									flex={sizes.MD}
									style={{
										marginTop: sizes.XXS,
									}}
								>
									<DynamicIcon
										fileType={uploadedFile.type}
										size={sizes.MD}
										style={{
											padding: `${sizes.XS} ${sizes.SM} 0 0`,
										}}
									></DynamicIcon>
								</Col>
								<Col
									flex="auto"
									style={{
										padding: `0 ${sizes.SM}`,
										maxWidth: 'calc(100% - 80px)',
									}}
								>
									<Typography style={{ fontSize: sizes.base }}>
										{uploadedFile.name}
									</Typography>
									{uploadedFile.size && (
										<Space>
											<Typography
												style={{
													fontSize: sizes.SM,
													color: colorPalette.neutral6,
												}}
											>
												{uploadedFile.size > 1024
													? (uploadedFile.size / (1024 * 1024)).toFixed(2) + 'MB'
													: (uploadedFile.size / 1024).toFixed(2) + 'KB'}
												{progress < 1 && ','}
											</Typography>
											{progress < 1 && (
												<Typography
													style={{
														textTransform: 'capitalize',
														fontSize: sizes.SM,
														color: colorPalette.neutral6,
													}}
												>
													{t(
														'esign-pilet-ui:signerPage.content.fields.attachment.uploadingStatus'
													)}
												</Typography>
											)}
										</Space>
									)}
								</Col>
								<Col flex="24px">
									{progress < 1 && uploadedFile.size && (
										<Button
											size="small"
											shape="circle"
											style={{ border: 'none' }}
											className="cancel-upload-button"
											onClick={cancelUpload}
										>
											<Close data-testid="close-file-button" />
										</Button>
									)}
									{(progress === 1 || !uploadedFile.size) && (
										<Button
											size="small"
											shape="circle"
											style={{ border: 'none' }}
											className="delete-file-button"
											onClick={() => toggleModal(0, true)}
										>
											<RecycleBin data-testid="delete-file-button" />
										</Button>
									)}
								</Col>
							</Row>
							{progress < 1 && uploadedFile.size && (
								<Progress percent={Math.round(progress * 100)} strokeColor="#2F6A35" />
							)}
						</Space>
					)}
				</Space>
				<StyledAttachmentModal
					title={
						<Space>
							<ExclamationCircleFilled
								style={{
									fontSize: sizes.MS,
									color: colorPalette.orange7,
								}}
							/>
							<Space>
								{deleteModalOpen[0]
									? t(
											'esign-pilet-ui:signerPage.content.fields.attachment.modalDeleteFileTitle'
									  )
									: t(
											'esign-pilet-ui:signerPage.content.fields.attachment.uploadDifferentFileButton'
									  )}
							</Space>
						</Space>
					}
					open={deleteModalOpen[0] || deleteModalOpen[1]}
					onOk={() => removeUploadedFile()}
					onCancel={handleCloseDeleteModals}
					destroyOnClose={true}
					closeIcon={null}
					focusTriggerAfterClose={false}
					wrapClassName="confirmation-modal"
					width={400}
					centered={screens.sm}
					token={token}
					footer={() => (
						<Space
							direction={screens.sm ? 'horizontal' : 'vertical'}
							style={{ width: '100%', marginLeft: screens.xs ? 0 : sizes.MD }}
						>
							<Button
								onClick={() => removeUploadedFile(deleteModalOpen[1])}
								style={
									screens.sm
										? buttonDeskWidth
										: {
												...buttonMobileWidth,
												...{ maxWidth: `calc(100% - ${sizes.MD})` },
										  }
								}
								shape="round"
								type="primary"
								size="small"
								className="destroy-button"
							>
								{deleteModalOpen[0]
									? t(
											'esign-pilet-ui:signerPage.content.fields.attachment.modalDeleteFileOkButton'
									  )
									: t(
											'esign-pilet-ui:signerPage.content.fields.attachment.uploadDifferentFileButton'
									  )}
							</Button>
							<Button
								onClick={handleCloseDeleteModals}
								style={
									screens.sm
										? buttonDeskWidth
										: {
												...buttonMobileWidth,
												...{ maxWidth: `calc(100% - ${sizes.MD})` },
										  }
								}
								shape="round"
								size="small"
								className="cancel-button"
							>
								{t(
									'esign-pilet-ui:signerPage.content.fields.attachment.modalDeleteFileCancelButton'
								)}
							</Button>
						</Space>
					)}
				>
					<Space style={{ marginLeft: sizes.MD, marginBottom: sizes.SM }}>
						<Typography.Text style={{ marginBottom: sizes.MS }}>
							{t(
								'esign-pilet-ui:signerPage.content.fields.attachment.modalDeleteFileText',
								{
									fileName: (
										<Typography.Text
											strong={true}
										>{`${uploadedFile?.name}`}</Typography.Text>
									),
								}
							)}
							{deleteModalOpen[0]
								? '?'
								: t(
										'esign-pilet-ui:signerPage.content.fields.attachment.modalUploadAnotherFileText'
								  )}
						</Typography.Text>
					</Space>
				</StyledAttachmentModal>
			</StyledAttachmentModal>
		</>
	);
};
