// This storage is used to save document's personal settings
import { StateCreator } from 'zustand';
import { storeResetFns } from '.';

type State = {
	personalSettings: {
		allowTypedSignature: boolean;
		allowDrawnSignature: boolean;
		allowUploadedSignature: boolean;
	};
};

type Actions = {
	setPersonalSettings: (personalSettings) => void;
};

export type PersonalSettingsStore = State & Actions;

const initialState: State = {
	personalSettings: undefined,
};

export const createPersonalSettingsSlice: StateCreator<PersonalSettingsStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setPersonalSettings(personalSettings) {
			set(() => ({ personalSettings: personalSettings }));
		},
	};
};
