import React from 'react';
import { notification } from 'antd';
import { t } from '../util';

export function handleError(errorMessage = t('genericError')) {
	notifyError(errorMessage);
}

export function handleErrorWithServerMessage(error) {
	if (typeof error === 'string') {
		notifyError(error);
	} else {
		notifyError(error?.response?.data || error?.message || t('genericError'));
	}
}

function notifyError(errorMessage) {
	notification.error({
		message: 'Error',
		description: <span>{errorMessage}</span>,
	});
}
