import styled from '@emotion/styled';

export const SignerSignatureAnnotationImageOverlayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	height: calc(100% + 1px);
	width: calc(100% + 1px);
`;

export const SignerSignatureAnnotationImage = styled.img<{
	signatureColorBlueDefault: boolean;
}>`
	filter: ${props =>
		props.signatureColorBlueDefault
			? 'brightness(0) saturate(100%) invert(14%) sepia(43%) saturate(7430%) hue-rotate(231deg) brightness(76%) contrast(118%)'
			: 'unset'};
	height: 100%;
	width: 100%;
	position: absolute;
	left: 1px;
	top: 1px;
`;
