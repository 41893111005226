// This storage is used to save signer payments
import { StateCreator } from 'zustand';
import { SignerPayment } from '../../data/rsTypes';
import { storeResetFns } from '.';

type State = {
	signerPayments: Array<SignerPayment>;
};

type Actions = {
	setSignerPayments: (signerPayments: Array<SignerPayment>) => void;
};

export type SignerPaymentsStore = State & Actions;

const initialState: State = {
	signerPayments: undefined,
};

export const createSignerPaymentsSlice: StateCreator<SignerPaymentsStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setSignerPayments(signerPayments) {
			set(() => ({ signerPayments: signerPayments }));
		},
	};
};
