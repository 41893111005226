import styled from '@emotion/styled';

export const StyledComponentWrapper = styled.div<{
	left: number;
	top: number;
	width: number;
	height: number;
	centerContent?: boolean;
	transparent?: boolean;
}>`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;

	left: ${props => props.left}px;
	top: ${props => props.top}px;
	width: ${props => props.width}px;
	height: ${props => props.height}px;

	${props => !props.transparent && 'background-color: #ededfd;'}
`;
