import styled from '@emotion/styled';
import { sizes } from '@sharefiledev/antd-config';
import { GlobalToken } from 'antd';

export const StyledInitialsComponentWrapper = styled.div<{
	textAlign: string;
	borderColor: string;
	backgroundColor: string;
	token: GlobalToken;
}>`
	@media (max-width: ${props => props.token.screenLGMax}px) {
		font-size: ${sizes.SM};
		padding: 0px ${props => (props.textAlign === 'right' ? sizes.XS : sizes.XXS)} 0px
			${sizes.XXS} !important;
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS};
		padding: 0px ${sizes.XXS} 0px ${sizes.XXS} !important;
		align-content: baseline;
	}

	display: flex;
	align-items: center;
	align-self: baseline;
	width: 100%;
	height: 100%;
	min-height: 100%;
	white-space: nowrap;
	padding: ${sizes.XXS} ${props => (props.textAlign === 'right' ? sizes.base : sizes.XXS)}
		${sizes.XXS} ${sizes.XS};
	border-radius: ${sizes.XXS};
	font-size: ${sizes.base};
	border-color: ${props => props.borderColor} !important;
	background-color: ${props => props.backgroundColor};

	&:hover {
		cursor: text;
		border: 2px solid ${props => props.borderColor} !important;
	}

	text-align: ${props => props.textAlign};
	justify-content: ${props => props.textAlign};
`;
