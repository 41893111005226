import React from 'react';
import { Flex, Typography } from 'antd';
import {
	groupComponents,
	isComponentValueValid,
} from '../../Common/ComponentsRenderer/util';
import { useAppStore } from '../../Common/store';
import { t } from '../../util';
import { ProgressFieldComponent } from '../ProgressFieldComponent/ProgressFieldComponent';

const { Text } = Typography;

type ProgressTrackerProps = {
	onSelectComponentId: (componentId: string) => void;
};

const textStyles: React.CSSProperties = {
	fontWeight: '400px',
};

export const ProgressTracker: React.FC<ProgressTrackerProps> = props => {
	const [signer, esignDocument, componentValues] = useAppStore(store => [
		store.signer,
		store.esignDocument,
		store.componentValues,
	]);

	// components assigned to the signer sorted by page, y, and x
	const signerComponents = groupComponents(
		esignDocument.components.filter(comps => comps.assigned_to === signer.role_name)
	);
	const requiredComponents = signerComponents.filter(coms => coms.is_required);

	const optionalComponentsCount = signerComponents.length - requiredComponents.length;
	let headerText = t(
		'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.noFields'
	);
	if (requiredComponents.length > 0 && optionalComponentsCount > 0) {
		headerText = t(
			'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.requiredAndOptional',
			{
				requiredCountLabel: (
					<Text strong style={textStyles} data-testid="required">
						{t(
							'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.requiredCount',
							{ count: requiredComponents.length }
						)}
					</Text>
				),
				optionalCountLabel: (
					<Text strong style={textStyles} data-testid="optional">
						{t(
							'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.optionalCount',
							{ count: optionalComponentsCount }
						)}
					</Text>
				),
			}
		);
	} else if (requiredComponents.length > 0) {
		headerText = t(
			'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.requiredOnly',
			{
				requiredCountLabel: (
					<Text strong style={textStyles} data-testid="required">
						{t(
							'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.requiredCount',
							{ count: requiredComponents.length }
						)}
					</Text>
				),
			}
		);
	} else if (optionalComponentsCount > 0) {
		headerText = t(
			'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.optionalOnly',
			{
				optionalCountLabel: (
					<Text strong style={textStyles} data-testid="optional">
						{t(
							'esign-pilet-ui:signerPage.content.sidePanelTabs.fieldsTab.optionalCount',
							{ count: optionalComponentsCount }
						)}
					</Text>
				),
			}
		);
	}

	return (
		<Flex vertical gap={16}>
			<Text style={textStyles}>{headerText}</Text>
			<Flex vertical gap={8}>
				{signerComponents.map(comp => {
					return (
						<ProgressFieldComponent
							key={comp.id}
							component={comp}
							isValid={isComponentValueValid(componentValues, comp)}
							onSelectComponentId={props.onSelectComponentId}
						/>
					);
				})}
			</Flex>
		</Flex>
	);
};
