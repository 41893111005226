import React, { useEffect, useRef, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Close, Payment } from '@sharefiledev/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Flex, Grid, MenuProps, Space, theme, Typography } from 'antd';
import { Component, ComponentValue, SignerPayment } from '../../../../data/rsTypes';
import { t } from '../../../../util';
import { ComponentTypes } from '../../../Components/types';
import { useAppStore } from '../../../store';
import { isComponentValid } from '../../util';
import { CreditCardModal } from '../Payment/Modals/CreditCardModal';
import {
	StyledAmountDivWrapper,
	StyledChevronDown,
	StyledDropDown,
	StyledDropDownButton,
	StyledPaymentComponentWrapper,
} from './PaymentComponent.styled';
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
const { useBreakpoint } = Grid;
const { useToken } = theme;
interface Props {
	componentValue: ComponentValue;
	component: Component;
	onChange: (value: string) => void;
	selected: boolean;
	pageBoundingBox: DOMRect;
	scale: number;
	componentVisibility: boolean;
}
export const PaymentComponent = (props: Props) => {
	const value = props.componentValue.value;
	const signerPayments = useAppStore(store => store.signerPayments);
	const isValid = isComponentValid(value, ComponentTypes.PaymentComponent);
	const [refreshKey, setRefreshKey] = useState(0);
	const [card, setCard] = useState<SignerPayment>(
		() => signerPayments?.find(payment => payment.id === value) ?? null
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const showModal = () => setIsModalOpen(prevState => !prevState);
	const screens = useBreakpoint();
	const { token } = useToken();
	const scale = props.scale;
	const componemtWidthPx = props.component.width * props.pageBoundingBox.width;
	const [isPaymentDropDownOpen, setIsPaymentDropDownOpen] = useState(false);
	const paymentRef = useRef<HTMLDivElement>(null);
	const onChange = card => {
		card ? props.onChange(card.id) : props.onChange(null);
	};
	const clearCard = () => {
		setCard(null);
		onChange(null);
		setRefreshKey(prevKey => prevKey + 1);
	};
	const handleModalSubmit = (formData: SignerPayment) => {
		setCard(formData);
		onChange(formData);
		setIsModalOpen(false);
	};
	useEffect(() => {
		if (!props.componentVisibility) {
			setIsPaymentDropDownOpen(false);
		}
	}, [props.componentVisibility]);

	useEffect(() => {
		if (props.selected && paymentRef.current) {
			paymentRef.current.focus();
		}
	}, [props.selected]);
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (value) {
				setIsPaymentDropDownOpen(prev => !prev);
			} else {
				showModal();
			}
		}
	};
	const items: MenuProps['items'] = [
		{
			icon: <Payment size={24} />,
			label: props.component.charge_after_executed ? (
				<Flex vertical>
					{screens.xl
						? `${card?.payment_type} ${card?.payment_last4}`
						: card?.payment_last4}
					<StyledAmountDivWrapper token={token}>
						{t('esign-pilet-ui:signerPage.content.fields.paymentField.amount')}:
						{` ${card?.payment_amount_dollars_formatted}`}
					</StyledAmountDivWrapper>
				</Flex>
			) : screens.xl ? (
				`${card?.payment_type} ${card?.payment_last4}`
			) : (
				card?.payment_last4
			),
			key: 'card',
			style: { gap: 2 },
		},
		{
			type: 'divider',
		},
		{
			label: (
				<div onClick={showModal} data-testid="change-payment-card">
					<Space>
						<ReloadOutlined />
						{t('esign-pilet-ui:signerPage.content.fields.paymentField.change')}
					</Space>
				</div>
			),
			key: 'change',
		},
		{
			label: (
				<div data-testid="clear-payment-card" onClick={clearCard}>
					<Space>
						<Close />
						{t('esign-pilet-ui:signerPage.content.fields.paymentField.clear')}
					</Space>
				</div>
			),
			key: 'clear',
		},
	];
	return (
		<>
			<StyledPaymentComponentWrapper
				textAlign={props.component.text_align}
				valid={isValid}
				token={token}
				onKeyDown={handleKeyDown}
				ref={paymentRef}
				tabIndex={0}
				multiline={false}
				data-testid={`PaymentComponent-${props.componentValue.component_id}`}
			>
				{value ? (
					<StyledDropDown
						token={token}
						menu={{ items }}
						autoFocus
						trigger={['click']}
						data-testid="dropdown-payment-component"
						open={isPaymentDropDownOpen}
						onOpenChange={setIsPaymentDropDownOpen}
					>
						<StyledDropDownButton
							type="text"
							onChange={onChange}
							token={token}
							data-testid="button-payment-component"
						>
							<Payment size={24 * scale} />
							<Typography.Text
								style={{
									maxWidth: '100%',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									fontSize: 14 * props.scale,
								}}
							>
								{`${card?.payment_type} ${card?.payment_last4}`}
								{props.component.charge_after_executed
									? ` (${card?.payment_amount_dollars_formatted})`
									: null}
							</Typography.Text>
							<StyledChevronDown token={token} scale={scale} />
						</StyledDropDownButton>
					</StyledDropDown>
				) : (
					<Space data-testid="empty-payment-component" onClick={showModal}>
						<Flex gap={8}>
							<Payment size={24 * scale} />
							{componemtWidthPx < 45 || (
								<Typography.Text
									style={{
										maxWidth: '100%',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontSize: 18 * scale,
									}}
								>
									{props.component?.charge_after_executed
										? t(
												'esign-pilet-ui:signerPage.content.fields.paymentField.authorizePayment'
										  )
										: t(
												'esign-pilet-ui:signerPage.content.fields.paymentField.creditDetails'
										  )}
								</Typography.Text>
							)}
						</Flex>
					</Space>
				)}
			</StyledPaymentComponentWrapper>
			<Elements stripe={stripePromise}>
				<CreditCardModal
					key={refreshKey}
					component={props.component}
					componentValue={props.componentValue}
					isOpen={isModalOpen}
					onSubmit={handleModalSubmit}
					setIsModalOpen={setIsModalOpen}
				/>
			</Elements>
		</>
	);
};
