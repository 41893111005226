import React from 'react';
import { css, Global } from '@emotion/react';
import { colorPalette } from '@sharefiledev/antd-config';
import { Pagination } from 'antd';
import { PageSelectorBar } from './PageSelector.styled';

export interface Props {
	currentPage: number;
	totalPages: number;
	onPageChange: (newPage: number) => void;
}

export const PageSelector = (props: Props) => {
	return (
		<>
			<Global
				styles={css`
					.ant-pagination-item-link:focus {
						border: 2px solid ${colorPalette.lavender7} !important;
					}
				`}
			/>

			<PageSelectorBar>
				<Pagination
					simple
					current={props.currentPage}
					pageSize={1}
					total={props.totalPages}
					onChange={props.onPageChange}
				/>
			</PageSelectorBar>
		</>
	);
};
