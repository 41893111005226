import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { GlobalToken, Modal } from 'antd';

export const StyledMobileWrapper = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: ${sizes.XS} ${sizes.base};
	background: ${colorPalette.neutral1};
	box-shadow: 0px -${sizes.XXS} ${sizes.XXS} 0px rgba(0, 0, 0, 0.08);
	z-index: 15;
`;

export const StyledSubmitModal = styled(Modal)<{
	token: GlobalToken;
}>`
	.ant-modal-content {
		@media (max-width: ${props => props.token.screenXS}px) {
			margin: ${sizes.base};
			width: fit-content !important;
		}
	}
`;
