import styled from '@emotion/styled';
import { Drawer } from 'antd';

export const SignatureStyledDrawer = styled(Drawer)`
	hr {
		margin: 2px;
	}

	.signature-textpad-wrapper {
		padding: 0;
		justify-content: center;
		display: flex;
		position: relative;
		flex-direction: column;
	}

	.signature-input {
		font: 48px 'Cedarville Cursive', 'cursive';
		height: 200px;
		width: 100%;
		padding-left: 2px;
		border: 0 none;
		box-sizing: border-box;
		outline: none;
	}

	.hidden-resizer {
		visibility: hidden;
		position: absolute;
		right: 0;
		bottom: 0;
		font-family: 'Cedarville Cursive';
	}

	.signature-pad-wrapper {
		position: relative;
	}
`;
