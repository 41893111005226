import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { FillAndSign } from '@sharefiledev/icons';
import { Button, GlobalToken, Typography } from 'antd';

export const StyledOpenDrawerButton = styled(Button)<{
	token?: GlobalToken;
}>`
	font-size: ${sizes.base};

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS};
		padding: 0px ${sizes.XXS} 0px ${sizes.XXS} !important;
		align-content: baseline;
	}

	@media (max-width: ${props => props.token.screenLGMax}px) {
		font-size: ${sizes.SM};
		padding: 0px ${sizes.XXS} 0px ${sizes.XXS} !important;
	}

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: ${sizes.XXS};
	background-color: ${colorPalette.lavender7 + '1A'} !important;

	.ant-btn-icon {
		color: ${colorPalette.neutral13};
		display: flex;
	}

	&:hover,
	&:focus {
		border: 2px solid ${colorPalette.lavender6} !important;
	}
`;

export const StyledOpenDrawerDropdownButton = styled(Typography.Text)<{
	token?: GlobalToken;
	isSelected?: boolean;
}>`
	cursor: pointer;
	font-size: ${sizes.base};

	@media (max-width: ${props => props.token.screenXS}px) {
		font-size: ${sizes.XS};
		padding: 0px ${sizes.XXS} 0px ${sizes.XXS} !important;
		align-content: baseline;
	}

	@media (max-width: ${props => props.token.screenLGMax}px) {
		font-size: ${sizes.SM};
		padding: 0px ${sizes.XXS} 0px ${sizes.XXS} !important;
	}

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: ${sizes.XXS};
	border: ${props =>
		props.isSelected
			? `2px solid ${colorPalette.green8}`
			: `1px solid ${colorPalette.green8}`} !important;
	background-color: ${colorPalette.green8 + '1A'} !important;

	.ant-btn-icon {
		color: ${colorPalette.neutral13};
	}

	&:hover,
	&:focus {
		border: 2px solid ${colorPalette.green8} !important;
	}
`;

export const StyledFillAndSign = styled(FillAndSign)<{
	scale: number;
}>`
	font-size: ${props => props.scale * 24}px !important;
	vertical-align: baseline;
`;
