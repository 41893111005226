import React, { useState } from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { CreditCard, HelpQuestion } from '@sharefiledev/icons';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { Button, Col, Form, Row, Tooltip } from 'antd';
import { ESignatureClient } from '../../../../../data/eSignatureClient';
import { Component, ComponentValue, PaymentPayload } from '../../../../../data/rsTypes';
import { t } from '../../../../../util';
import { useAppStore } from '../../../../store';
import { MandatoryFieldIndicator } from '../../../MandatoryFieldIndicator';
import { CreditCardModalStyled, ValidationContainer } from './CreditCardModal.styled';

export interface Props {
	component: Component;
	componentValue: ComponentValue;
	isOpen: boolean;
	onSubmit: (cardData) => void;
	setIsModalOpen: (state) => void;
}

export const CreditCardModal = (props: Props) => {
	const stripe = useStripe();
	const elements = useElements();
	const [focusedField, setFocusedField] = useState(null);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [signer, searchParams] = useAppStore(store => [store.signer, store.searchParams]);
	const [error, setError] = useState({
		cardNumber: null,
		cardExpiry: null,
		cardCvc: null,
	});

	const handleOk = e => {
		saveCard(e);
	};

	const handleCancel = () => {
		props.setIsModalOpen(false);
	};

	const handleFocus = field => {
		setFocusedField(field);
	};

	const handleBlur = () => {
		setFocusedField(null);
	};

	const createHandlers = field => ({
		onFocus: () => handleFocus(field),
		onBlur: handleBlur,
	});

	const stripeElementOptions = {
		style: {
			base: {
				fontSize: '14px',
			},
			input: {
				color: colorPalette.neutral13,
			},
			invalid: {
				color: colorPalette.neutral13,
			},
		},
	};

	const cardNumberElementOptions = {
		...stripeElementOptions,
		placeholder: t(
			'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.placeholders.cardNumber'
		),
	};

	const cardCvcElementOptions = {
		...stripeElementOptions,
		placeholder: t(
			'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.placeholders.cvc'
		),
	};

	const getFieldStyles = (fieldName, hasError) => ({
		borderRadius: `${sizes.XXS}`,
		padding: '10px',
		border:
			focusedField === fieldName
				? `2px solid ${hasError ? colorPalette.red6 : colorPalette.lavender6}`
				: `1px solid ${hasError ? colorPalette.red6 : colorPalette.neutral4}`,
		outline: 'none',
		backgroundColor: colorPalette.neutral1,
		transition: 'border-color 0.3s, box-shadow 0.3s',
		alignItems: 'flex-start',
	});

	const saveCard = async e => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		const cardNumberElement = elements.getElement(CardNumberElement);
		const result = await stripe.createToken(cardNumberElement);

		setError({
			...error,
			cardNumber: result.error?.code.includes('number') ? result.error.message : null,
			cardExpiry: result.error?.code.includes('expiry') ? result.error.message : null,
			cardCvc: result.error?.code.includes('cvc') ? result.error.message : null,
		});

		if (!result.error) {
			setLoading(true);
			await signerPaymentRequest(result.token);
		}
	};

	const signerPaymentRequest = async token => {
		const payload: PaymentPayload = { token: token.id };

		const response = await ESignatureClient.signerPaymentRequest(
			signer.id,
			props.componentValue.component_id,
			searchParams,
			payload
		);

		props.onSubmit(response.signer_payment);
		setLoading(false);
	};

	return (
		<CreditCardModalStyled
			width={496}
			title={`${t(
				'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.modalTitle'
			)}`}
			centered
			maskClosable={false}
			open={props.isOpen}
			footer={[
				<Button
					type="primary"
					size="small"
					loading={isLoading}
					shape="round"
					key="submit"
					onClick={handleOk}
					data-testid="save-card-edit"
				>
					{t('esign-pilet-ui:save')}
				</Button>,
				<Button
					type="default"
					size="small"
					shape="round"
					key="submit"
					onClick={handleCancel}
					data-testid="cancel-card-edit"
				>
					{t('esign-pilet-ui:cancel')}
				</Button>,
			]}
			onCancel={handleCancel}
			data-testid="credit-card-modal"
		>
			<Form>
				<Row gutter={[16, 24]}>
					{props.component?.charge_after_executed ? (
						<Col span={24}>
							{t(
								'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.authorizeMessage',
								{ paymentAmount: <strong>${props.component.payment_amount}</strong> }
							)}
						</Col>
					) : null}
					<Col span={24}>
						<label>
							{t(
								'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.labels.cardNumber'
							)}
							<MandatoryFieldIndicator
								displayOutside={false}
								textAligned={true}
								scale={1}
							/>
						</label>
						<div className="flex" style={getFieldStyles('cardNumber', error.cardNumber)}>
							<CreditCard size={16} color={error.cardNumber ? colorPalette.red6 : ''} />
							<div style={{ width: '100%', height: '100%', paddingLeft: '6px' }}>
								<CardNumberElement
									options={cardNumberElementOptions}
									{...createHandlers('cardNumber')}
								/>
							</div>
						</div>
						{error.cardNumber && (
							<ValidationContainer>{error.cardNumber}</ValidationContainer>
						)}
					</Col>
					<Col span={12}>
						<label>
							{t(
								'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.labels.expiryDate'
							)}
							<MandatoryFieldIndicator
								displayOutside={false}
								textAligned={true}
								scale={1}
							/>
						</label>
						<div
							className="ant-input ant-input-lg"
							style={getFieldStyles('cardExpiry', error.cardExpiry)}
						>
							<CardExpiryElement
								options={stripeElementOptions}
								{...createHandlers('cardExpiry')}
							/>
						</div>
						{error.cardExpiry && (
							<ValidationContainer>{error.cardExpiry} </ValidationContainer>
						)}
					</Col>
					<Col span={12}>
						<label>
							{t(
								'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.labels.cvc'
							)}
							<MandatoryFieldIndicator
								displayOutside={false}
								textAligned={true}
								scale={1}
							/>
							<Tooltip
								trigger={'hover'}
								placement="top"
								overlayInnerStyle={{ fontSize: '14px' }}
								title={t(
									'esign-pilet-ui:signerPage.content.fields.paymentField.creditCardModalDetails.cvcTooltipMessage'
								)}
								data-testid={`CreditCardModalTooltip-${props.componentValue.component_id}`}
							>
								<HelpQuestion />
							</Tooltip>
						</label>
						<div
							className="ant-input ant-input-lg"
							style={getFieldStyles('cardCvc', error.cardCvc)}
						>
							<CardCvcElement
								//type="password"
								options={cardCvcElementOptions}
								{...createHandlers('cardCvc')}
							/>
						</div>
						{error.cardCvc && (
							<ValidationContainer> {error.cardCvc} </ValidationContainer>
						)}
					</Col>
				</Row>
			</Form>
		</CreditCardModalStyled>
	);
};
