import React from 'react';
import dayjs from 'dayjs';
import { SignerTextAnnotationComponentProps } from '../ComponentsRenderer/SignerComponents/Annotations/SignerTextAnnotationComponent';

export interface WithFormattedDateProps {
	value: string;
	dateFormat: string;
}

function withFormattedDate(
	WrappedComponent: React.ComponentType<SignerTextAnnotationComponentProps>
) {
	return (props: SignerTextAnnotationComponentProps & WithFormattedDateProps) => {
		const { value, dateFormat, ...restProps } = props;
		const formattedValue = dayjs(value).format(dateFormat);

		return <WrappedComponent value={formattedValue} {...restProps} />;
	};
}

export default withFormattedDate;
