import React from 'react';
import { StyledTextArea } from './SignerTextAnnotationComponent.styled';

export interface SignerTextAnnotationComponentProps {
	value: string;
	fontSize: number;
	scale: number;
}

const SignerTextAnnotationComponent = ({
	value,
	fontSize,
	scale,
}: SignerTextAnnotationComponentProps) => {
	const containerRef = React.useRef(null);
	const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
	const [totalCols, setTotalCols] = React.useState(15);
	const [totalRows, setTotalRows] = React.useState(1);

	React.useEffect(() => {
		if (textAreaRef.current) {
			// This resizes the component height
			textAreaRef.current.style.height = '0px';
			textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
		}
	}, [textAreaRef.current?.scrollWidth, textAreaRef.current?.scrollHeight]);

	React.useEffect(() => {
		if (value) {
			const colsAvailable = Math.max(...value?.split('\n').map(str => str.length));
			setTotalCols(colsAvailable);
			setTotalRows(value?.split('\n').length);
		}
	}, [value]);

	return (
		<div
			ref={containerRef}
			style={{
				position: 'absolute',
				top: '1px',
				left: '1px',
				zIndex: 9999,
			}}
			data-testid={`signer-text-annotation`}
		>
			<StyledTextArea
				cols={totalCols}
				rows={totalRows}
				fontSize={fontSize}
				scale={scale}
				value={value}
				readOnly={true}
				autoFocus={false}
				ref={textAreaRef}
			></StyledTextArea>
		</div>
	);
};

export default SignerTextAnnotationComponent;
