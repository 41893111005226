import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Button, GlobalToken, Modal } from 'antd';

export const StyledOpenModalButton = styled(Button)<{
	token: GlobalToken;
	valid: number;
}>`
	@media (max-width: ${props => props.token.screenLGMax}px) {
		padding: 0px !important;
	}

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
	border: 1px solid
		${props => (props.valid ? colorPalette.green8 : colorPalette.lavender6)} !important;
	border-radius: 4px !important;
	background-color: ${props =>
		props.valid ? colorPalette.green8 + '1A' : colorPalette.lavender7 + '1A'} !important;

	.ant-btn-icon {
		color: ${colorPalette.neutral13};
		display: flex;
	}

	&:hover,
	&:focus {
		border: 2px solid
			${props => (props.valid ? colorPalette.green8 : colorPalette.lavender6)} !important;
	}
`;

export const StyledAttachmentModal = styled(Modal)<{
	token: GlobalToken;
}>`
	.ant-modal-content {
		@media (max-width: ${props => props.token.screenXS}px) {
			margin: ${sizes.base};
			width: auto !important;
		}
	}
`;
