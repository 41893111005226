import styled from '@emotion/styled';
import { sizes } from '@sharefiledev/antd-config';
import { Alert, GlobalToken } from 'antd';

export const StyledAlert = styled(Alert)<{
	token: GlobalToken;
}>`
	.ant-alert-icon {
		margin-inline-end: ${sizes.base};

		@media (max-width: ${props => props.token.screenXS}px) {
			margin-inline-end: ${sizes.XS};
		}
	}
`;
