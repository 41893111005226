import styled from '@emotion/styled';
import { colorPalette } from '@sharefiledev/antd-config';
import { Card } from 'antd';

export const StyledCard = styled(Card)<{
	selected: boolean;
}>`
	border-color: ${props =>
		props.selected ? colorPalette.lavender6 : colorPalette.neutral3};
	border-width: ${props => (props.selected ? '2px' : '1px')};
	border-radius: 8px;
	&:hover {
		background-color: ${colorPalette.neutral2};
	}
`;
