import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { GlobalToken, Modal } from 'antd';

export const PasscodeModalStyled = styled(Modal)<{
	token: GlobalToken;
}>`
	left: 0;
	overflow: hidden;

	.ant-modal-title {
		text-align: center;
	}

	.ant-modal-content {
		padding: ${sizes.LG};
	}
	.ant-space {
		row-gap: 0px;
	}
	.ant-row {
		font-size: ${sizes.base};
	}
	.ant-alert-icon {
		margin-inline-end: ${sizes.base};
	}

	.ant-modal-footer {
		margin-top: 0px;
	}

	.ant-input-clear-icon {
		color: ${colorPalette.red6};

		:hover {
			color: ${colorPalette.red6};
		}
	}

	@media (max-width: ${props => props.token.screenXS}px) {
		max-width: 100% !important;
		min-height: 100% !important;
		margin: 0 !important;
		top: 0 !important;
		padding: 0 !important;
	}

	.ant-modal-content {
		@media (max-width: ${props => props.token.screenXS}px) {
			min-height: 100vh;
			width: unset;
			padding: 20px ${sizes.MS};
			border-radius: 0;
		}
	}

	.ant-modal-footer {
		@media (max-width: ${props => props.token.screenXS}px) {
			width: 100%;
			text-align: center;
			position: fixed;
			bottom: 0;
			padding: ${sizes.XS} ${sizes.base};
			margin-left: -${sizes.base} !important;
			margin-bottom: 0 !important;
			border-top: 1px solid ${colorPalette.neutral2};
			background-color: ${colorPalette.neutral1};
		}
	}

	.ant-btn-primary.ant-btn-lg {
		@media (max-width: ${props => props.token.screenXS}px) {
			height: ${sizes.XL};
		}
	}
`;
