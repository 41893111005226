export const getFileType = (file: File) => {
	let fileType = file.type;
	switch (fileType) {
		case 'application/msword':
			return '.doc';
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return '.docx';
		case 'text/plain':
			return '.txt';
		case 'application/pdf':
			return '.pdf';
		case 'text/csv':
			return '.csv';
		default:
			// if fileType is null, get it from the file name for example when we select a file from sharefile
			const splitName = file.name.split('.');
			return splitName.length > 1 ? `.${splitName.pop().toLowerCase()}` : '';
	}
};

const getFileNameWithoutExtension = (fileName: string) => {
	// "Document.pdf" => "Document"
	return fileName.split('.').slice(0, -1).join('.') || fileName;
};

export const replaceSpecialCharsWithSpace = (fileName: string) => {
	// "Document_new.pdf" => "Document new"
	return getFileNameWithoutExtension(fileName)
		.replace(/[_`~!@#$%^&*(){};:'",.?[\]|\s\\|/<>=]+/g, ' ')
		.trim();
};

const getFileExtensionFromUrl = (downloadUrl: string) => {
	try {
		const url = new URL(downloadUrl);
		const pathname = url.pathname;
		const extension = pathname.split('.').pop();
		return extension;
	} catch (err) {
		return 'pdf';
	}
};

/**
 * This function downloads a file from a given URL and names it with a given filename.
 * If any error occurs during the process, it tries to open the file in a new tab.
 */
export const downloadFileWithName = async (downloadUrl: string, name: string) => {
	try {
		// Get file extension from URL
		const fileExtension = getFileExtensionFromUrl(downloadUrl);

		const response = await fetch(downloadUrl);

		// Check if the fetch was successful
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const blob = await response.blob();
		const urlFromBlob = window.URL.createObjectURL(blob);

		const downloadLink = document.createElement('a');
		downloadLink.href = urlFromBlob;

		const fileName = `${name}.${fileExtension}`;

		downloadLink.download = fileName;

		downloadLink.click();

		window.URL.revokeObjectURL(urlFromBlob);
	} catch (err) {
		// If there's an error, try to open the file in a new tab
		// But first, check if the URL is valid
		try {
			new URL(downloadUrl);
			window.open(downloadUrl, '_blank');
		} catch (_) {
			console.error('Invalid URL:', downloadUrl);
		}
	}
};
