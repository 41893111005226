import styled from '@emotion/styled';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Modal } from 'antd';

export const CreditCardModalStyled = styled(Modal)`
	font-size: ${sizes.base};

	.ant-modal-content {
		font-size: ${sizes.base};
	}

	.ant-modal-content {
		padding: ${sizes.MD}, ${sizes.LG}, ${sizes.MS};
	}
	.ant-modal-body {
		padding: ${sizes.base} 0px ${sizes.MS};
	}
	.ant-modal-body label {
		padding: ${sizes.XS} 0px;
		line-height: 32px;
		display: block;
		height: 32px;
	}
`;

export const ValidationContainer = styled.div<{}>`
	color: ${colorPalette.red6};
	font-size: 14px;
`;
