import { StateCreator } from 'zustand';
import { ComponentValue } from '../../data/rsTypes';
import { storeResetFns } from '.';

type State = {
	// This map should only have the current signer's componentValues.
	componentValues: Map<string, ComponentValue>;
	selectedComponentId: string;
};

type Actions = {
	setComponentValues: (componentValues: ComponentValue[]) => void;
	setComponentValue: (componentValue: ComponentValue) => void;
	setSelectedComponentId: (componentId: string) => void;
};

export type ComponentValueStore = State & Actions;

const initialState: State = {
	componentValues: new Map<string, ComponentValue>(),
	selectedComponentId: null,
};

export const createComponentValueSlice: StateCreator<ComponentValueStore> = set => {
	storeResetFns.add(() => {
		set(initialState);
	});
	return {
		...initialState,
		setComponentValues: newComponentValues => {
			const componentValuesMap = new Map<string, ComponentValue>();
			newComponentValues.forEach(value => {
				componentValuesMap.set(value.component_id, value);
			});

			set({ componentValues: componentValuesMap });
		},
		setComponentValue(componentValue) {
			set(prevState => ({
				componentValues: new Map(prevState.componentValues).set(
					componentValue.component_id,
					componentValue
				),
			}));
		},
		setSelectedComponentId(componentId) {
			set(prevState => ({
				componentValues: prevState.componentValues,
				selectedComponentId: componentId,
			}));
		},
	};
};
