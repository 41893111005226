import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { css, Global } from '@emotion/react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Button, Grid, Image as AntdImage, Space, Spin, Typography, Upload } from 'antd';
import { SignatureDrawerIndexes } from '../../../constants';
import { t } from '../../../util';
import { canvasDimesions, SignatureType } from '../constants';
import { SaveSignatureEvent } from '../SaveSignatureEvent';

const { Dragger } = Upload;
const { useBreakpoint } = Grid;

export const ESignatureUploadPad = ({
	saveSignature,
	setIsSignatureDrawn,
	tabOpened = SignatureType.UPLOADED,
	clearRef = undefined,
	isSignerFlow = false,
	previousValue = undefined,
	setPreviousValue = undefined,
}) => {
	const inputFileRef = useRef<File | null>(null);
	const draggerRef = useRef(null);
	const canvasRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [isImageVisible, setIsImageVisible] = useState(false);
	const [imageUrl, setImageUrl] = useState(previousValue?.image_url ?? null);
	const screens = useBreakpoint();

	useEffect(() => {
		const canvas = canvasRef.current;
		const inputFile = inputFileRef.current;

		const onSaveEventHandler = () => {
			if (
				canvas &&
				inputFile &&
				(!isSignerFlow || (isSignerFlow && tabOpened === SignatureType.UPLOADED))
			) {
				const dataURL = canvas.toDataURL('image/png');
				saveSignature(dataURL, null, null);
			}
		};

		const key = 'ESignatureUploadPad';
		SaveSignatureEvent.registerHandler(key, onSaveEventHandler);

		if (isSignerFlow && tabOpened === SignatureType.UPLOADED) {
			imageUrl ? setIsSignatureDrawn(true) : setIsSignatureDrawn(false);
		}

		return () => {
			SaveSignatureEvent.unRegisterHandler(key);
		};
	}, [imageUrl, isSignerFlow, saveSignature, setIsSignatureDrawn, tabOpened]);

	const getDataUrl = (canvas: HTMLCanvasElement, img: HTMLImageElement) => {
		const ctx = canvas.getContext('2d');

		let width = img.width;
		let height = img.height;

		if (height > canvas.height) {
			height = canvas.height;
			width = Math.min((canvas.height / img.height) * img.width, canvas.width);
		} else if (width > canvas.width) {
			width = canvas.width;
			height = Math.min((canvas.width / img.width) * img.height, canvas.height);
		}

		const offsetX = (canvas.width - width) / 2;
		const offsetY = (canvas.height - height) / 2;

		ctx.imageSmoothingEnabled = true;
		ctx.imageSmoothingQuality = 'high';

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(img, offsetX, offsetY, width, height);
		ctx.save();

		const dataURL = canvas.toDataURL('image/png');
		return dataURL;
	};

	const handleSignatureUpload = info => {
		const file = info.file;
		const canvas = canvasRef.current;
		if (file) {
			inputFileRef.current = file;
			const url = URL.createObjectURL(file);
			const img = new Image();

			img.onload = () => {
				setImageUrl(getDataUrl(canvas, img));
			};
			img.src = url;
		}
	};

	const handleImageLoaded = () => {
		setLoading(false);
		setIsImageVisible(true);
	};

	const handleClear = () => {
		inputFileRef.current = null;
		setIsSignatureDrawn(false);
		setPreviousValue(null);
		setImageUrl(null);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key === ' ' || e.key === 'Space') {
			draggerRef.current?.upload.uploader.fileInput.click();
		}
	};

	return (
		<>
			<Global
				styles={css`
					.signature-upload-field > .ant-space-item:first-of-type {
						width: 100%;
						height: 100%;
					}
				`}
			/>
			<Space
				style={{
					height: '220px',
					border: `1px solid ${colorPalette.neutral3}`,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					overflow: 'hidden',
					borderRadius: sizes.XXS,
				}}
				className="signature-upload-field"
				id="upload-signature-field"
				tabIndex={SignatureDrawerIndexes.UploadFile}
				onKeyDown={handleKeyDown}
			>
				{!imageUrl && !loading && (
					<Dragger
						accept="image/*"
						multiple={false}
						showUploadList={false}
						beforeUpload={() => false}
						onChange={handleSignatureUpload}
						ref={draggerRef}
						data-testid="upload-image-dragger"
						style={{
							display: 'block !important',
							background: `${colorPalette.neutral1}`,
							border: 'none',
						}}
					>
						<p className="ant-upload-drag-icon">
							<UploadOutlined />
						</p>
						<Typography.Text className="ant-upload-text" style={{ fontSize: sizes.base }}>
							{t(
								'esign-pilet-ui:signerPage.content.fields.signature.uploadSignatureImage'
							)}
						</Typography.Text>
					</Dragger>
				)}

				{loading && (
					<Spin
						indicator={
							<LoadingOutlined
								style={{ fontSize: sizes.XL, color: `${colorPalette.lavender6}` }}
								spin
							/>
						}
					/>
				)}

				{imageUrl && (
					<Space
						style={{
							display: 'flex',
							height: '100%',
							width: '100%',
						}}
						data-testid="image-container"
					>
						<Button
							type="text"
							icon={<CloseOutlined />}
							ref={clearRef}
							tabIndex={SignatureDrawerIndexes.UploadClearButton}
							style={{
								position: 'absolute',
								top: sizes.XXS,
								right: sizes.XXS,
								zIndex: 1,
								background: 'transparent',
							}}
							onClick={() => handleClear()}
							id="clear-upload-signature-btn"
							data-testid="clear-upload-signature"
						/>
						<Space
							style={{
								height: '200px',
								maxHeight: canvasDimesions.height,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: `${sizes.XS} ${screens.sm ? '6%' : '7%'} 0% 5%`,
							}}
						>
							<AntdImage
								src={imageUrl}
								alt={t(
									'esign-pilet-ui:signerPage.content.fields.signature.uploadSignatureAltText'
								)}
								onLoad={() => handleImageLoaded()}
								onLoadStart={() => setLoading(true)}
								preview={false}
								data-testid="signature-image"
								style={{
									display: isImageVisible ? 'block' : 'none',
									maxHeight: canvasDimesions.height,
									maxWidth: canvasDimesions.width,
								}}
							/>
						</Space>
					</Space>
				)}
			</Space>

			<canvas
				ref={canvasRef}
				{...{ width: canvasDimesions.width, height: canvasDimesions.height }}
				style={{ display: 'none' }}
			></canvas>
		</>
	);
};
